import React from 'react'
import { Table, Header } from 'semantic-ui-react'
import BifrostButton from '../../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton'
import { useAuth0 } from "@auth0/auth0-react"

const GroupView = ({ setRemoveItemViewProps, groupID, devicesInGroup, usersInGroup, openDeviceTab, openUserTab }) => {
  const { getAccessTokenSilently } = useAuth0()
  const removeDevice = async (deviceId) => {
    const data = { group_id: groupID, device_id: deviceId }
    return new Request(process.env.REACT_APP_SESSION_ADDR + "/api/v2/devicegroup", {
      method: 'DELETE',
      headers: new Headers({
        'authorization': "Bearer " + await getAccessTokenSilently(),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    })
  }

  const removeUser = async (userId) => {
    const data = { group_id: groupID, user_id: userId }
    return new Request(process.env.REACT_APP_SESSION_ADDR + "/api/v2/usergroup", {
      method: 'DELETE',
      headers: new Headers({
        'authorization': "Bearer " + await getAccessTokenSilently(),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    })
  }
  return (
    <>
      
        <Header> Devices </Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Short Name</Table.HeaderCell>
              <Table.HeaderCell>Serial Number</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {devicesInGroup.map((dev, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{dev.shortName}</Table.Cell>
                <Table.Cell>{dev.serialNr}</Table.Cell>
                <Table.Cell><BifrostButton onClick={() => setRemoveItemViewProps({
                  identifier: dev.shortName,
                  removeItem: () => removeDevice(dev.id)
                })} color="red">Remove</BifrostButton></Table.Cell>
              </Table.Row>
            ))
            }
          </Table.Body>
        </Table>
        <BifrostButton onClick={openDeviceTab}>Add Device</BifrostButton>

        <Header> Users </Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Nickname</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {usersInGroup.map((user, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.name}</Table.Cell>
                <Table.Cell><BifrostButton onClick={() => setRemoveItemViewProps({
                  identifier: user.email,
                  removeItem: () => removeUser(user.id)
                })} color="red">Remove</BifrostButton></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <BifrostButton onClick={openUserTab}>Add User</BifrostButton>
      
    </>
  )
}
export default GroupView