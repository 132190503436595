import BifrostButton from "../../../BifrostElementComponent/BifrostButton/BifrostButton";
import React from "react";

const BifrostConfirmModalActions = ({loading, confirmButtonColor, confirmText, confirmHandler, cancelHandler}) => {

    return (
        <>
            <BifrostButton style={{display: "inline-block"}}
                           color={confirmButtonColor ? confirmButtonColor : "red"}
                           disabled={loading}
                           onClick={confirmHandler}
            >
                {confirmText ? confirmText : "Delete"}
            </BifrostButton>
            <BifrostButton style={{display: "inline-block"}} color='gray'
                            disabled={loading}
                           onClick={cancelHandler}>
                Cancel
            </BifrostButton>
        </>
    );
};
export default BifrostConfirmModalActions;