import React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Message } from 'semantic-ui-react'
import BifrostButton from '../../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton'
import Loader from '../../../../../../../Components/BifrostPageComponent/Loader/Loader'

const initialValidatedState = {
  user_id: true,
}

const AddUser2GroupPage = ({ orgIdOfGroup, usersInGroup, onFinish, groupId }) => {

  const [values, setValues] = useState({ user_id: null })
  const [validated, setValidated] = useState(initialValidatedState)
  const [loading, setLoading] = useState(false);
  const [optionsUser, setOptionsUser] = useState([]);

  const [showErrorMessage, setShowErrorMessage] = useState(false)


  const updateField = (e, name, value) => {
    if (!name) {
      name = e.target.name
      value = e.target.value
    }
    setValues({
      ...values,
      [name]: value
    });
  };

  const { getAccessTokenSilently } = useAuth0();

  const getAvailableUsers = useCallback(async () => {
    setLoading(true)
    
    const request = new Request(process.env.REACT_APP_SESSION_ADDR + "/api/v2/org/" + orgIdOfGroup + "/user", {
      method: 'GET',
      headers: new Headers({
        'authorization': "Bearer " + await getAccessTokenSilently()
      })
    });

    fetch(request)
      .then(response => {
        if (response.status !== 200) {
          return [];
        }
        console.log("Got list")
        return response.json();
      })
      .then(data => {
        const unAddedUsers = data.filter(u => usersInGroup.every(u2 => u2.id !== u.id))
        setOptionsUser(unAddedUsers.map((user) =>
          ({ key: user.id, value: user.id, text: user.email })
        ))
      })
      .catch(error => {
        setShowErrorMessage(true)
        console.error(error);
      }).finally(() => {
        setLoading(false)
      });
  }, [getAccessTokenSilently, orgIdOfGroup, usersInGroup])

  

  useEffect(() => {
    !optionsUser.length && getAvailableUsers()
}, [getAvailableUsers]);

  const postUser2Group = useCallback(async (data) => {
    setLoading(true)
    
    data.group_id = groupId
    const url = new URL(process.env.REACT_APP_SESSION_ADDR +
      "/api/v2/addU2G")
    const request = new Request(url, {
      method: 'POST',
      headers: new Headers({
        'authorization': "Bearer " + await getAccessTokenSilently(),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    });

    fetch(request)
      .then(response => {
        if (response.status !== 200) {
          throw new ErrorEvent("Unexected response")
        }
        console.log("Posted User To Group")
        
      }).then(() => onFinish())
      .catch(error => {
        setShowErrorMessage(true)
        console.error(error);
      }).finally(() => {
      setLoading(false)
      });
  }, [getAccessTokenSilently, groupId, onFinish])

  const validateInput = () => {
    let valid = true
    let validatedCopy = {...validated}
    for (const [key] of Object.entries(validatedCopy)) {
      if (!values[key] && values[key] !== 0) {
        validatedCopy[key] = false
        valid = false
      } else {
        validatedCopy[key] = true
      }
    }

    setValidated({ ...validatedCopy })

    return valid
  }

  const onSubmit = () => {
    validateInput() && postUser2Group(values)

  };

  return (<>
    
      {showErrorMessage &&
        <Message negative>
          <p>Something unexpected happened</p>
        </Message>
      }
      <Form>
        {loading ? <Loader backgroundColor="white" inverted height="1px" minHeight="100px" /> :
          <>
            <Form.Dropdown
              fluid
              search
              selection
              onChange={(e, { name, value }) => updateField(e, name, value)}
              name="user_id"
              placeholder='Select User'
              options={optionsUser}
              error={!validated.user_id}
            />
          
        <BifrostButton style={{ display: "inline-block" }} onClick={() => onSubmit()}>Submit</BifrostButton>
        <BifrostButton onClick={() => onFinish()} style={{ display: "inline-block" }} color="gray">Cancel</BifrostButton>
        </>
        }
      </Form>
  </>
  );
}

export default AddUser2GroupPage