import { useState } from "react";
import { BifrostContext } from "./BifrostContext";
import { getOperatingSystem, getBrowser } from "./Utils/Platform";
import React from "react";


export const BifrostProvider = ({ children }) => {

  const currentOS = getOperatingSystem(window);
  const currentBrowser = getBrowser(window);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInRTC, setLoggedInRTC] = useState(false);
  const [status, setStatus] = useState(
    "This is the message field, where all relevant and important messages are displayed."
  );
  const sessionAddress = process.env.REACT_APP_SESSION_ADDR
  const frontendAddress = process.env.REACT_APP_FRONTEND_ADDR
  const cookieDomain = process.env.REACT_APP_DOMAIN
  const [token, setToken] = useState(null)

  return (
    <BifrostContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        status,
        setStatus,
        loggedInRTC,
        setLoggedInRTC,
        currentOS,
        currentBrowser,
        sessionAddress,
        frontendAddress,
        cookieDomain,
        token,
        setToken
      }}
    >
      {children}
    </BifrostContext.Provider>
  );
};
