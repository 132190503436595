import {useGetAllData} from "./useGetAllData/useGetAllData";
import {useCallback} from "react";


export const useGetDevices = () => {
    const {data, setData, isLoading, getAllData} = useGetAllData();

    const getDevices = useCallback(() => {
        getAllData("/api/v2/device/getTable", result => {
            return result.map(device => {
                device.name = device.shortname
                delete device.shortname
                return device
            })
        })
    }, [getAllData])

    return {data, setData, isLoading, getDevices};
}