import {useCallback, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

export const useGetLatestFirmwareVersion = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [latestFirmwareVersion, setLatestFirmwareVersion] = useState(null);

    const {getAccessTokenSilently} = useAuth0();

    const getLatestFirmwareVersion = useCallback(async () => {
        setIsLoading(true);
        console.log("Getting firmware version");
        return fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR +  "/api/v2/firmware/latestVersion",
                {
                    method: "GET",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        "Content-Type": "application/json"
                    }),
                }
            )
        ).then(response => {
            if (response.ok) {
                console.log("Firmware version is retrieved successfully")
                return response.text();
            } else if (response.statusText === "Unauthorized") {
                throw new Error("Unauthorized");
            } else {
                console.log(response.statusText);
                throw new Error("Something went wrong");
            }
        }).then(result => {
            setLatestFirmwareVersion(result ? result : null);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [getAccessTokenSilently]);

    return {isLoading,latestFirmwareVersion, getLatestFirmwareVersion};
}