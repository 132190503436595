import React, { useState } from 'react';
import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import { Icon } from "semantic-ui-react";
import CreateGroupPage from "./CreateGroupPage";
import BifrostModal
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import { validateInput } from "../../../../../../Utils/Validator/ValidateInput";
import { usePostData } from "../../../../../../Hooks/FetchHooks/PostDataService/usePostData";
import Loader from '../../../../../../Components/BifrostPageComponent/Loader/Loader'


const initialValueState = {
    org_id: "",
    name: "",
};

const initialValidatedState = {
    org_id: true,
    name: true,
}

const CreateGroupModal = ({ refreshGroups }) => {
    const [values, setValues] = useState(initialValueState)
    const [validated, setValidated] = useState(initialValidatedState)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const { isLoading, postItem } = usePostData();

    const submitHandler = () => {
        validateInput(values, validated, setValidated)
            && postItem("/api/v2/group", values, refreshGroups).then(() => {
                setValidated({...initialValidatedState});
                setValues({...initialValueState});
                setShowErrorMessage(false);
                setOpenModal(false);
            }).catch(() => {
                setValidated({...initialValidatedState});
                setValues({...initialValueState});
                setShowErrorMessage(true);
            })
    };

    const cancelHandler = () => {
        setValidated({...initialValidatedState});
        setValues({...initialValueState});
        setShowErrorMessage(false);
        setOpenModal(false);
    }

    return (
        <BifrostModal title={"Create Group"}
            closeOnEscape={!isLoading}
            closeOnDimmerClick={!isLoading}
            open={openModal}
            setOpen={setOpenModal}
            trigger={
                <BifrostButton>
                    <Icon name='folder' />
                    Create
                </BifrostButton>}
            modalPage={
                isLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="100px"/> :
                <CreateGroupPage validated={validated}
                    values={values}
                    setValues={setValues}
                    showErrorMessage={showErrorMessage}
                />
            }
            modalActions={
                <>
                    <BifrostButton disabled={isLoading} style={{ display: "inline-block" }}
                        onClick={() => submitHandler()}>
                        Submit
                    </BifrostButton>
                    <BifrostButton onClick={() => cancelHandler()}
                        style={{ display: "inline-block" }}
                        color="gray"
                        disabled={isLoading}>
                        Cancel
                    </BifrostButton>
                </>
            }

        />
    )
}

export default CreateGroupModal;