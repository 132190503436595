/**
 * Renders the header of a table component.
 * This component is a child of the BifrostTable component.
 * It contains the column Headers and handles the action and icon of sorting.
 * 
 * @param {Object[]} columns - The array of column objects.
 * @param {string} sortedColumn - The key of the currently sorted column.
 * @param {string} direction - The sorting direction ('ascending' or 'descending').
 * @param {Function} handleSort - The function to handle column sorting.
 * @returns {JSX.Element} The rendered table header.
 */
import React from 'react'
import { Table } from "semantic-ui-react";

const TableHeader = ({ columns, sortedColumn, direction, handleSort }) => {
    return (
        <Table.Header>
            <Table.Row>
                {columns.map((col, idx) => (
                    <Table.HeaderCell
                        className={(col.isUnSortable && 'unsortable') + ' no-background ' + (col.type)}
                        textAlign={col.textAlign}
                        key={idx}
                        width={col.width}
                        sorted={!col.isUnSortable && col.key === sortedColumn ? direction : undefined}
                        onClick={!col.isUnSortable ? (() => handleSort(col.key)) : undefined}
                    >
                        {col.name}
                    </Table.HeaderCell>))}
            </Table.Row>
        </Table.Header>)
}

export default TableHeader