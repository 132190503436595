import React, { useState, useContext, useEffect } from "react";
import { useGetDeviceById } from "../../../../../Hooks/FetchHooks/GetDataService/useGetDataById/useGetDeviceById";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetLatestFirmwareVersion } from "../../../../../Hooks/FetchHooks/GetDataService/useGetLatestFirmwareVersion";
import { isAdmin, isVersionHigherOrEqual } from "../../../../../Utils/util";
import { Menu, Icon } from "semantic-ui-react";
import DeviceDetailsComponent from "../../../../../Components/BifrostPageComponent/DeviceComponents/DeviceDetailsComponent/DeviceDetailsComponent";
import {
	hasFeatureInPackage,
	isSuperAdminOrAdmin,
} from "../../../../../Utils/util";
import userInfoContext from "../../../../../store/userInfoContext";
import UpgradePackageModal from "../../../../Sidebar/SidebarNavPanel/SidebarNavLink/UpgradePackageModal/UpgradePackageModal";
import BifrostAuditComponent from "../../../../../Components/BifrostPageComponent/OrganizationComponents/BifrostAuditComponent";
import BifrostSessionComponent from "../../../../../Components/BifrostPageComponent/OrganizationComponents/BifrostSessionComponent";
import { useLocation, useParams } from "react-router-dom";
import MainPanelHeader from "../../../MainPanelHeader/MainPanelHeader";
import MainPanelFooter from "../../../MainPanelFooter/MainPanelFooter";
import Error401 from "../../../../OtherPages/ErrorPage/Error401";
import Loader from "../../../../../Components/BifrostPageComponent/Loader/Loader";
import { Navigate } from "react-router-dom";

const DeviceDetailsPage = () => {
	const { id } = useParams();
	const { state } = useLocation();
	const userInfo = useContext(userInfoContext);
	const { user } = useAuth0();
	const {
		isLoading: deviceIsLoading,
		data,
		isError,
		getDeviceById,
	} = useGetDeviceById(id);
	const { latestFirmwareVersion, getLatestFirmwareVersion } =
		useGetLatestFirmwareVersion(id);
	const [firmwareUpToDate, setFirmwareUpToDate] = useState(true);
	const [activeItem, setActiveItem] = useState("details");
	const [feature, setFeature] = useState(null);
	const [openUpgradePackageModal, setOpenUpgradePackageModal] = useState(false);
	const [name, setName] = useState(data ? data.shortname : "");

	useEffect(() => {
		if (data.firmware && latestFirmwareVersion) {
			setFirmwareUpToDate(
				isVersionHigherOrEqual(data.firmware, latestFirmwareVersion)
			);
		}
	}, [latestFirmwareVersion, data]);

	const handleItemClick = (e, name) => {
		switch (name) {
			case "details":
				setActiveItem(name);
				break;
			case "audit":
				if (hasFeatureInPackage(userInfo, "audit_log")) {
					setActiveItem(name);
				} else {
					setFeature("audit_log");
					setOpenUpgradePackageModal(true);
				}
				break;
			case "sessions":
				if (hasFeatureInPackage(userInfo, "statistics")) {
					setActiveItem(name);
				} else {
					setFeature("statistics");
					setOpenUpgradePackageModal(true);
				}
				break;
			default:
				setOpenUpgradePackageModal(true);
		}
	};

	useEffect(() => {
		setName(
			data.shortname ? (
				<>
					{data.shortname}
					<div
						className="status-col-value-container"
						style={{ display: "inline-block", marginLeft: "15px" }}
					>
						<span className={`status-col-value ${data.state.toLowerCase()}`}>
							{data.state}
						</span>
					</div>
				</>
			) : (
				data.shortname
			)
		);
	}, [data]);

	useEffect(() => {
		if (userInfo && userInfo.terms_accepted) {
			getDeviceById();
			getLatestFirmwareVersion();
		}
	}, [id, userInfo]);

	return (
		<>
			<section className={`bifrost-table-panel devices-table`}>
				<MainPanelHeader
					title={isError ? " " : "Devices"}
					name={deviceIsLoading ? (state ? state.name : "") : name}
					navPath={"/devices"}
				/>
				{deviceIsLoading ? (
					<Loader
						backgroundColor="white"
						inverted
						height="1px"
						minHeight="200px"
					/>
				) : (
					<>
						{isError ? (
							isSuperAdminOrAdmin(user) ? (
								<Error401 />
							) : (
								<Navigate to={"*"} />
							)
						) : (
							<div style={{ backgroundColor: "white" }}>
								<UpgradePackageModal
									openModal={openUpgradePackageModal}
									setOpenModal={setOpenUpgradePackageModal}
									feature={feature}
								/>
								<Menu tabular style={{ paddingTop: "10px" }}>
									<Menu.Item
										name="details"
										active={activeItem === "details"}
										onClick={(e, { name }) => handleItemClick(e, name)}
									>
										Details
									</Menu.Item>
									{
										<>
											<Menu.Item
												disabled={!isSuperAdminOrAdmin(user)}
												name="audit"
												active={activeItem === "audit"}
												onClick={(e, { name }) => handleItemClick(e, name)}
											>
												{!hasFeatureInPackage(userInfo, "audit_log") ? (
													<Icon color="grey" name="lock" />
												) : (
													<Icon name="star outline" />
												)}
												Audit
											</Menu.Item>
											<Menu.Item
												disabled={!isSuperAdminOrAdmin(user)}
												name="sessions"
												active={activeItem === "sessions"}
												onClick={(e, { name }) => handleItemClick(e, name)}
											>
												{!hasFeatureInPackage(userInfo, "statistics") ? (
													<Icon color="grey" name="lock" />
												) : (
													<Icon name="star outline" />
												)}
												Sessions
											</Menu.Item>
										</>
									}
								</Menu>
								<div style={{ padding: "20px" }}>
									{activeItem === "details" && (
										<DeviceDetailsComponent
											data={data}
											deviceIsLoading={deviceIsLoading}
											getDeviceById={getDeviceById}
											latestFirmwareVersion={latestFirmwareVersion}
											firmwareUpToDate={firmwareUpToDate}
										/>
									)}
									{activeItem === "audit" && (
										<BifrostAuditComponent
											chosenItem={data}
											chosenItemType="device"
										/>
									)}
									{activeItem === "sessions" && (
										<BifrostSessionComponent
											chosenItem={data}
											chosenItemType="device"
										/>
									)}
								</div>
							</div>
						)}
					</>
				)}
				<MainPanelFooter />
			</section>
		</>
	);
};

export default DeviceDetailsPage;
