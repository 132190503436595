import {useCallback} from "react";
import {useGetAllData} from "./useGetAllData/useGetAllData";

export const useGetOrganizations = () => {
    const {data, setData, isLoading, getAllData} = useGetAllData();

    const getOrganizations = useCallback(() => {
        getAllData("/api/v2/org", result => result)
    },[getAllData]);

    return {data, setData, isLoading, getOrganizations};
}