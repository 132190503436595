import React from 'react';
import { Header } from 'semantic-ui-react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from '../../../Components/BifrostPageComponent/Loader/Loader';

const Error404 = () => {
    return (
        <div style={{ position: "absolute", transform: "translate(-50%,-50%)", top: "50%", left: "50%" }}>
            <Header style={{ textAlign: "center", color: "white", fontSize: "80pt" }}>404</Header>
            <div style={{ textAlign: "center", marginTop: "40px" }}>
                <div style={{ fontSize: "25pt", fontWeight: "bold" }} >
                    Page doesn't exist
                </div>
            </div>
        </div>
    )
}
export default withAuthenticationRequired(Error404, {
    onRedirecting: () => <Loader />,
  });