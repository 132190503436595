import React from 'react';
import { Message, Input } from "semantic-ui-react";
import { isSuperAdmin } from "../../../../../../Utils/util";
import { useAuth0 } from "@auth0/auth0-react";

const UpgradeFirmwareModalContent = ({ setVersion, device, showErrorMessage, latestFirmwareVersion }) => {
    const { user } = useAuth0();

    return (isSuperAdmin(user)
        ? <>
            {showErrorMessage && 
                <Message negative>
                    <p>Something unexpected happened</p>
                </Message>
             }
              <>
                <Message color={"blue"}>
                    Please input the firmware version <br />
                    If you want to push the latest version, please input "latest"
                </Message>
                <Message color={"grey"}>
                    Current firmware version: {device.firmware ? device.firmware.toString() : "Unknown"}
                </Message>
            </>
            
            <Input placeholder="Please input the firmware version"
                style={{ width: "100%" }}
                onChange={e => setVersion(e.target.value)}
            />
        </>
        : <>
            {showErrorMessage &&
                <Message negative>
                    <p>Something unexpected happened</p>
                </Message>
            }
            <p style={{ color: "black" }}>
                Are you sure you want to update <b>{device.shortname}</b> to <b>{latestFirmwareVersion}</b>?
            </p>

        </>
    );
};

export default UpgradeFirmwareModalContent;