/**
 * Represents the toolbar component for the Bifrost table.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.headerMessage - The header message to display.
 * @param {string} props.pageName - The name of the page.
 * @param {Object} props.pagination - The pagination object.
 * @param {Function} props.setPagination - The function to set the pagination object.
 * @param {Function} props.onFilter - The function to handle filtering.
 * @param {Function} props.fetchData - The function to fetch data.
 * @returns {JSX.Element} The toolbar component.
 */
import React from 'react';
import { useState } from 'react';
import {Header, Icon, Input} from "semantic-ui-react";
import BifrostButton from "../BifrostButton/BifrostButton";


const BifrostTableToolbar = ({ headerMessage, pageName, pagination, setPagination, onFilter, fetchData }) => {
    const [search, setSearch] = useState(pagination ? pagination.search : "")

    // handle the refresh button click. It will reset the pagination state and fetch the data again.
    const handleRefresh = () => {
        setSearch("")
        pagination 
            ? fetchData({
                activePage: 1,
                limit: 10,
                search: "",
                sort: {column: "timestamp", order: "descending"}
            })
            : fetchData()
    }

    // handle the search input change. 
    // The search input will be remove the space at the beginning and the end of the string.
    // The active page will be reset to 1 to show the first page of the search result.
    const handleSearch = (search) => {
        setPagination({...pagination, search: search.trim(),})
        fetchData({...pagination, activePage:1, search: search.trim()})
    }

    return (
        <>
            {headerMessage && <Header style={{padding: "0px 50px"}}>{headerMessage}</Header>}
            <div className="bifrost-table-toolbar">
                <div className="bifrost-table-searchbar-refresh">
                    {
                        !pagination
                            ? <Input
                                icon={"search"}
                                placeholder={`Search ${pageName}`}
                                onChange={onFilter}
                            /> :
                            <Input
                                placeholder={`Search ${pageName}`}
                                onChange={(e) => { setSearch(e.target.value) }}
                                onKeyPress={(e) => { if (e.key === "Enter") handleSearch(search) }}    
                                value={search}
                                action={{ icon: 'search', onClick: () => handleSearch(search) }}
                            />
                    }
                    <BifrostButton onClick={handleRefresh} size="medium" style={{marginLeft: "10px", marginTop: "1px", marginBottom: "1px"}}>
                        <Icon name="refresh" style={{margin: "0px", opacity: "1"}}/>
                    </BifrostButton>
                </div>
            </div>
        </>
    );
};

export default BifrostTableToolbar;