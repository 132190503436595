
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useCallback } from "react";

export const useGetPaginatedAuditHistory = (chosenItem, chosenItemType) => {
    const { getAccessTokenSilently } = useAuth0()

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [pagination, setPagination] = useState({
        totalPages: 1,
        activePage: 1,
        totalRows: 0,
        limit: 10,
        search: "",
        sort: {
            column: 'timestamp',
            order: 'descending'
        }
    })

    const getAuditHistory = useCallback(async (pagination) => {
        setIsLoading(true)
        const sortShort =
            pagination.sort.order === "ascending" ? "asc"
                : pagination.sort.order === "descending" ? "desc"
                    : pagination.sort.order

        console.log("pagination", pagination)

        return fetch(
            process.env.REACT_APP_SESSION_ADDR +
            "/api/v2/"+chosenItemType+"/" + chosenItem.id +
            "/event?page=" + pagination.activePage +
            "&limit=" + pagination.limit +
            "&search=" + pagination.search +
            "&sort=" + pagination.sort.column +
            "&order=" + sortShort,
            {
                method: "GET",
                headers: new Headers({
                    'authorization': "Bearer " + await getAccessTokenSilently(),
                    'Content-Type': 'application/json'
                }),
            })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(result => {
                setData(result.rows ? result.rows : [])
                setPagination({
                    ...pagination,
                    limit: result.limit,
                    totalPages: result.total_pages,
                    activePage: result.page,
                    totalRows: result.total_rows
                })
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false)
            });
    }, [getAccessTokenSilently])


    return { data, isLoading, pagination, setData, setPagination, getAuditHistory }
}