import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useEffect, useState} from "react";

export const useGetUserById = (userID) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [data, setData] = useState({
        id: 0,
        org_id: 0,
        nickname: '',
        role: '',
        email: '',
    });


    const {getAccessTokenSilently} = useAuth0();

    const getUserById = useCallback(async () => {
        setIsLoading(true);
        fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + "/api/v2/user/" + userID, {
                    method: "GET",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        'Content-Type': 'application/json'
                    })
                }
            )
        )
            .then(response => {
                if (response.ok) {
                    console.log("Get User by Id successfully!");
                    return response.json();
                } else if (response.statusText === "Unauthorized") {
                    throw new Error("Unauthorized");
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(result => {
                setIsError(false);
                setData(result ? result : null);
            })
            .catch(err => {
                setIsError(true);
                console.log(err);
            }).finally(() => setIsLoading(false));

    }, [getAccessTokenSilently]);

    useEffect(() => {
        return () => {
            setIsLoading(null);
            setIsError(null);
        }
    }, []);

    return {isLoading, isError, data, setData, getUserById};
}
