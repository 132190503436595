import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const organization = process.env.REACT_APP_AUTH0_ORGANIZATION_ID;
  
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!domain || !clientId || !audience) {
    return null;
  }

  const authorizationParams = organization ? {
      redirect_uri: window.location.origin,
      audience: audience,
      organization : organization,
  } : {
      redirect_uri: window.location.origin,
      audience: audience,
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={
        authorizationParams
      }
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory