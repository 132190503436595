import { useAuth0 } from "@auth0/auth0-react";
import { useState, useCallback, useEffect } from "react";

export const useGetOrgStructure = (chosenOrg) => {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const getOrgStructure = useCallback(async () => {
        setIsLoading(true)
        return fetch(
            process.env.REACT_APP_SESSION_ADDR + "/api/v2/org/" + chosenOrg.id + "/structure",
            {
                method: 'GET',
                headers: new Headers({
                    'authorization': "Bearer " + await getAccessTokenSilently(),
                    'Content-Type': 'application/json'
                }),
            })
            .then(response => {
                if (response.ok) {
                    console.log("Get all data successfully!");
                    return response.json();
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(res => {
                setData(res ? res : [])
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            });
    }, [getAccessTokenSilently])

    useEffect(() => {
        return () => {
            setIsLoading(null);
        }
    }, []);

    return { data, isLoading, getOrgStructure }
}