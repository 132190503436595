import { useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useRebootDevice = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const rebootDeviceById = useCallback(async (id) => {
        setIsLoading(true);
        console.log("Rebooting device with id: " + id)

        return fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + `/api/v2/device/deviceReboot`,
                {
                    method: "POST",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        "Content-Type": "application/json"
                    }),
                    body: JSON.stringify({ id: "" + id })
                }
            )
        ).then(response => {
            if (response.status === 202) {
                console.log("Reboot succeeded")
                return response;
            } else if (response.statusText === "Unauthorized") {
                throw new Error("Unauthorized");
            } else {
                console.log(response.statusText);
                throw new Error("Something went wrong");
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [getAccessTokenSilently])

    return { isLoading, rebootDeviceById };
}

