import {useAuth0} from "@auth0/auth0-react";
import {useState, useCallback} from "react";

/**
 * This hook is used for fetch the paginated session history for the session manager server.
 * @param chosenOrg is the specific org info for reach the data. In this case, use the chosenOrg.id value.
 */
export const useGetPaginatedSessionHistory = (chosenItem, chosenItemType) => {
    const { getAccessTokenSilently } = useAuth0();
    
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    /**
     * Pagination state
     * totalPage: the number of pages. It Depends on the limit of the pages. It will be generated by the session manager.
     * activePage: which page will be showed up in the table.
     * totalRows: the number of records.
     * limit: how many records should be displayed in the table.
     * search: the key word for search record
     * sort: the sort rules for record
     */
    const [pagination, setPagination] = useState({
        totalPages: 1,
        activePage: 1,
        totalRows: 0,
        limit: 10,
        search: "",
        sort: {
            column: 'session_start',
            order: 'descending'
        }
    })
    
    /**
     * The fetch method to get the data by restful API.
     * The method will convert the sort order as short formatter first.
     * Then request with the parameters in the url
     * If the response is 200, then convert the result as json and update the data state.
     * After the process, update the pagination state to the newest state to update the view component.
     * @param pagination Pass in the pagination state from the view component.
     * When invoke the method from the view component. It should pass in the pagination rules to fetch the new data.
     * @returns {Promise<any>}  Returns the promise for synchronized chain invoke. No response value.
     */
    
    const getSessionHistory = useCallback(async (pagination) => {
        setIsLoading(true)
        const sortShort =
            pagination.sort.order === "ascending" ? "asc"
                : pagination.sort.order === "descending" ? "desc"
                    : pagination.sort.order
        
        console.log("pagination", pagination)

        return fetch(
            process.env.REACT_APP_SESSION_ADDR +
            "/api/v2/"+chosenItemType+"/" + chosenItem.id +
            "/session?page=" + pagination.activePage +
            "&limit=" + pagination.limit +
            "&search=" + pagination.search +
            "&sort=" + pagination.sort.column +
            "&order=" + sortShort,
            {
                method: "GET",
                headers: new Headers({
                    'authorization': "Bearer " + await getAccessTokenSilently(),
                    'Content-Type': 'application/json'
                }),
            })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(res => {
                setData(res.rows ? res.rows : [])
                setPagination({
                    ...pagination,
                    limit: res.limit,
                    totalPages: res.total_pages,
                    activePage: res.page,
                    totalRows: res.total_rows
                })
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false)
            });
    }, [getAccessTokenSilently])
    
    return {data, isLoading, setData, pagination, setPagination, getSessionHistory}
}