import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useEffect, useState} from "react";

export const useGetOrganizationByID = (orgID) => {
    const {getAccessTokenSilently} = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState({
        id: 0,
        name: "",
        package: {},
        package_id: 0,
        user_limit: 0
    });

    const getOrganizationByID = useCallback(async () => {
        setIsLoading(true);
        fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + "/api/v2/org/" + orgID, {
                    method: "GET",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        'Content-Type': 'application/json'
                    })
                }
            )
        )
            .then(response => {
                if (response.ok) {
                    console.log("Get Org by Id successfully!");
                    return response.json();
                } else if (response.statusText === "Unauthorized") {
                    throw new Error("Unauthorized");
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(result => {
                setData(result ? result : null);
            })
            .catch(err => {
                setIsError(true);
                console.log(err);
            }).finally(() => setIsLoading(false));

    }, [getAccessTokenSilently]);

    useEffect(() => {
        return () => {
            setIsError(null);
            setIsLoading(null);
        }
    }, []);

    return {isLoading, isError, data, getOrganizationByID};
}
