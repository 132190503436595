import React from 'react';
import { Header, Button } from 'semantic-ui-react';
import { ReactComponent as CompanyLogo } from "@imagesPath/company-logo-white.svg";
import { useAuth0 } from "@auth0/auth0-react";


const ErrorAuth0 = () => {
    const { logout } = useAuth0();

    return (
        <div style={{ position: "absolute", transform: "translate(-50%,-50%)", top: "50%", left: "50%" }}>
            <div style={{ textAlign: "center", marginBottom: "100px", marginLeft: "auto", marginRight: "auto", maxWidth: "500px" }}><CompanyLogo /></div>
            <Header style={{ textAlign: "center", color: "white", fontSize: "80pt" }}>401</Header>
            <div style={{ textAlign: "center", marginTop: "40px" }}>
                <div style={{ fontSize: "25pt", lineHeight: "25pt", fontWeight: "bold" }} >
                    You are not authorized for selected page
                </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
                <Button style={{ color: "white", backgroundColor: "#5589c0" }} onClick={(event) => {
                    event.preventDefault();
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin,
                        }
                    })
                }}>
                    Go to Login
                </Button>
            </div>
        </div>
    )
}
export default ErrorAuth0