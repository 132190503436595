import React from 'react';
import {Message} from "semantic-ui-react";

const BifrostModalConfirmItem = ({showErrorMessage, action, name}) => {
    return (
        <>
            {showErrorMessage &&
                <Message negative>
                    <p>Something unexpected happened</p>
                </Message>
            }
            <p style={{color: "black"}}>
                Are you sure you want to {action} {name}?
            </p>
        </>
    );
};

export default BifrostModalConfirmItem;