import React, { useCallback, useState, useEffect } from 'react'
import "./BifrostTable.scss"
import { Table, Segment } from 'semantic-ui-react'
import { dates, isDate } from '../../../Utils/util'
import Loader from '../../BifrostPageComponent/Loader/Loader'

import BifrostTableToolbar from "./BifrostTableToolbar";
import BifrostTableHeader from './BifrostTableHeader'
import BifrostTableBody from "./BifrostTableBody";
import BifrostTableFooter from "./BifrostTableFooter";

const compareDate = (columnA, columnB) => {
    let difference
    if (!isDate(columnA)) difference = -1;
    else if (!isDate(columnB)) difference = 1;
    else difference = dates.compare(new Date(columnA), new Date(columnB));
    return difference
}

/**
 * BifrostTable component.
 * 
 * @component
 * @param {string} pageName - The name of the page.
 * @param {string} header - The header message.
 * @param {boolean} loading - Indicates whether the table is loading.
 * @param {Array} data - The data to be displayed in the table.
 * @param {Array} columns - The columns configuration for the table.
 * @param {React.Component} Row - The component to render each row in the table.
 * @param {Object} pagination - The pagination configuration for the table.
 * @param {Function} setPagination - The function to update the pagination state.
 * @param {Function} filterFunc - The function to filter the data.
 * @param {Object} defaultSort - The default sort configuration for the table.
 * @param {boolean} isSelectable - Indicates whether the table rows are selectable.
 * @param {Function} fetchData - The function to fetch data for the table.
 * @returns {JSX.Element} The rendered BifrostTable component.
 */
const BifrostTable = ({ pageName, header, loading, data, columns, Row,
    pagination, setPagination, filterFunc, defaultSort, isSelectable, fetchData, }) => {
    
    const [filter, setFilter] = useState("")
    const [sort, setSort] = useState(defaultSort)
    const [items, setItems] = useState(data)

    /**
     * Handle the sort request. 
     * 
     * This method will be invoke when the `onSort` function be triggered. 
     * The new sort rule will be set to the pagination state and then send a http request to fetch the new sorted data.
     * 
     * The rest of the pagination state will be kept the same.
     * The pagination state will be updated again by the http request.
     * 
     * @param sort the new sort rule: toggle between ascending and descending
     * @returns void
     */
    const handleSort = (sort) => {
        setPagination({...pagination, sort: sort, activePage: 1})
        fetchData({...pagination, sort: sort, activePage: 1})
    }

    
    const sortItems = useCallback(() => {
        const defaultCompare = (a, b) => {
            let columnA = a[sort.column]
            let columnB = b[sort.column]

            let difference
            if (typeof (columnA) === 'number') {
                if (columnA > columnB) difference = 1
                else if (columnA < columnB) difference = -1
                else difference = 0
            } else if (typeof (columnA) === 'string') {
                if (isDate(columnA) || isDate(columnB)) {
                    difference = compareDate(columnA, columnB)
                } else {
                    difference = columnB.localeCompare(columnA)
                }
            } else difference = 0
            if (sort.order === 'ascending')
                return -difference
            else
                return difference
        }

        let sortedColumn = columns.find(col => col.key == sort.column)
        if (sortedColumn.isUnSortable) return
        if (sortedColumn.compareFunction) {
            if (sort.order === 'ascending') setItems(data.filter((dev) => filterFunc(dev, filter)).sort(sortedColumn.compareFunction))
            else setItems(data.filter((dev) => filterFunc(dev, filter)).sort(sortedColumn.compareFunction).reverse())
        } else setItems(data.filter((dev) => filterFunc(dev, filter)).sort(defaultCompare))
    }, [data, filter, filterFunc, sort.column, sort.order])


    /**
     * Trigger the sort action.
     * 
     * When the column's header be clicked, this function will invoke and switch the sort order. 
     * Then if the `pagination` object is not null, then invoke the `handleSort` function to request the new sorted data.
     * If the `pagination` object is null, which means the data has not been paginate, then just sort the data by change the sort state.
     * 
     * @param clickedColumn the column that need to be sort.
     */
    const onSort = (clickedColumn) => {
        let newOrder = sort.order === "ascending" ? "descending" : "ascending"

        if (sort.column !== clickedColumn) {
            newOrder = "descending"
        }

        let newSort = { column: clickedColumn, order: newOrder }

        pagination && handleSort(newSort)

        setSort(newSort)
    };

    const onFilter = (event) => {
        if (event.target.value !== filter) {
            setFilter(event.target.value);
        }
    };

    /**
     * When the pagination is null, use sortItem function to sort the data.
     * Otherwise, sort data will be active by other method but not this.
     */
    useEffect(() => {
        !pagination && sortItems()
    }, [pagination, sortItems])
    
    /**
     * update the data if it is changed.
     */
    useEffect(() => {
        data && setItems(data)
    }, [data])

    return (
        <Segment>
            <div className={'bifrost-table-container'}>
                <BifrostTableToolbar
                    headerMessage={header}
                    pagination={pagination}
                    setPagination={setPagination}
                    pageName={pageName}
                    onFilter={onFilter}
                    fetchData={fetchData}
                />
                {loading ?
                    <Loader backgroundColor="white" inverted height="1px" minHeight="200px" /> :
                    <Table padded basic='very' sortable className='no-border pad' unstackable selectable={isSelectable} >
                        <BifrostTableHeader
                            columns={columns}
                            sortedColumn={sort?.column}
                            direction={sort?.order}
                            handleSort={onSort}
                        />
                        <BifrostTableBody
                            items={items}
                            Row={Row}
                        />
                    </Table>
                }
            </div>
            {(pagination && !loading) && <BifrostTableFooter pagination={pagination} fetchData={fetchData} />}
        </Segment>
    )
}

export default BifrostTable
