import React from 'react'
import BifrostButton from '../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton'

export const RebootDeviceModalActions = ({ showErrorMessage, isLoading, confirmHandler, cancelHandler }) => {

    return (
        <>
            <BifrostButton disabled={isLoading} style={{ display: "inline-block" }} onClick={() => confirmHandler()} >
             {showErrorMessage ? "Retry" : "Reboot"}
            </BifrostButton>

            <BifrostButton
                style={{ display: "inline-block" }}
                color='gray'
                onClick={() => cancelHandler()}
                disabled={isLoading} 
            >
                Cancel
            </BifrostButton>
        </>
    )
}
