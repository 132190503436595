/**
 * Renders the footer section of the BifrostTable component.
 * 
 * @param {Object} pagination - The pagination object containing information about the current page and limit.
 * @param {Function} fetchData - The function to fetch data based on the updated pagination.
 * @returns {JSX.Element} The rendered BifrostTableFooter component.
 */
import React from 'react';
import { Dropdown, Pagination, Segment, Divider} from 'semantic-ui-react';

// The options for the page limit dropdown.
const pageOptions = [
    { key: '10', value: 10, text: '10' },
    { key: '20', value: 20, text: '20' },
    { key: '50', value: 50, text: '50' },
    { key: '0', value: 0, text: 'All' }
]

const BifrostTableFooter = ({ pagination, fetchData }) => {
    
    const handlePageChange = (event, activePage) => fetchData({ ...pagination, activePage: activePage })
    
    // Handle the limit change. When the limit is changed, the active page will be reset to 1. 
    // (if the user active the second page and change the limit, and the active page is not reset to 1, then the data showing up as seconde page will be different with the last view and cause the confusion.)
    const handleLimitChange = (event, limit) => fetchData({...pagination, limit: limit, activePage: 1})
    
    return (
        <>
            <Divider />
            <Segment basic textAlign='right'>
                Per page&nbsp;&nbsp;&nbsp;
                <Dropdown
                    selection
                    onChange={(event, data) => handleLimitChange(event, data.value)}
                    value={pagination.limit}
                    style={{ minWidth: "150px", marginRight: "50px" }}
                    options={pageOptions}
                />
                {pagination.activePage * pagination.limit - pagination.limit + 1} - {pagination.limit ? pagination.activePage * pagination.limit : pagination.totalRows} of {pagination.totalRows}
                <Pagination
                    style={{ marginLeft: "30px", marginRight: "100px" }}
                    boundaryRange={1}
                    activePage={pagination.activePage}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pagination.totalPages}
                    onPageChange={(event, data) => handlePageChange(event, data.activePage)}
                />  
            </Segment>
        </>
    )
}

export default BifrostTableFooter; 