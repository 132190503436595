import {useState, useCallback} from "react";
import {useAuth0} from "@auth0/auth0-react";

export const useGetAllData = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const {getAccessTokenSilently} = useAuth0();

    const getAllData = useCallback(async (url, mapping) => {
        setIsLoading(true);
        return fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + url,
                {
                    method: "GET",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        "Content-Type": "application/json"
                    })
                }
            )
        ).then(response => {
            if (response.ok) {
                console.log("Get all data successfully!");
                return response.json();
            } else if (response.statusText === "Unauthorized") {
                throw new Error("Unauthorized");
            } else {
                console.log(response.statusText);
                throw new Error("Something went wrong");
            }
        }).then(result => {
            setData(result ? mapping(result) : []);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [getAccessTokenSilently]);

    return {data, setData, isLoading, getAllData};
}