import React from 'react';
import BifrostButton from "../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import {Icon} from "semantic-ui-react";
import '../../Pages.scss'
import './MainPanelHeader.scss'
import {useNavigate} from "react-router-dom";
import {useMatch} from "react-router";

const MainPanelHeader = ({title, name, userLimitItem, children, navPath}) => {
    const nav = useNavigate();
    const match = useMatch('/activity');

    return (
        <section className={'main-panel-header'}>
            <nav className={'table'}>
                <h1 className={'table-cell'} style={{alignItems: "center"}}>
                    { name && !match
                        ? <>
                            <BifrostButton size="huge"
                                           style={{
                                               display: "inline-block",
                                               backgroundColor: "inherit",
                                               color: "black",
                                               marginBottom: "1px",
                                               padding: 0
                                           }}
                                           onClick={() => {
                                            nav(navPath);
                                           }}
                            ><Icon name="chevron left"/>
                            </BifrostButton>
                            {name}</>
                        : <div className="user-title">
                            {title}{userLimitItem && <div>{userLimitItem}</div>}
                        </div>
                    }
                </h1>
            </nav>
            <div className="main-panel-header-create-modal">
                {title && <nav className={'modal-button'}>{children}</nav>}
            </div>
        </section>
    );
};

export default MainPanelHeader;