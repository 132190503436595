import React from 'react'
import { Message } from 'semantic-ui-react'


export const RebootDeviceModalPage = ({ deviceName, showErrorMessage }) => {


    return (
        <>
            {
                showErrorMessage ?
                    <Message negative>Could not reboot device</Message> :
                    <p style={{ color: "black" }}>
                        Are you sure you want to reboot device <b>{deviceName}</b>?
                    </p>

            }
        </>
    )
}
