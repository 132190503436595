import {useCallback} from "react";
import {useGetAllData} from "./useGetAllData/useGetAllData";

export const useGetPackages = () => {
    const {data, setData, isLoading, getAllData} = useGetAllData();

    const getPackages = useCallback(() => {
        getAllData("/api/v2/package", result => result)
    },[getAllData]);

    return {data, setData, isLoading, getPackages};
}