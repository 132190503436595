import React, { useState } from 'react'
import BifrostModal from '../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal'
import Loader from '../../../../../../Components/BifrostPageComponent/Loader/Loader'
import { RebootDeviceModalPage } from './RebootDeviceModalPage'
import { RebootDeviceModalActions } from './RebootDeviceModalActions'
import { useRebootDevice } from '../../../../../../Hooks/OprateDeviceHooks/useRebootDevice'
import { Popup, Icon, DropdownItem } from 'semantic-ui-react'
import { isVersionHigherOrEqual } from '../../../../../../Utils/util'

export const RebootDeviceModal = ({ chosenItem, disabled }) => {
    const { isLoading, rebootDeviceById } = useRebootDevice();

    const [openModal, setOpenModal] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccessIcon, setShowSuccessIcon] = useState(false)

    const confirmHandler = () => {
        rebootDeviceById(chosenItem.id)
            .then(() => {
                setShowErrorMessage(false)
                setShowSuccessIcon(true);
                setTimeout(() => {
                    // After 3 seconds set the show value to false
                    setShowSuccessIcon(false)
                    setOpenModal(false);
                  }, 1500)
            })
            .catch(() => {
                setShowErrorMessage(true)
                setShowSuccessIcon(false);
            })
    }

    const cancelHandler = () => {
        setShowErrorMessage(false)
        setOpenModal(false)
        setShowSuccessIcon(false);
    }

    return (
        <>
            {
                    <BifrostModal
                        title={`Reboot Device`}
                        closeOnEscape={!isLoading}
                        closeOnDimmerClick={!isLoading}
                        open={openModal}
                        setOpen={setOpenModal}
                        trigger={
                            <Popup disabled={isVersionHigherOrEqual(chosenItem.firmware, "v4.9.0")} content='Firmware v4.9.0 or higher required' wide='very'
                                trigger={
                                    <span className={'dropdown-span item'}>
                                        <DropdownItem onClick={disabled ? () => {} : () => setOpenModal(true)} icon="redo" text='Reboot' disabled={disabled} />
                                    </span>
                                }
                            />
                        }
                        modalPage={
                            isLoading ?
                                <Loader backgroundColor="white" inverted height="1px" minHeight="50px" /> :
                                showSuccessIcon ? <Icon style={{width: "100%", textAlign: "center", color: "#39C4AA"}} name="check circle outline" size="huge" /> :
                                <RebootDeviceModalPage
                                    deviceName={chosenItem.shortname}
                                    showErrorMessage={showErrorMessage}
                                />
                        }
                        modalActions={
                            <RebootDeviceModalActions
                                isLoading={isLoading || showSuccessIcon}
                                showErrorMessage={showErrorMessage}
                                confirmHandler={confirmHandler}
                                cancelHandler={cancelHandler}
                            />
                        }
                    />
            }
        </>
    )
}
