export const isSuperAdmin = (claims) => {
    try{
        return claims.role === "SuperAdmin";
    }catch(e){
        return false;
    }

}

export const isAdmin = (claims) => {
    try{
        return claims.role === "Admin";
    }catch(e){
        return false;
    }
    
}

export const isSuperAdminOrAdmin = (claims) => {
    try{
        return isAdmin(claims) || isSuperAdmin(claims);
    }catch(e){
        return false;
    }
  
}

export const isDate = function (date) {
    if(!parseInt(date.replace('-',''))){
        return false
    }
    
    date = new Date(date)
    return (date !== "Invalid Date") && !isNaN(date);
}

export const hasFeatureInPackage = (userInfo, featureName) => {
    try{
        return userInfo.features.includes(featureName);
    }catch(e){
        return false;
    }
}

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export const isVersionHigherOrEqual = (version1, version2) => {

if (typeof version1 !== 'object') {
    version1 = version1.toString().split('.');
}
if (typeof version2 !== 'object') {
    version2 = version2.toString().split('.');
}

for (let i = 0; i < (Math.max(version1.length, version2.length)); i++) {

    if (version1[i] === undefined && version1[i] === null) {
        version1[i] = "0";
    }
    if (version2[i] === undefined && version2[i] === null) {
        version2[i] = "0";
    }

    if (version1[i].includes("-")) version1[i] = version1[i].split("-")[0]
    if (version1[i].includes("-")) version1[i] = version1[i].split("-")[0]

    if (Number(version1[i]) > Number(version2[i])) {
        return true;
    }
    if (version1[i] !== version2[i]) {
        return false
    }
}
return true;
}


  // Source: http://stackoverflow.com/questions/497790
export const dates = {
  convert:function(d) {
      // Converts the date in d to a date-object. The input can be:
      //   a date object: returned without modification
      //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
      //   a number     : Interpreted as number of milliseconds
      //                  since 1 Jan 1970 (a timestamp) 
      //   a string     : Any format supported by the javascript engine, like
      //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
      //  an object     : Interpreted as an object with year, month and date
      //                  attributes.  **NOTE** month is 0-11.
      return (
          d.constructor === Date ? d :
          d.constructor === Array ? new Date(d[0],d[1],d[2]) :
          d.constructor === Number ? new Date(d) :
          d.constructor === String ? new Date(d) :
          typeof d === "object" ? new Date(d.year,d.month,d.date) :
          NaN
      );
  },
  compare:function(a,b) {
      // Compare two dates (could be of any type supported by the convert
      // function above) and returns:
      //  -1 : if a < b
      //   0 : if a = b
      //   1 : if a > b
      // NaN : if a or b is an illegal date
      // NOTE: The code inside isFinite does an assignment (=).
      return (
          isFinite(a=this.convert(a).valueOf()) &&
          isFinite(b=this.convert(b).valueOf()) ?
          (a>b)-(a<b) :
          NaN
      );
  },
  inRange:function(d,start,end) {
      // Checks if date in d is between dates in start and end.
      // Returns a boolean or NaN:
      //    true  : if d is between start and end (inclusive)
      //    false : if d is before start or after end
      //    NaN   : if one or more of the dates is illegal.
      // NOTE: The code inside isFinite does an assignment (=).
     return (
          isFinite(d=this.convert(d).valueOf()) &&
          isFinite(start=this.convert(start).valueOf()) &&
          isFinite(end=this.convert(end).valueOf()) ?
          start <= d && d <= end :
          NaN
      );
  }
}
