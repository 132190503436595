import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useEffect, useState} from "react";

export const useGetDeviceById = (deviceID) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [data, setData] = useState({
        id: 0,
        org_id: 0,
        serialnr: '',
        shortname: '',
        state: '',
        timestamp: '',
        firmware: '',
        type: '',
        org_name: '',
        groups: []
    });


    const {getAccessTokenSilently} = useAuth0();

    const getDeviceById = useCallback(async () => {
        setIsLoading(true);
        fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + "/api/v2/device/" + deviceID, {
                    method: "GET",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        'Content-Type': 'application/json'
                    })
                }
            )
        )
            .then(response => {
                if (response.ok) {
                    console.log("Get Device by Id successfully!");
                    return response.json();
                } else if (response.statusText === "Unauthorized") {
                    throw new Error("Unauthorized");
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(result => {
                setIsError(false);
                setData(result ? result : null);
            })
            .catch(err => {
                setIsError(true); 
                console.log(err);
            }).finally(() => setIsLoading(false));

    }, [getAccessTokenSilently]);

    useEffect(() => {
        /**
         * Because the hook will be loaded before the component is mounted at the first time the component start, 
         * but at the same time the compoent is not be mounted yet,
         * so the react will warning about the isError state can not be set on an unmounted component. 
         * This situation only happened when the user refresh the page or the page is loaded at the first time.
         * To solve this problem, we need to add a return function in the useEffect hook to set the isError state to false when the component is unmounted.
         * [stackflow first answer](https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp?newreg=f583afb1204540c4845050e4af8bb172)
         */
        return () => {
            setIsError(null);
            setIsLoading(null);
        }
    }, []);

    return {isLoading, data, setData, isError, getDeviceById};
}
