import React from 'react';
import {Input, Label} from "semantic-ui-react";
import Loader from "../../../../../../Components/BifrostPageComponent/Loader/Loader";

const RenameDeviceModalPage = (props) => {
    const {
        inputData,
        setInputData,
        isLoading,
        showConfirmMessage,
        nameIsValid,
        nameLengthOK,
        validateDeviceName
    } = props;


    const nameIsNotValidMessage = "Not Valid! Please use alphanumeric, dash and underscore without space";
    const nameLengthNotOKMessage = "The new name should between 3 and 16 characters!";


    const inputHandler = (e) => {
        validateDeviceName(e.target.value);
        setInputData(e.target.value);
    }

    return (
        <>
            {
                isLoading ?
                    <Loader backgroundColor="white" inverted height="50px"/>
                    :
                    showConfirmMessage
                        ? <p style={{color: "black"}}>Please confirm your new device name is: <b>{inputData}</b></p>
                        : <>
                            <Input
                                value={inputData}
                                style={{width: "100%"}}
                                placeholder={"Please use a-z, A-Z, 0-9, _ and -"}
                                onChange={inputHandler}/>
                            {
                                (!nameIsValid && inputData !== "")
                                && <Label basic pointing={"above"} color={"red"} size={"medium"}>
                                    {nameIsNotValidMessage}
                                </Label>}
                            {
                                !nameLengthOK
                                && <Label basic pointing={"above"} color={"red"} size={"medium"}>
                                    {nameLengthNotOKMessage}
                                </Label>}
                        </>
            }
        </>
    );
};

export default RenameDeviceModalPage;