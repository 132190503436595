/**
 * Renders a card component displaying the last session and session count for a chosen organization.
 *
 * @component
 * @param {Object} chosenOrg - The chosen organization object.
 * @returns {JSX.Element} The rendered card component.
 */
import React, {useEffect} from 'react';
import {Card, Statistic} from "semantic-ui-react";
import Loader from "../Loader/Loader";
import {
    useGetOrgSessionCount
} from "../../../Hooks/FetchHooks/GetDataService/getOrganizationData/useGetOrgSessionCount";
import {useGetOrgLastSession} from "../../../Hooks/FetchHooks/GetDataService/getOrganizationData/useGetOrgLastSession";

const BifrostLastSessionComponent = ({chosenOrg}) => {
    const { data:orgSessionCount, isLoading:sessionCountLoading, getSessionCount:fetchOrgSessionsCount } = useGetOrgSessionCount(chosenOrg)
    const { data:orgLastSession, isLoading:lastSessionLoading, getOrgLastSession:fetchOrgLastSession } = useGetOrgLastSession(chosenOrg)
    
    useEffect(() => {
        if (chosenOrg && process.env.REACT_APP_SAML_ENABLED !== "true") {
            fetchOrgSessionsCount(chosenOrg.id)
            fetchOrgLastSession(chosenOrg.id)
        }
    },[])
    
    return (
        <Card fluid style={{ height: "100%" }}>
            <Card.Content>
                <Statistic.Group widths={2}>
                    <Statistic>
                        <Statistic.Value>{lastSessionLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="55px" /> : orgLastSession}</Statistic.Value>
                        <Statistic.Label>Last Session (UTC)</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>{sessionCountLoading ?
                            <Loader backgroundColor="white" inverted height="1px"
                                    minHeight="55px" /> : Number.isInteger(orgSessionCount) ? orgSessionCount : "-"}</Statistic.Value>
                        <Statistic.Label>Sessions Last 30 days</Statistic.Label>
                    </Statistic>
                </Statistic.Group>
            </Card.Content>
        </Card>
    );
};

export default BifrostLastSessionComponent;