import {useCallback, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

export const usePostDeviceNameById = () => {
    const [isLoading, setIsLoading] = useState(false);

    const {getAccessTokenSilently} = useAuth0();

    const postDeviceNameById = useCallback( async (id, input) => {
        setIsLoading(true);
        return fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + "/api/v2/device/rename",
                {
                    method: "POST",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        "Content-Type": "application/json"
                    }),
                    body: JSON.stringify({id: "" + id, shortname: input})
                }
            )
        ).then(response => {
            if (response.ok) {
                console.log("Rename is successful")
                return response;
            } else if(response.status === 400) {
                throw new Error("This device name is already in use")
            } else if (response.statusText === "Unauthorized") {
                throw new Error("Unauthorized");
            } else {
                console.log(response.statusText);
                throw new Error("Something went wrong");
            }
        }).finally(() => {
            setIsLoading(false);
        })
    },[getAccessTokenSilently])

    return {isLoading, postDeviceNameById};
}