import React, {useEffect, useContext} from 'react'
import "../../../Pages.scss";
import {Table} from 'semantic-ui-react'

import {useAuth0} from "@auth0/auth0-react"
import {hasFeatureInPackage, isSuperAdmin, isSuperAdminOrAdmin} from "../../../../Utils/util";
import Error404 from '../../../OtherPages/ErrorPage/Error404';

import MainPanelHeader from "../../MainPanelHeader/MainPanelHeader";
import MainPanelFooter from "../../MainPanelFooter/MainPanelFooter";
import CreateGroupModal from "./GroupModal/CreateGroupModal/CreateGroupModal";
import BifrostTable from "../../../../Components/BifrostElementComponent/BifrostTable/BifrostTable"
import {useGetGroups} from "../../../../Hooks/FetchHooks/GetDataService/useGetGroups";
import userInfoContext from '../../../../store/userInfoContext';

const GroupsPage = () => {
        const userInfo = useContext(userInfoContext);

        const {user, isAuthenticated} = useAuth0()
        const {data, setData, isLoading, getGroups} = useGetGroups();

        const columns = React.useMemo(
            () => {
                const superAdminColumns = isSuperAdmin(user) ? [{
                    name: "Organization",
                    key: "org_name",
                    type: "string"
                }] : []

                return superAdminColumns.concat([
                    {
                        name: "Name",
                        key: "name",
                        type: "string"

                    },
                    {
                        name: "Users",
                        key: "no_users",
                        type: "number"
                    },
                    {
                        name: "Devices",
                        key: "no_devices",
                        type: "number"
                    },
                    {
                        name: "Last Session (UTC)",
                        key: "last_session",
                        type: "number"
                    },
                    {
                        name: "Edit",
                        key: "edit",
                        isUnSortable: true
                    },
                    {
                        name: "Delete",
                        key: "delete",
                        isUnSortable: true
                    },
                ])
            },
            [user]
        );
        const filterGroup = (group, filter) => {
            if (!filter || filter === "") return true
            else
                return group.name.toLowerCase().includes(filter.toLowerCase()) || group.org_name.toLowerCase().includes(filter.toLowerCase())
        }
        const GroupRow = ({item}) => (
            <Table.Row key={item.id}>
                {isSuperAdmin(user)
                    ? <Table.Cell>{item.org_name}</Table.Cell>
                    : null}
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.no_users}</Table.Cell>
                <Table.Cell>{item.no_devices}</Table.Cell>
                <Table.Cell>{item.last_session}</Table.Cell>
                <Table.Cell>{item.edit}</Table.Cell>
                <Table.Cell>{item.delete}</Table.Cell>
            </Table.Row>
        )


        useEffect(() => {
            if (isAuthenticated && isSuperAdminOrAdmin(user) && userInfo && userInfo.terms_accepted) {
                getGroups();
            }
            return () => setData([])
        }, [isAuthenticated])

        return (
            (!isSuperAdminOrAdmin(user) || process.env.REACT_APP_SAML_ENABLED === "true" || !hasFeatureInPackage(userInfo, "groups"))
                ? <Error404/>
                : <section className={`bifrost-table-panel devices-table`}>
                    <MainPanelHeader title="Groups" fetchData={getGroups} >
                        <div className="create-group-modal">
                            <CreateGroupModal refreshGroups={getGroups}/>
                        </div>
                    </MainPanelHeader>
                    <BifrostTable
                        loading={isLoading}
                        pageName='Groups'
                        data={data}
                        columns={columns}
                        Row={GroupRow}
                        filterFunc={filterGroup}
                        defaultSort={{order: 'ascending', column: isSuperAdmin(user) ? 'org_name' : 'name'}}
                        fetchData={getGroups}
                    />
                    <MainPanelFooter/>
                </section>
        );
    }
;
export default GroupsPage;