import React from 'react';
import {Modal} from "semantic-ui-react";

const BifrostModal = ({title, open, closeOnEscape, closeOnDimmerClick, setOpen, trigger, modalPage, modalActions}) => {

    return (
        <>
            <Modal
                size="tiny"
                dimmer="blurring"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={trigger}
                closeOnDimmerClick={typeof(closeOnDimmerClick) === "boolean" ? closeOnDimmerClick : true}
                closeOnEscape={typeof(closeOnEscape) === "boolean" ? closeOnEscape : true}
            >
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                        {modalPage}
                </Modal.Content>
                <Modal.Actions>
                    {modalActions}
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default BifrostModal;