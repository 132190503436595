import {Icon} from "semantic-ui-react";
import React, {useState} from "react";
import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import CreateUserPage from "./CreateUserPage";
import {validateInput} from "../../../../../../Utils/Validator/ValidateInput";
import BifrostModal from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import {usePostData} from "../../../../../../Hooks/FetchHooks/PostDataService/usePostData";
import Loader from '../../../../../../Components/BifrostPageComponent/Loader/Loader'

const initialValueState = {
    email: "",
    nickname: "",
    org_id: null,
    role: null,
    groups: []
};

const initialValidatedState = {
    email: true,
    nickname: true,
    org_id: true,
    role: true,
    groups: true
}

export const CreateUserModal = ({refreshUsers}) => {

    const [values, setValues] = useState(initialValueState)
    const [validated, setValidated] = useState(initialValidatedState)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const {isLoading, postItem} = usePostData();

    const submitHandler = () => {
        validateInput(values, validated, setValidated)
        && postItem("/api/v2/user", values, refreshUsers).then(() => {
            setValidated({...initialValidatedState});
            setValues({...initialValueState});
            setShowErrorMessage(false);
            setOpenModal(false);
        }).catch(() => {
            setValidated({...initialValidatedState});
            setValues({...initialValueState});
            setShowErrorMessage(true);
        })
    };

    const cancelHandler = () => {
        setValidated({...initialValidatedState});
        setValues({...initialValueState});
        setShowErrorMessage(false);
        setOpenModal(false);
    }

    return (
        <BifrostModal title={"Create User"}
                      open={openModal}
                      setOpen={setOpenModal}
                      closeOnEscape={!isLoading}
                      closeOnDimmerClick={!isLoading}
                      trigger={
                          <BifrostButton>
                              <Icon name='user'/>
                              Create
                          </BifrostButton>
                      }
                      modalPage={
                        isLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="200px"/> :
                          <CreateUserPage validated={validated}
                                          values={values}
                                          setValues={setValues}
                                          showErrorMessage={showErrorMessage}
                          />
                      }
                      modalActions={
                          <>
                              <BifrostButton style={{display: "inline-block"}}
                                             onClick={() => submitHandler()}
                                             disabled={isLoading}>
                                  Submit
                              </BifrostButton>
                              <BifrostButton onClick={() => cancelHandler()}
                                             style={{display: "inline-block"}}
                                             color="gray"
                                             disabled={isLoading}>
                                  Cancel
                              </BifrostButton>
                          </>
                      }
        />
    )
}