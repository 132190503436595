import React from 'react';
import {Form, Message} from "semantic-ui-react";

const BifrostModalCreateItem = ({showErrorMessage, children}) => {

    return (
        <>
            
               
                 <>
                    {showErrorMessage && <Message negative>
                        <p>Something unexpected happened</p>
                    </Message>}
                    <Form>
                        {children}
                    </Form>
                </>
            
        </>
    );
};

export default BifrostModalCreateItem;