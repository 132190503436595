import {useGetAllData} from "./useGetAllData/useGetAllData";
import {useCallback} from "react";

export const useGetUsers = () => {
    const {data, setData, isLoading, getAllData} = useGetAllData();

    const getUsers = useCallback( () => {
        getAllData("/api/v2/user", result => {
            return result.map(user => {
                user.org_name = user.org.name
                user.name = user.nickname
                delete user.nickname
                return user
            })
        })
    },[getAllData]);

    return {data, setData, isLoading, getUsers};
}