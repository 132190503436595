import {withAuthenticationRequired} from "@auth0/auth0-react";
import React from "react";
import Loader from "../Loader/Loader";

const ProtectedRoute = ({component, props}) => {

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <Loader/>,
    });

    return <Component {...props} />;
};

export default ProtectedRoute