import React, { useState, useCallback } from 'react'
import { ReactComponent as CompanyLogo } from "@imagesPath/company-logo-black.svg";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react"
import { Modal, Message } from 'semantic-ui-react'
import BifrostButton from '../../../Components/BifrostElementComponent/BifrostButton/BifrostButton';
import Loader from '../../../Components/BifrostPageComponent/Loader/Loader';


const AcceptUserTerms = () => {
  const { logout, getAccessTokenSilently } = useAuth0();
  const [openModal, setOpenModal] = useState(true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const termsVersion = "1.1"

  const postAcceptTerms = useCallback(async () => {
    setLoading(true)
    const request = new Request(process.env.REACT_APP_SESSION_ADDR + "/api/v2/user/accept", {
      method: 'POST',
      headers: new Headers({
        'authorization': "Bearer " + await getAccessTokenSilently(),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({version: termsVersion})
    });

    fetch(request)
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        console.log("Successfully accept terms")
        
      })
      .then(() => {
        window.location.reload(false);
      })
      .catch(error => {
        setShowErrorMessage(true)
        console.log(error);
        setLoading(false)
      });
  }, [getAccessTokenSilently])

  return (
    <div style={{ position: "absolute", transform: "translate(-50%,-50%)", top: "50%", left: "50%" }}>


      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={openModal}
        dimmer="blurring"
        onClose={() => setOpenModal(false)}
        onOpen={() => setOpenModal(true)}
        style={{overflowY: "hidden"}}
        size='small'
      //   trigger={<Button>Scrolling Content Modal</Button>}
      >
        <Modal.Header>Terms of Use <CompanyLogo style={{width: "100px", height: "30px", float: "right"}} /></Modal.Header>
        <Modal.Content scrolling style={{color: "black", maxHeight: "calc(80vh - 20em)"}} >
        {showErrorMessage &&
            <Message negative>
              <p>Something unexpected happened</p>
            </Message>
          }

            <h4 style={{ textAlign: "center" }}>PRODUCT USE TERMS FOR BIFROSTCONNECT <br />
              APPLICABLE FROM 1st JULY 2023 (VERSION {termsVersion})</h4>

            <strong>1. SCOPE AND APPLICATION</strong><br />
            1.1. These product use terms ("Use Terms") applies to anyone who uses (a "User") the
            BifrostConnect Solution (as defined in clause 2.1) provided by BifrostConnect ApS
            ("BifrostConnect") or other services ("Services") delivered by BifrostConnect. Clauses
            applying to Core Services (as defined in clause 2.2) also apply to Services mutatis
            mutandi.<br />
            <br />
            1.2. If the User is a customer ("Customer") of BifrostConnect, the Use Terms functions as
            a schedule to (and thereby an integral part of) the general terms and conditions of
            products and services applicable to the agreement between the Customer and
            BifrostConnect.<br />
            <br />
            1.3. If the User is not a Customer (but a subsidiary, parent company or other affiliate of the
            Customer, a customer of the Customer, or a user otherwise authorised by the Customer
            to use the BifrostConnect Solution as agreed between BifrostConnect and the
            Customer), the Use Terms sets out (i) certain obligations which the User has towards
            BifrostConnect (as a third party beneficiary) in relation to the User's use of the
            BifrostConnect Solution, and (ii) certain information relevant for the User about the
            BifrostConnect Solution.<br />
            <br />
            1.4. Even if the User is not a Customer, BifrostConnect is entitled (as a third party
            beneficiary) to rely on the Use Terms and assert or bring any action or claim against
            the User. The Customer is responsible for the User's fulfillment of Its obligations under
            these Use Terms.<br />
            <br />
            1.5. These Use Terms include certain clauses which set out obligations of BifrostConnect
            towards the Customer. If the User is not a Customer, the User understands and accepts
            (i) that BifrostConnect bears no obligations towards the User under these Use Terms,
            and (ii) that the User shall in any case not assert or bring any action or claim against
            BifrostConnect under these Use Terms or in relation to the BifrostConnect Solution or
            Services on whatever grounds.<br />
            <br />
            <strong>2. DESCRIPTION OF THE BIFROSTCONNECT SOLUTION</strong><br />
            2.1. BifrostConnect produces and delivers a solution (the "BifrostConnect Solution") that
            consists of a Hardware Unit (as described in clause 2.2) and a Unit Usage License (as
            described in clause 2.3). The BifrostConnect Solution enables secure (i) remote access
            to, (ii) remote control of and/or (iii) remote connection between IT equipment and
            devices (each a "Remote Device"), including computers, mobile phones, PLCs, IoT devices and network equipment. The secure connection to and/or between
            Remote Devices can be achieved by various means, including by establishing KVM
            access, serial terminal, an IP tunnel, a serial tunnel, or a USB tunnel. The
            BifrostConnect Solution is browser-based and a plug and play solution with no
            software download or installation required on the Remote Devices.<br />
            <br />
            2.2. A "Hardware Unit" is a compact remote-access unit that can be connected to one or
            more Remote Devices whereby the unit facilitates remote access to and/or between such Remote
            Devices.<br />
            <br />
            2.3. The "Unit Usage License" (which forms part of the BifrostConnect Solution) gives the
            Customer the right to access to services ("Core Services") (i) ensuring the
            authentication and encrypted data stream between the Remote Devices, and (ii)
            providing a web interface to be applied when enabling the secure remote access
            between the Remote Devices. Also, it may be agreed that the Unit Usage License includes optional services ("Optional Services") such as identity and access management, audit logging, and API integrations.<br />
            <br />
            <strong>3. SPECIFICATIONS, REQUIREMENTS, UPDATES AND ACCESSIBILITY</strong><br />
            3.1. The BifrostConnect Solution has technical specifications ("Technical Specifications")
            described at <a target="_blank" href="https://bifrostconnect.com">https://bifrostconnect.com</a>.<br />
            <br />
            3.2. The requirements (the "Requirements") for the User's use of the BifrostConnect
            Solution are specified in the description which can be found at <a target="_blank" href="https://bifrostconnect.com">https://bifrostconnect.com</a>.<br />
            <br />
            3.3. The software and technical solutions relating to Core Services provided under the Unit
            Usage Licenses and the Hardware Unit are sought to be continuously improved, and
            BifrostConnect may therefore change or update the applications, services, technical
            solutions, and firmware on an ongoing basis.<br />
            <br />
            3.4. The Technical Specifications and the Requirements may be modified by BifrostConnect
            from time-to-time, including when changes and updates are made to the applications,
            services, technical solutions, and firmware, cf. clause 3.3. The Technical Specifications
            and the Requirements that at any point in time apply to the BifrostConnect Solution
            can be found on <a target="_blank" href="https://bifrostconnect.com">https://bifrostconnect.com</a>.<br />
            <br />
            3.5. Changes and updates to the firmware of the Hardware Unit must be downloaded by
            the User when notified by BifrostConnect. It can cause harm to the firmware on the
            Hardware Unit if the User does not comply with the Requirements when updating
            firmware, especially if the Hardware Unit is being switched-off during an update. If the
            firmware or Hardware Unit is damaged for this reason, the costs of any repair or
            replacement will be a cost of the User.<br />
            <br />
            3.6. BifrostConnect will make reasonable efforts to notify the Customer of scheduled
            changes, upgrades, or maintenance of the Core Services that may materially affect the
            User's use of the BifrostConnect Solution.<br />
            <br />
            3.7. The Core Services may be temporarily inaccessible due to upgrades or maintenance of
            such services. BifrostConnect shall use reasonable efforts to schedule any such
            upgrades or maintenance outside normal business hours in Denmark.<br />
            <br />
            3.8. BifrostConnect strives to provide a minimum uptime of 99% for the Core Services . This
            uptime is inclusive of scheduled maintenance, but exclusive of downtime due to (i)
            downtime caused by lack of (at least) 4G connectivity or errors to the data SIM card,
            and/or (ii) errors in the User's equipment or internet connection. The User accepts that
            defects, errors, or inappropriate features may occur in the Core Services provided under
            Unit Usage Licenses. Such defects, errors or inappropriate features will be sought to
            be remedied by BifrostConnect in accordance with the General Terms.<br />
            <br />
            3.9. From 9:00 to 16:30 (Central European Time) on ordinary business days in Denmark,
            BifrostConnect provides support to the Customer in relation to the BifrostConnect
            Solution. Unless otherwise agreed between BifrostConnect and the Customer, the
            Customer provides support to the User.<br />
            <br />
            <strong>4. USE OF THE BIFROSTCONNECT SOLUTION</strong><br />
            4.1. Unless otherwise agreed between BifrostConnect and the Customer, the User shall
            not license, rent, sell, lease, transfer, assign, distribute, display, host, outsource,
            disclose, link to, mirror, frame, or otherwise commercially exploit or make available to
            any party (other than User's authorized employees) the BifrostConnect Solution.<br />
            <br />
            4.2. Usernames and passwords shall be used only for the benefit of an User. The User is
            liable to BifrostConnect for any abusive or unauthorized use of its login information or
            of any account use through which the User gets access to Services. The User will
            inform BifrostConnect without delay of any abusive or unauthorized use, or any other
            security failure of which the User is aware. BifrostConnect is entitled to temporarily
            block the User's access to any BifrostConnect application, exclude the Customer from
            using Services and to remove the Customer from any BifrostConnect application, if
            BifrostConnect acting reasonably deems that there are indications of abuse of the login
            information. Access will be restored to the Customer as soon as such suspicion has
            been removed and/or additional security measures respectively (e.g. change of the
            login information) have been taken.<br />
            <br />
            4.3. The User is continuously obliged to provide true, precise, up-to-date and complete
            data to BifrostConnect regarding the User's account with BifrostConnect.<br />
            <br />
            4.4. The User represents that the User will not provide personal data to BifrostConnect
            through the BifrostConnect applications or otherwise. Details on BifrostConnect's processing of personal data are available in BifrostConnect's privacy policy which is
            available on <a target="_blank" href="https://bifrostconnect.com/privacy">https://bifrostconnect.com/privacy</a>.<br />
            <br />
            4.5. The User understands that all communications, transmissions, and submissions to,
            through, and from the BifrostConnect Solution are transmitted through networks,
            equipment and facilities that are not owned or controlled by BifrostConnect.
            BifrostConnect intends to take such measures as it considers reasonably appropriate
            for purposes of maintaining the security of only its own facilities used to provide the
            BifrostConnect Solution. Provided that BifrostConnect takes such measures,
            BifrostConnect shall have no responsibility or liability for any communications,
            transmissions, or submissions which are altered, intercepted, accessed without
            authorization or any other breaches of security. BifrostConnect shall not be required to
            authenticate the identity of any user.<br />
            <br />
            4.6. The BifrostConnect Solution (including the Web Site) may become unavailable and
            inaccessible at any time without notice, both for reasons controlled by BifrostConnect
            or User and for reasons not controlled or determined by BifrostConnect Solution or
            User. In the event of planned unavailability, BifrostConnect will attempt in good faith
            to provide Customer with at least 24 hours prior notice of the unavailability. Where
            relevant, it is the obligation of the Customer (and not BifrostConnect) to inform the
            User thereof. Planned outages may also be announced by email and on the Web Site.
            Subject to the foregoing, BifrostConnect shall have no liability or responsibility as a
            result of any unavailability of, inaccessibility to the BifrostConnect Solution or Web
            Site, whether or not the unavailability, inaccessibility, or inability to use, is within their
            control.<br />
            <br />
            4.7. BifrostConnect shall have no liability or responsibility for any loss of data or
            information by User when using the BifrostConnect Solution.<br />
            <br />
            4.8. The User shall indemnify and hold harmless BifrostConnect and the directors, officers
            and employees of BifrostConnect and the successors and assigns of any of the
            foregoing (the "BifrostConnect Indemnitees"), from and against any and all liabilities,
            damages, settlements, claims, actions, suits, proceedings, penalties, fines, costs and
            expenses (including, without limitation, reasonable attorneys' fees and other expenses
            of litigation) incurred by any BifrostConnect Indemnitee based upon a claim of a thirdparty to the extent resulting from: (a) the negligence, or illegal or willful misconduct,
            of User; or (b) otherwise arising out of User's use of the BifrostConnect Solution.<br />
            <br />
            4.9. BifrostConnect may with immediate effect and without prior notice suspend or
            terminate the User's access to and use of BifrostConnect Solution, (i) if the User in the
            reasonable opinion of BifrostConnect is in material breach of these Use Terms or (with
            respect to the Customer) the General Terms; or (ii) if User in any way causes a third
            party claim against BifrostConnect or causes a justifiable reason for a third-party to
            impose actions upon BifrostConnect that will have an adverse effect on
            BifrostConnect's operations; or (iii) if the Customer fails to pay any BifrostConnect invoice on due date or is behind with other agreed upon payments and such failure is
            not cured within five (5) business days of notice thereof.<br />
            <br />
            4.10. BifrostConnect is not responsible for any functionality or service made available to the
            User by third-party suppliers, including telecom providers.<br />
            <br />
            <strong>5. REPRESENTATIONS AND WARRANTIES</strong><br />
            5.1. The User guarantees that the User (i) will comply with the Requirements and any other
            requirement in these Use Terms, and (ii) will not use the BifrostConnect Solution for
            illegal purposes or in a way that infringes the rights of BifrostConnect under these Use
            Terms.<br />
            <br />
            5.2. The User acknowledges and agrees that it may be required to comply with certain
            third-party service provider policies which may be made available in the
            BifrostConnect applications' user interface and/or presented to the User at the moment
            such third-party services are ordered through the BifrostConnect applications. If the
            User does not agree to such policies, it shall not use such third-party services.<br />
            <br />
            5.3. When using the BifrostConnect Solution, the User shall not (i) use the BifrostConnect
            Solution in breach of applicable law, in particular the Customer shall not transmit any
            content or data that is unlawful or infringes any anti-curruption laws, sanctions laws,
            intellectual property rights or other rights of third-parties; and (ii) interfere or endanger
            the operation or security of the BifrostConnect Solution, Services and any
            BifrostConnect application.<br />
            <br />
            5.4. The User represents that the User will not provide personal data to BifrostConnect
            through the BifrostConnect applications or otherwise. Details on BifrostConnect's
            processing of personal data are available in BifrostConnect's Privacy Policy. The
            Privacy Policy is available on <a target="_blank" href="https://bifrostconnect.com/privacy">https://bifrostconnect.com/privacy</a>.<br />
            <br />
            <strong>6. INTELLECTUAL PROPERTY RIGHTS</strong><br />
            6.1. The User acknowledges that the BifrostConnect Solution is a valuable trade secret of
            BifrostConnect, and all intellectual property rights therein are and shall remain the
            sole property of BifrostConnect as well as any enhancements, improvements,
            corrections, modifications, alterations, and updates thereto.<br />
            <br />
            6.2. The User gains no patent rights, copyrights, business secrets, trademark rights or other
            rights, in the BifrostConnect Solution, except as set out in these Use Terms and the
            Order(s).<br />
            <br />
            6.3. If the User provides BifrostConnect with (whether orally or in writing) any feedback,
            suggestions or the like regarding the BifrostConnect Solution and other (potential)
            products and services of BifrostConnect ("Feedback"), such Feedback shall be deemed the exclusive property of BifrostConnect and Customer hereby irrevocably transfers
            and assigns to BifrostConnect all intellectual property rights to the Feedback and
            waives any and all moral rights or economic rights that Customer may have in respect
            thereto and BifrostConnect may use and exploit such Feedback in any manner as
            BifrostConnect sees fit, without any obligation and/or liability by BifrostConnect,
            including without limitation payment of royalty or any other consideration to Customer
            and/or any third party.<br />
            <br />
            6.4. The User shall not (and shall not allow any third-party to) (i) (except to the limited
            extent applicable statutory law expressly prohibits reverse engineering restrictions),
            copy, modify, reverse engineer, decompile, disassemble revise or enhance the
            hardware, software or other components being or relating to the BifrostConnect
            Solution or attempt to discover or derive any software source code relation to the
            BifrostConnect Solution, or create derivative works based thereon; (ii) develop any
            concept, material or product containing any of the concepts and ideas contained in the
            BifrostConnect Solution; or (iii) remove or modify any proprietary notices, labels or
            marks on Hardware Units.<br />
            <br />
            6.5. The User acknowledges and agrees that BifrostConnect is the exclusive owner of
            BifrostConnect's trademarks and service marks (whether registered or not) ("Marks").
            The User may not contest the Marks or register or attempt to register in any jurisdiction
            any of the Marks or any confusingly similar trademark or trade name. The User agrees
            not to use the Marks with respect to any products or materials not provided by
            BifrostConnect, or that might in any way result in confusion as to BifrostConnect and
            User or any third party being separate and distinct entities. All goodwill associated
            with or arising out User's use of the Marks shall belong solely to BifrostConnect.<br />
            <br />
            6.6. User shall indemnify and hold harmless BifrostConnect from and against any losses,
            damages, liabilities, costs or expenses of any nature whatsoever, including reasonable
            attorney's fees arising out of or associated with User's infringement of
            BifrostConnect's intellectual property rights associated with the BifrostConnect
            Solution or User's breach of its obligations under this Section 6.<br />
            <br />
            <strong>7. CONFIDENTIAL INFORMATION</strong><br />
            7.1. BifrostConnect and the User shall (i) keep confidential the other party's confidential
            information toward third parties (excluding affiliates, agents, directors, employees and
            authorized representatives with a need to know, provided that recipient party binds
            those affiliates, agents, directors, employees and authorized representatives to
            confidentiality obligations at least as restrictive as those stated in this section); (ii) not
            use the other party's Confidential Information for any commercial, industrial or other
            purpose other than the use or provision of the BifrostConnect Solution or Services; or
            (iii) copy, adapt, or otherwise reproduce the other party's Confidential Information save
            as reasonable required. All Confidential Information (including all copies or extracts
            thereof) of the disclosing party shall remain the property of the disclosing party.<br />
            <br />
            7.2. Confidential Information will not include any information that (i) is already known by
            the recipient or its affiliates free of any obligation to keep it confidential; (ii) is or
            becomes publicly known through no wrongful act of the recipient or its affiliates; (iii) is
            received by the recipient from a third-party without any restriction on confidentiality;
            (iv) is disclosed to third-parties by the disclosing party without any obligation of
            confidentiality or because of a valid order, rule, regulation or law.<br />
            <br />
            <strong>8. ASSIGNMENT</strong><br />
            8.1. The User may not assign, sublicense, or transfer any rights or duties under these Use
            Terms, without BifrostConnect's prior written consent.<br />
            <br />
            8.2. BifrostConnect and its subsequent assignees may assign, delegate, sublicense, or
            otherwise transfer its rights or obligations under these Use Terms, in whole or in part,
            to any person or entity. BifrostConnect shall provide written notice to the User of any
            such assignment or transfer.<br />
            <br />
            <strong>9. MODIFICATIONS</strong><br />
            9.1. Modifications to these Use Terms, the Technical Specifications, and the Requirements
            which are made by BifrostConnect to reflect technical advances, to allow the Services'
            continuing compliance with applicable mandatory laws or cannot be deemed
            detrimental in any manner to the Customer can be adopted by BifrostConnect and
            become binding on the User forthwith upon noticing User by posting the amended Use
            Terms on BifrostConnect's website. It is the User's responsibility to check
            BifrostConnect's website regularly for any such modifications.<br />
            <br />
            <strong>10. MARKETING RIGHTS</strong><br />
            10.1. The User grants to BifrostConnect permission to use the User's name and logo as part
            of BifrostConnect's sales and marketing activities and related reference materials,
            including, but not limited to, on BifrostConnect's websites (including
            www.bifrostconnect.com). The User may revoke this permission at any time by written
            notification to BifrostConnect.<br />
            <br />
            <strong>11. FORCE MAJEURE</strong><br />
            11.1. The User and BifrostConnect not will be responsible for delay or failure in performing
            obligations under these Use Terms if the delay or failure in performance is prevented
            by or becomes unreasonably onerous due to the occurrence of an event beyond the
            control of such party ("Force Majeure").<br />
            <br />
            <strong>12. OTHER TERMS</strong><br />
            12.1. No failure or delay by BifrostConnect or the Customer to exercise any right or remedy
            provided under these Use Terms or by law shall constitute a waiver of that or any other
            right or remedy, nor shall it preclude or restrict the further exercise of that or any other
            right or remedy. No single or partial exercise of such right or remedy shall preclude or
            restrict the further exercise of that or any other right or remedy.<br />
            <br />
            12.2. The headings used in these Use Terms are for convenience only and will not affect in
            any way the meaning or interpretation of the Use Terms. In the event of any claimed
            conflict, omission or ambiguity in these Use Terms, no presumption or burden of proof
            or persuasion will be implied by virtue of the fact that the Use Terms was prepared by
            or at the request of a particular party. These Use Terms will be interpreted equally as
            to the parties and not against the party that drafted them. Whenever the context
            requires, the gender of all words will include the masculine, feminine and neuter, and
            the number of all words will include the singular and plural.<br />
            <br />
            12.3. In the event that any provision of these Use Terms is found to be to be unenforceable
            and/or contrary to law, then such provision shall be construed as nearly as possible to
            reflect the intention of the parties, with the other provisions remaining in full force and
            effect.<br />
            <br />
            <strong>13. GOVERNING LAW AND DISPUTE RESOLUTION</strong><br />
            13.1. These Use Terms shall be governed by, and construed in accordance with, the laws of
            Denmark without regard to any conflicts of laws rules or principles.<br />
            <br />
            13.2. Any claim, dispute or controversy between BifrostConnect and the User will be subject
            to the exclusive jurisdiction and venue of the Copenhagen City Court and each party
            hereby irrevocably submits to the personal jurisdiction of such court and waives any
            jurisdictional, venue, or inconvenient forum objections to such court. Notwithstanding
            the foregoing, each party may seek equitable relief in any court of competent
            jurisdiction. The United Nations Convention on Contracts for the International Sale of
            Goods shall not apply to these Use Terms and is hereby disclaimed.



          
        </Modal.Content>
        <Modal.Actions>
           <BifrostButton disabled={loading} style={{ display: "inline-block" }} color='gray' onClick={(event) => {
                    event.preventDefault();
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin,
                        }
                    })
                }}>
              Decline
            </BifrostButton>
            <BifrostButton loading={loading} style={{ display: "inline-block" }} color='blue' onClick={() => postAcceptTerms()}>
              Accept
            </BifrostButton>
        </Modal.Actions>
      </Modal >
    </div >
  )
}
export default withAuthenticationRequired(AcceptUserTerms, {
  onRedirecting: () => <Loader />,
});