import React from 'react';
import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";

const RenameDeviceModalActions = ({isLoading, nameLengthOK, nameIsValid, showConfirmMessage, renameHandler, confirmHandler, cancelRenameHandler, cancelConfirmHandler}) => {

    return (
        <>
            {showConfirmMessage
                ? <>
                    <BifrostButton disabled={isLoading} style={{display: "inline-block"}} onClick={() => confirmHandler()}>
                        Confirm
                    </BifrostButton>
                    <BifrostButton disabled={isLoading} style={{display: "inline-block"}} color="gray" onClick={() => cancelConfirmHandler()}>
                        Cancel
                    </BifrostButton>
                </>
                :
                <>
                    <BifrostButton disabled={!nameLengthOK || !nameIsValid} style={{display: "inline-block"}} onClick={() => renameHandler()}>
                        Rename
                    </BifrostButton>
                    <BifrostButton disabled={isLoading} style={{display: "inline-block"}} color="gray" onClick={() => cancelRenameHandler()} >
                        Cancel
                    </BifrostButton>

                </>
            }
        </>
    );
};

export default RenameDeviceModalActions;