/**
 * Renders the Bifrost Detail Component.
 * 
 * @param {Object} chosenOrg - The chosen organization object.
 * @returns {JSX.Element} The rendered Bifrost Detail Component.
 */
import React, { useState, useContext } from 'react';
import './DeviceDetailsComponent.scss'
import Loader from "../../Loader/Loader";
import { Icon, Menu, MenuMenu, MenuItem, Dropdown, DropdownMenu } from "semantic-ui-react";
import DeviceDetailsSegment from "./DeviceDetailsSegment";
import DeviceSettingsSegment from "./DeviceSettingsSegment";
import { isVersionHigherOrEqual } from "../../../../Utils/util";
// import RenameDeviceModal from "../../../../Pages/MainPanel/MainPanelBody/DevicesPage/DeviceDetailsPage/RenameDeviceModal/RenameDeviceModal";
// import UpgradeFirmwareModal from "../../../../Pages/MainPanel/MainPanelBody/DevicesPage/DeviceDetailsPage/UpgradeFirmwareModal/UpgradeFirmwareModal";
import { RebootDeviceModal } from '../../../../Pages/MainPanel/MainPanelBody/DevicesPage/DeviceDetailsPage/RebootDeviceModal/RebootDeviceModal';
import { ReactComponent as OptionsIcon } from '../../../../Icons/options.svg'
import { hasFeatureInPackage } from "../../../../Utils/util";
import userInfoContext from '../../../../store/userInfoContext'
import UpgradePackageModal
    from "../../../../Pages/Sidebar/SidebarNavPanel/SidebarNavLink/UpgradePackageModal/UpgradePackageModal";

const DeviceDetailsComponent = ({ data, deviceIsLoading, getDeviceById, latestFirmwareVersion, firmwareUpToDate }) => {
    const [activeItem, setActiveItem] = useState("details")
    const [feature, setFeature] = useState(null)
    const userInfo = useContext(userInfoContext);
    const [openUpgradePackageModal, setOpenUpgradePackageModal] = useState(false)

    const handleItemClick = (e, name) => {
        switch (name) {
            case "details":
                setActiveItem(name);
                break;
            case "settings":
                if (hasFeatureInPackage(userInfo, "device_config")) {
                    setActiveItem(name);
                } else {
                    setFeature("device_config")
                    setOpenUpgradePackageModal(true);
                }
                break;
            default:
                setOpenUpgradePackageModal(true);
        }
    }

    return (
        <div className={"device-detail-container"}>

            {deviceIsLoading
                ? <Loader backgroundColor="white" inverted height="1px" minHeight="200px" />
                :
                <div className={"device-detail-segment"}>
                    <UpgradePackageModal openModal={openUpgradePackageModal}
                        setOpenModal={setOpenUpgradePackageModal}
                        feature={feature} />

                    <Menu style={{ marginBottom: "0px" }}>

                        <Menu.Item name='details' active={activeItem === 'details'}
                            onClick={(e, { name }) => handleItemClick(e, name)}
                        >
                            <Icon name="list" />
                            Details
                        </Menu.Item>
                        <Menu.Item name='settings'
                            active={activeItem === 'settings'}
                            onClick={(e, { name }) => handleItemClick(e, name)}
                        >
                            {
                                !hasFeatureInPackage(userInfo, "device_config")
                                    ?
                                    <Icon color="grey" name="lock" />

                                    :
                                    <Icon name="setting" />

                            }
                            Settings
                        </Menu.Item>
                        <MenuMenu position='right'>
                            <MenuItem className={"device-detail-options"} style={{ padding: "0px" }}>
                                <Dropdown style={{ padding: "0px 8px" }} icon={<OptionsIcon style={{ height: "32px", width: "32px" }} />}>
                                    <DropdownMenu>
                                            <RebootDeviceModal
                                                chosenItem={data}
                                                disabled={data.state !== "idle" || !isVersionHigherOrEqual(data.firmware, "v4.9.0")}
                                            />             
                                    </DropdownMenu>
                                </Dropdown>
                            </MenuItem>
                        </MenuMenu>
                    </Menu>


                    {activeItem === 'details' && <DeviceDetailsSegment data={data} getDeviceById={getDeviceById} latestFirmwareVersion={latestFirmwareVersion} firmwareUpToDate={firmwareUpToDate} />}
                    {activeItem === 'settings' && <DeviceSettingsSegment data={data} latestFirmwareVersion={latestFirmwareVersion} firmwareUpToDate={firmwareUpToDate} />}

                </div>
            }
        </div>
    );
};

export default DeviceDetailsComponent;