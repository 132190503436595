import React from 'react'

import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import './Loader.scss';


const Spinner = (props) => (
    <div style={{minHeight: props.minHeight, height: props.height}}>
        <Segment style={{boxShadow: "none", border: props.border, height: props.minHeight ? props.minHeight : props.height}}>
            <Dimmer className={"bg-"+props.backgroundColor} inverted={!!props.inverted} active>
                <Loader size={props.size && props.size} inverted>{props.text}</Loader>
            </Dimmer>
        </Segment>
    </div>
)

export default Spinner;

Spinner.defaultProps = {
    backgroundColor: "default",
    inverted: false,
    height: "100%",
    minHeight: false,
    border: "0px"
}