import React, { useState } from 'react';
import BifrostModal
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import EditGroupsPage from "./EditGroupsPage";
import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";

const EditGroupModal = ({ groupId, groupName, refreshGroups }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("Edit group");

    return (
        <BifrostModal title={title}
            open={open}
            setOpen={setOpen}
            trigger={
                <BifrostButton>
                    Edit
                </BifrostButton>}
            modalPage={
                <EditGroupsPage setTitle={setTitle}
                    groupID={groupId}
                    groupName={groupName}
                />
            }
            modalActions={
                <BifrostButton style={{ display: "inline-block" }}
                    color="gray"
                    onClick={() => {
                        setOpen(false);
                        refreshGroups("/api/v2/group")
                    }}
                >
                    Close
                </BifrostButton>}
        />


    );
};

export default EditGroupModal;