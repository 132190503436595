import { useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useDeleteData = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const deleteItem = useCallback(async (url, id, refreshData) => {
        setIsLoading(true);
        return fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + url + id,
                {
                    method: "DELETE",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        'Content-Type': 'application/json'
                    }),
                }
            )
        ).then(response => {
            if (response.ok) {
                console.log("Delete Item successfully: " + id);
                return response;
            } else if (response.statusText === "Unauthorized") {
                throw new Error("Unauthorized");
            } else {
                console.log(response.statusText);
                throw new Error("Something went wrong");
            }
        }).then(() => {
            if (refreshData) {
                refreshData();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [getAccessTokenSilently])

    return { isLoading, deleteItem };
}