import React, { useContext } from 'react'
import {useEffect} from 'react'
import {useAuth0} from "@auth0/auth0-react"
import {Table} from 'semantic-ui-react'
import BifrostButton from '../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton'
import BifrostTable from "../../../../Components/BifrostElementComponent/BifrostTable/BifrostTable"
import {isSuperAdmin} from '../../../../Utils/util'
import Error404 from '../../../OtherPages/ErrorPage/Error404'
import MainPanelHeader from "../../MainPanelHeader/MainPanelHeader";
import MainPanelFooter from "../../MainPanelFooter/MainPanelFooter";
import CreateOrganizationModal from "./OrganizationsModal/CreateOrganizationModal/CreateOrganizationModal";
import {useGetOrganizations} from "../../../../Hooks/FetchHooks/GetDataService/useGetOrganizations";
import {useNavigate} from "react-router-dom";
import userInfoContext from "../../../../store/userInfoContext";
import './OrganizationsPage.scss'
import { isSuperAdminOrAdmin } from '../../../../Utils/util'


const OrganizationsPage = () => {
    const nav = useNavigate();
    const {user} = useAuth0()
    const userInfo = useContext(userInfoContext);
    const {data: organizations, setData: setOrganizations, isLoading, getOrganizations} = useGetOrganizations();

    const columns = React.useMemo(
        () => {
            const superadminColumns = isSuperAdmin(user) ? [{
                name: "Name",
                key: "name",
                type: "string"
            },
                {
                    name: "Package",
                    key: "package_name",
                    type: "string"
                },
                {
                    name: "Users",
                    key: "no_users",
                    type: "number"
                },
                {
                    name: "Devices",
                    key: "no_devices",
                    type: "number"
                },
                {
                    name: "Last Session (UTC)",
                    key: "last_session",
                    type: "number"
                }] : []

            return superadminColumns
        }, [user]);


    const OrganizationRow = ({item}) => (
    <Table.Row key={item.id}
        className={isSuperAdminOrAdmin(user) && "orgs-list-row"}
        onClick={() => nav(`/orgs/${item.id}`, {state: {name: item.name}})}
    >
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
            {item.package_key ?
                <span className={`package-col-value ${item.package_key.toLowerCase()}`}>
          {item.package_name.toUpperCase()}
        </span>
                : "None"
            }
        </Table.Cell>
        <Table.Cell>{item.no_users}</Table.Cell>
        <Table.Cell>{item.no_devices}</Table.Cell>
        <Table.Cell>{item.last_session}</Table.Cell>
    </Table.Row>)

    const filterOrganization = (org, filter) => {
        if (!filter || filter === "") return true
        else
            return org.name.toLowerCase().includes(filter.toLowerCase())
    }


    useEffect(() => {
        (userInfo && userInfo.terms_accepted ) && getOrganizations();
        return () => setOrganizations([]);
    }, [])

    return (
        !isSuperAdmin(user)
            ? <Error404/>
            : <section className={`bifrost-table-panel users-table`}>
                <MainPanelHeader title={"Organizations"}
                                 fetchData={getOrganizations}
                >
                    <div className="create-org-modal">
                        <CreateOrganizationModal refreshOrgs={getOrganizations}/>
                    </div>
                </MainPanelHeader>
                <BifrostTable
                    loading={isLoading}
                    pageName='Organizations'
                    columns={columns}
                    data={organizations}
                    Row={OrganizationRow}
                    filterFunc={filterOrganization}
                    defaultSort={{order: 'ascending', column: 'name'}}
                    isSelectable={!!(isSuperAdminOrAdmin(user) && !isLoading)}
                    fetchData={getOrganizations}
                />
                <MainPanelFooter/>
            </section>

    )
}

export default OrganizationsPage