import React from 'react';
import BifrostModalConfirmItem
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalPage/BifrostModalConfirmItem";

const DeleteGroupPage = ({name, showDeleteErrorMessage}) => {
    return (
        <BifrostModalConfirmItem showErrorMessage={showDeleteErrorMessage} action="delete" name={name}/>
    );
};

export default DeleteGroupPage;