import React, { useEffect, useContext, useCallback, useState } from "react";
import { BifrostContext } from "../../../../BifrostContext";
import BifrostTable from "../../../../Components/BifrostElementComponent/BifrostTable/BifrostTable";
import "../../../Pages.scss";
import BifrostButton from "../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import { useAuth0 } from "@auth0/auth0-react";

import { Table } from "semantic-ui-react";

import { isSuperAdmin, isSuperAdminOrAdmin } from "../../../../Utils/util";
import MainPanelHeader from "../../MainPanelHeader/MainPanelHeader";
import MainPanelFooter from "../../MainPanelFooter/MainPanelFooter";
import { CreateDeviceModal } from "./DeviceModal/CreateDeviceModal";
import { useGetDevices } from "../../../../Hooks/FetchHooks/GetDataService/useGetDevices";
import { useNavigate } from "react-router-dom";
import "./DevicesPage.scss";
import userInfoContext from "../../../../store/userInfoContext";
import { isVersionHigherOrEqual } from "../../../../Utils/util";

const DevicesPage = () => {
	const userInfo = useContext(userInfoContext);
	const { data, setData: setDevices, isLoading, getDevices } = useGetDevices();
	const { user } = useAuth0();
	const nav = useNavigate();

	const filterDevice = (device, filter) => {
		if (!filter || filter === "") return true;
		else
			return (
				device.name.toLowerCase().includes(filter.toLowerCase()) ||
				device.org_name.toLowerCase().includes(filter.toLowerCase()) ||
				device.timestamp.toLowerCase().includes(filter.toLowerCase()) ||
				device.firmware.toLowerCase().includes(filter.toLowerCase()) ||
				device.serialnr.toLowerCase().includes(filter.toLowerCase())
			);
	};

	const DeviceRow = ({ item }) => {
		const { frontendAddress } = useContext(BifrostContext);

		const getConnectionLink = useCallback(
			(serial, type) => {
				console.log("redirecting with device: " + serial);

				if (type === "AA") {
					// window.open(frontendAddress + "?serial=" + serial + "&auth=true", "_blank");
					window.location.href =
						frontendAddress + "?serial=" + serial + "&auth=true";
				} else {
					// window.open(frontendAddress + "?serial=" + serial, "_blank");
					window.location.href = frontendAddress + "?serial=" + serial;
				}
			},
			[frontendAddress]
		);

		const getTunnelConnectionLink = useCallback(
			(serial, type) => {
				console.log("redirecting with tunnel device: " + serial);
				if (type === "AA") {
					// window.open(frontendAddress + "?serial=" + serial + "&tunnel=true&auth=true", "_blank");
					window.location.href =
						frontendAddress + "?serial=" + serial + "&tunnel=true&auth=true";
				} else {
					// window.open(frontendAddress + "?serial=" + serial + "&tunnel=true", "_blank");
					window.location.href =
						frontendAddress + "?serial=" + serial + "&tunnel=true";
				}
			},
			[frontendAddress]
		);

		return (
			<Table.Row
				key={item.id}
				className={isSuperAdminOrAdmin(user) && "devices-list-row"}
				onClick={() =>
					isSuperAdminOrAdmin(user) &&
					nav(`/devices/${item.id}`, { state: { name: item.name } })
				}
			>
				<Table.Cell textAlign="center">
					<span className={`status-col-value ${item.state.toLowerCase()}`}>
						{item.state}
					</span>
				</Table.Cell>
				<Table.Cell>{item.name}</Table.Cell>
				{isSuperAdmin(user) &&
					process.env.REACT_APP_SAML_ENABLED !== "true" && (
						<Table.Cell>{item.org_name}</Table.Cell>
					)}
				<Table.Cell>{item.serialnr}</Table.Cell>
				<Table.Cell>
					{item.state.toLowerCase() === "offline" ? item.timestamp : "Now"}
				</Table.Cell>
				<Table.Cell>{item.firmware}</Table.Cell>
				<Table.Cell>{item.type}</Table.Cell>
				<Table.Cell>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<BifrostButton
							disabled={
								!(
									isVersionHigherOrEqual(
										item.firmware.substr(1, item.firmware.length - 1),
										"4.4"
									) &&
									item.state === "idle" &&
									item.org_id === userInfo.org.id
								)
							}
							style={{ marginRight: "10px" }}
							color="blue"
							icon={true}
							className={""}
							onClick={(e) => {
								e.stopPropagation();
								getTunnelConnectionLink(item.serialnr, item.type);
							}}
						>
							Tunnel
						</BifrostButton>

						<BifrostButton
							color="green"
							icon={true}
							className={""}
							disabled={
								!(item.state === "idle" && item.org_id === userInfo.org.id)
							}
							onClick={(e) => {
								e.stopPropagation();
								getConnectionLink(item.serialnr, item.type);
							}}
						>
							Connect
						</BifrostButton>
					</div>
				</Table.Cell>
			</Table.Row>
		);
	};

	const columns = React.useMemo(() => {
		const columns1 = [
			{
				name: "Status",
				key: "state",
				textAlign: "center",
				type: "string",
				compareFunction: (a, b) => {
					let difference;
					if (a.state === "idle") difference = -1;
					else if (b.state === "idle") difference = 1;
					else if (a.state === "session") difference = -1;
					else if (b.state === "session") difference = 1;
					else if (a.state === "offline") difference = -1;
					else if (b.state === "offline") difference = 1;
					else difference = 0;

					return difference;
				},
			},
			{
				name: "Name",
				key: "name",
				type: "string",
			},
		];

		const columns2 = isSuperAdmin(user)
			? [
					{
						name: "Organization",
						key: "org_name",
						type: "string",
					},
			  ]
			: [];

		const columns3 = [
			{
				name: "Serial No",
				key: "serialnr",
				type: "string",
			},
			{
				name: "Last Online (UTC)",
				key: "timestamp",
				type: "number",
			},
			{
				name: "Firmware",
				key: "firmware",
				type: "string",
			},
			{
				name: "Type",
				key: "type",
				type: "string",
			},
			{
				name: "Connect",
				textAlign: "center",
				key: "connect",
				isUnSortable: true,
			},
		];

		return columns1.concat(columns2).concat(columns3);
	}, [user]);

	useEffect(() => {
		if (userInfo && userInfo.terms_accepted) {
			getDevices();
		}
		return () => setDevices([]);
	}, []);

	return (
		<section className={`bifrost-table-panel devices-table`}>
			<MainPanelHeader title="Devices" fetchData={getDevices}>
				{isSuperAdmin(user) && (
					<CreateDeviceModal refreshDevices={getDevices} />
				)}
			</MainPanelHeader>
			<BifrostTable
				loading={isLoading}
				pageName="Devices"
				columns={columns}
				data={data}
				Row={DeviceRow}
				filterFunc={filterDevice}
				defaultSort={{ order: "ascending", column: "state" }}
				isSelectable={!!(isSuperAdminOrAdmin(user) && !isLoading)}
				fetchData={getDevices}
			/>
			<MainPanelFooter />
		</section>
	);
};

export default DevicesPage;
