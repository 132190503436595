import { useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useGetOrgDetails = (chosenOrg) => {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false)

    const getOrgDetails = useCallback(async () => {
        setIsLoading(true)

        const request = new Request(process.env.REACT_APP_SESSION_ADDR + "/api/v2/org/" + chosenOrg.id, {
            method: 'GET',
            headers: new Headers({
                'authorization': "Bearer " + await getAccessTokenSilently(),
                'Content-Type': 'application/json'
            }),
        });

        fetch(request)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(data => {
                setData(data ? data : [])
            })
            .catch(error => {
                console.error(error);
            }).finally(() => {
                setIsLoading(false)
            });
    }, [getAccessTokenSilently])

    return { data, isLoading, getOrgDetails }
}