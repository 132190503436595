import React, { useEffect } from 'react';
import BifrostTable from "../../BifrostElementComponent/BifrostTable/BifrostTable";
import { useGetPaginatedAuditHistory } from "../../../Hooks/FetchHooks/GetDataService/getOrganizationData/useGetPaginatedAuditHistory";
import { Table } from "semantic-ui-react";

const BifrostAuditComponent = ({ chosenItem, chosenItemType }) => {
    const { data, isLoading, pagination, setPagination, getAuditHistory } = useGetPaginatedAuditHistory(chosenItem, chosenItemType)

    const auditHeaderColumns = React.useMemo(() => [
        { name: "Timestamp (UTC)", key: "timestamp", type: "date" },
        { name: "Action", key: "type", type: "string" },
        { name: "User", key: "username", type: "string" },
        { name: "Details", key: "details", type: "string" }
    ], []);

    const AuditRow = ({ item }) =>
        <Table.Row key={item.timestamp}>
            <Table.Cell>{item.timestamp}</Table.Cell>
            <Table.Cell>
                <span className={`action-col-value ${item.type.toLowerCase()}`}>
                    {item.type.toUpperCase()}
                </span>
            </Table.Cell>
            <Table.Cell>{item.email}</Table.Cell>
            <Table.Cell>{item.details}</Table.Cell>
        </Table.Row>

    const filterAudit = (audit, filter) =>
        (!filter || filter === "") ?
            true :
            (audit.type.toLowerCase().includes(filter.toLowerCase())
                || (audit.username && audit.username.toLowerCase().includes(filter.toLowerCase()))
                || audit.timestamp.toLowerCase().includes(filter.toLowerCase())
                || audit.details && audit.details.toLowerCase().includes(filter.toLowerCase()))

    useEffect(() => {
        if (!data || !data.length) {
            getAuditHistory(pagination)
        }
        // return () => setData([])
    }, [])

    return (
        <BifrostTable
            pageName='Audit'
            loading={isLoading}
            data={data}
            columns={auditHeaderColumns}
            Row={AuditRow}
            pagination={pagination}
            setPagination={setPagination}
            filterFunc={filterAudit}
            defaultSort={{ order: 'descending', column: 'timestamp' }}
            fetchData={getAuditHistory}
        />
    );
};

export default BifrostAuditComponent;