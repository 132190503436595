/**
 * Renders the Bifrost Detail Component.
 * 
 * @param {Object} chosenOrg - The chosen organization object.
 * @returns {JSX.Element} The rendered Bifrost Detail Component.
 */
import React, { useState } from 'react';
import UpgradeFirmwareModal from "../../../../Pages/MainPanel/MainPanelBody/DevicesPage/DeviceDetailsPage/UpgradeFirmwareModal/UpgradeFirmwareModal";
import { Feed, FeedEvent, Form, Label, Popup, Icon, FeedExtra, Message, Checkbox, FeedContent, FeedSummary, SegmentGroup, Segment } from "semantic-ui-react";
import { usePostData } from '../../../../Hooks/FetchHooks/PostDataService/usePostData';
import Loader from "../../Loader/Loader";
import { isVersionHigherOrEqual } from "../../../../Utils/util";
import BifrostButton from "../../../BifrostElementComponent/BifrostButton/BifrostButton";

const initialState = {
    enableDebug: false,
    otp: false
}

const optionsOtp = [
    { key: "60", value: 60, text: "1 min" },
    { key: "300", value: 300, text: "5 min" },
    { key: "600", value: 600, text: "10 min" },
    { key: "1800", value: 1800, text: "30 min" },
    { key: "3600", value: 3600, text: "1 hour" },
];

const initialSettingsState = {
    enableDebug: false,
    otp: optionsOtp[0].value
}

const settingEndpoints = {
    enableDebug: "debugMode",
    otp: "otp"
}

const DeviceSettingsSegment = ({ data: deviceData, latestFirmwareVersion, firmwareUpToDate }) => {
    const [settings, setSettings] = useState(initialSettingsState)
    const [values, setValues] = useState(initialSettingsState)
    const [postLoading, setPostLoading] = useState(initialState)
    const [postSuccess, setPostSuccess] = useState(initialState);
    const [postError, setPostError] = useState(initialState);
    const [showEditIcon, setShowEditIcon] = useState(initialState);
    const [changeEnabled, setChangeEnabled] = useState(initialState)

    const { postItem } = usePostData();

    const enableChange = (field, version) => {
        setPostError({ ...postError, [field]: false })
        if (isVersionHigherOrEqual(deviceData.firmware, version) && deviceData.state === "idle") {
            setChangeEnabled({ ...changeEnabled, [field]: true });
        } else {
            setChangeEnabled({ ...changeEnabled, [field]: false });
        }
    }

    const updateField = (e, name, value) => {
        if (!name) {
            name = e.target.name
            value = e.target.value
        }
        setValues({
            ...values,
            [name]: value
        });

    };

    const submitHandler = (field, value) => {
        if (value === undefined) {
            value = values[field]
        }
        resetFieldState(field)

        setPostLoading({ ...postLoading, [field]: true })
        let postObject = {}
        postObject[field] = value
        postObject.serialnumber = deviceData.serialnr

        console.log("postObject: ", postObject)

        postItem("/api/v2/device/configure/" + settingEndpoints[field], postObject, null).then(() => {
            setSettings({ ...settings, [field]: value })
            setPostSuccess({ ...postSuccess, [field]: true });
        }).catch(() => {
            setPostError({ ...postError, [field]: true });
        }).finally(() => {
            setPostLoading({ ...postLoading, [field]: false });
        })
    };

    const resetFieldState = (field) => {
        setPostLoading({ ...postLoading, [field]: false })
        setPostSuccess({ ...postSuccess, [field]: false })
        setPostError({ ...postError, [field]: false })
        setShowEditIcon({ ...showEditIcon, [field]: false })
        setChangeEnabled({ ...changeEnabled, [field]: false });
    }

    const cancelHandler = (field) => {
        setValues({ ...values, [field]: settings[field] })
        resetFieldState(field)
    }

    return (
        <SegmentGroup horizontal style={{ marginTop: "0px" }}>
            <Segment style={{ width: "50%" }}>
                <Feed>

                    <FeedEvent className='device-event-margin'>
                        <FeedContent>
                            <FeedSummary content={"Debug Mode"} />
                            <FeedExtra text className={"device-change-disabled"} style={{ justifyContent: "space-between" }} >
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Popup disabled={isVersionHigherOrEqual(deviceData.firmware, "v4.9.0")}
                                            content='Firmware v4.9.0 or higher required'
                                            wide='very'
                                            on='click'
                                            trigger={<Checkbox disabled={postLoading.enableDebug || deviceData.state !== "idle" || !isVersionHigherOrEqual(deviceData.firmware, "v4.9.0")} checked={settings.enableDebug} toggle onChange={(e, data) => submitHandler("enableDebug", data.checked)} />}
                                        />
                                        <div style={{ marginRight: "10px" }}>{settings["enableDebug"] ? "On" : "Off"}</div>
                                        {postLoading.enableDebug && <Loader backgroundColor="white" inverted size="small" />}
                                        {postSuccess.enableDebug && <Icon name="check circle outline" size="large" style={{ color: "#39C4AA" }} />}
                                        {postError.enableDebug && <Icon name="close" size="large" style={{ color: "darkred" }} />}
                                    </div>
                                    {postError.enableDebug && <Message negative size='mini' className="small-message">Couldn't toggle debug mode</Message>}
                                </>

                            </FeedExtra>

                        </FeedContent>

                    </FeedEvent>
                    { deviceData.type === "AA" &&
                        <FeedEvent className='device-event-margin'>
                            <FeedContent>
                                <FeedSummary content={"OTP Duration"} />
                                {postLoading.otp ? <Loader backgroundColor="white" inverted height="1px" minHeight="55px" /> :
                                    <>
                                        {postError.otp && <Message error content={"OTP change failed, please try again"} />}

                                        {!changeEnabled.otp ?
                                            <Popup
                                                disabled={isVersionHigherOrEqual(deviceData.firmware, "v4.9.0") && deviceData.state === "idle"}
                                                content={!isVersionHigherOrEqual(deviceData.firmware, "v4.9.0") ? "Firmware v4.9.0 or higher required" : "Device must be idle"}
                                                wide='very'
                                                on='click'
                                                trigger={
                                                    <FeedExtra
                                                        text
                                                        onClick={() => enableChange("otp", "v4.9.0")}
                                                        className={"user-change-hover"}
                                                        onMouseLeave={() => setShowEditIcon({ ...showEditIcon, "otp": false })}
                                                        onMouseEnter={() => setShowEditIcon({ ...showEditIcon, "otp": true })}>
                                                        {
                                                            // Find the selected option and display the text
                                                            optionsOtp.find(option => option.value === settings.otp).text
                                                        }
                                                        {(postSuccess.otp && !showEditIcon.otp) && <Icon name="check circle outline" size="large" style={{ float: "right", color: "#39C4AA" }} />}
                                                        {showEditIcon.otp && <Icon style={{ float: "right" }} name="edit" />}
                                                    </FeedExtra>
                                                }
                                            /> :
                                            <FeedExtra className="user-dropdown" style={{ marginTop: "5px" }} text>
                                                <Form.Dropdown
                                                    style={{ marginBottom: "14px" }}
                                                    fluid
                                                    search
                                                    selection
                                                    defaultValue={settings.otp}
                                                    onChange={(e, { name, value }) => updateField(e, name, value)}
                                                    name="otp"
                                                    placeholder='Select OTP Duration'
                                                    options={optionsOtp}
                                                />
                                                <div style={{ display: "flex", justifyContent: "right" }}>
                                                    <BifrostButton onClick={() => submitHandler("otp")} color='blue' style={{ width: "90px" }}>
                                                        Save
                                                    </BifrostButton>
                                                    <BifrostButton color="gray" onClick={() => { cancelHandler("otp") }} basic style={{ width: "90px" }}>
                                                        Cancel
                                                    </BifrostButton>
                                                </div>
                                            </FeedExtra>
                                        }
                                    </>
                                }

                            </FeedContent>

                        </FeedEvent>
                    }
                </Feed>
            </Segment>
            <Segment style={{ width: "50%" }}>
                <Feed>
                    <FeedEvent className='device-event-margin'>
                        {/* <FeedLabel image={image} /> */}
                        <FeedContent>
                            {/* <FeedDate content={date} /> */}
                            <FeedSummary content={"Firmware Version"} />
                            <FeedExtra text content={deviceData.firmware ?
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="device-detail-cell">
                                        {deviceData.firmware.toString()}
                                        {(latestFirmwareVersion && firmwareUpToDate) &&
                                            <Label style={{ marginLeft: "10px" }} size="small">
                                                LATEST
                                            </Label>}
                                    </div>
                                    {latestFirmwareVersion &&
                                        <div style={{ display: "flex", position: "relative" }}>
                                            <UpgradeFirmwareModal
                                                disabled={deviceData.state !== "idle"}
                                                device={deviceData}
                                                latestFirmwareVersion={latestFirmwareVersion}
                                                firmwareUpToDate={firmwareUpToDate}
                                            />
                                        </div>
                                    }
                                </div>
                                : "Unknown"} />
                        </FeedContent>
                    </FeedEvent>
                </Feed>

            </Segment>
        </SegmentGroup>
    );
};

export default DeviceSettingsSegment;