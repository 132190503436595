import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useState} from "react";

export const usePostUpgradeFirmware = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [firmwareUpgradeSuccess, setFirmwareUpgradeSuccess] = useState(false);

    const {getAccessTokenSilently} = useAuth0();

    const postUpgradeFirmware = useCallback(async (id, version) => {
        setIsLoading(true);
        console.log("Pushing firmware with id: " + id + " and version: " + version)

        return fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + "/api/v2/device/configure/firmwareUpgrade",
                {
                    method: "POST",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        "Content-Type": "application/json"
                    }),
                    body: JSON.stringify({id: "" + id, fw_version: "" + version})
                }
            )
        ).then( response => {
            if (response.status === 202) {
                console.log("Firmware upgrade succeeded")
                return response;
            } else {
                console.log("Firmware upgrade failed")
                throw new Error(response.statusText);
            }
        }).then( () => {
            setFirmwareUpgradeSuccess(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [getAccessTokenSilently])

    return {postUpgradeFirmware, isLoading, firmwareUpgradeSuccess};

}