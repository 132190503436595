import {useGetAllData} from "./useGetAllData/useGetAllData";
import React, {useCallback} from "react";
import EditGroupModal from "../../../Pages/MainPanel/MainPanelBody/Groups/GroupModal/EditGroupModal/EditGroupModal";
import DeleteGroupModal
    from "../../../Pages/MainPanel/MainPanelBody/Groups/GroupModal/DeleteGroupModal/DeleteGroupModal";

export const useGetGroups = () => {
    const {data, setData, isLoading, getAllData} = useGetAllData();

    const getGroups = useCallback(() => {
        getAllData("/api/v2/group", result => {
            return result.map((col, idx) => {
                col.org_name = col.org.name
                col.edit = (
                    <EditGroupModal groupName={col.name}
                                    groupId={col.id}
                                    refreshGroups={getGroups}
                    />
                )
                col.delete = (
                    <DeleteGroupModal idx={idx}
                                      name={col.name}
                                      id={col.id}
                                      refreshGroups={getGroups}
                    />
                )
                return col
            })
        })
    }, [getAllData]);

    return {data, setData, isLoading, getGroups};
}