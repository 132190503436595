import React from 'react'
import { Button } from 'semantic-ui-react'

import "./BifrostButton.scss"

const BifrostButton = (props) => {
    return (
        <Button
            loading={props.loading}
            style={{...props.style}}
            className={`bifrost-button button-icon button ${props.invert ? "invert" : ""} ${props.color} ${props.size} ${props.className} ${props.link ? "link" : "nolink"}`}
            type={props.type}
            id={props.id}
            onClick={props.onClick}
            disabled={props.disabled || props.loading}
            value={props.value}
        >
            {props.children}
        </Button>
    )
}

export default BifrostButton


BifrostButton.defaultProps = {
    loading: false,
    style: {},
    size: "",
    id: "",
    color: "blue",
    onClick: () => { console.log( "clicked!") },
    className: "",
    type: "button",
    value: "",
    link: false
}