import React from "react";
import Loader from "../../Components/BifrostPageComponent/Loader/Loader";
import UserInfoPanel from "./UserInfoPanel/UserInfoPanel";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "./Sidebar.scss";
import Logo from "./Logo/Logo";
import SidebarNavPanel from "./SidebarNavPanel/SidebarNavPanel";

const Sidebar = ({ loading }) => {
	const { logout } = useAuth0();

	return (
		<aside id="sidebar" className={`sidebar expanded`}>
			<Logo />
			<SidebarNavPanel logout={logout} />
			<UserInfoPanel loading={loading} />
		</aside>
	);
};

export default withAuthenticationRequired(Sidebar, {
	onRedirecting: () => <Loader />,
});
