import React, {useEffect, useState} from 'react'
import { Form } from 'semantic-ui-react'
import BifrostModalCreateItem
    from "../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalPage/BifrostModalCreateItem";
import { useGetPackages } from "../../../../../Hooks/FetchHooks/GetDataService/useGetPackages";

const ChangePackagePage = ({ validated, values, setValues, showErrorMessage }) => {
    const {data, isLoading, getPackages} = useGetPackages();
    const [packageOptions, setPackageOptions] = useState([])

    const updateField = (e, name, value) => {
        if (!name) {
            name = e.target.name
            value = e.target.value
        }
        setValues({
            ...values,
            [name]: value
        });
    };

    useEffect(() => {
        if (data.length === 0) {
            getPackages();
        } else {
            setPackageOptions(data.map((pack) =>
                ({key: pack.id, value: Number(pack.id), text: pack.name})
            ))
        }
    }, [data]);

    return (
        <BifrostModalCreateItem showErrorMessage={showErrorMessage} >
            <Form.Dropdown
                fluid
                search
                selection
                // onOpen={getOrganizations}
                loading={isLoading}
                noResultsMessage='Loading..'
                onChange={(e, { name, value }) => updateField(e, name, value)}
                name="package_id"
                placeholder='Select package'
                options={packageOptions}
                error={!validated.package_id}
            />
        </BifrostModalCreateItem>
    );
}

export default ChangePackagePage;
