import { useState, useCallback, React } from 'react'
import "./SidebarNavLink.scss";
import { ReactComponent as DefaultIcon } from "../../../../Icons/settings-icon.svg";
import { NavLink } from "react-router-dom";
import { Icon } from 'semantic-ui-react'
import UpgradePackageModal from './UpgradePackageModal/UpgradePackageModal'


const SidebarNavLink = (props) => {
    const [openModal, setOpenModal] = useState(false)


    const handleClick = useCallback((e) => {
        e.preventDefault()
        setOpenModal(true)
    })

    return (
        <NavLink className="sidebar-nav-link"
            style={props.locked ? { justifyContent: "space-between" } : {}}
            to={props.to}
            onClick={props.locked ? (event) => handleClick(event) : props.onClick}
        >
            <div style={props.locked ? { opacity: "0.7" } : {}}>
                {props.locked ? <Icon name="lock" /> : props.icon}{" "}
                <span className="sidebar-nav-label" style={{ verticalAlign: "middle" }}>
                    <span className="tooltip-arrow" />
                    {props.label}
                </span>
            </div>
            <UpgradePackageModal feature={props.to} openModal={openModal} setOpenModal={setOpenModal} />
        </NavLink>
    );
};

export default SidebarNavLink;

SidebarNavLink.defaultProps = {
    to: "/",
    label: "label",
    icon: <DefaultIcon />,
};
