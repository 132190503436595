import OrganizationView from './OrganizatianInfoPage/OrganizationView'
import { useAuth0 } from "@auth0/auth0-react";
import { isAdmin } from '../../../../../Utils/util';
import Error404 from '../../../../OtherPages/ErrorPage/Error404';
import React, { useContext, useEffect } from 'react';
import userInfoContext from "../../../../../store/userInfoContext";
import Loader from '../../../../../Components/BifrostPageComponent/Loader/Loader';

const ActivityPage = () => {
    const userInfo = useContext(userInfoContext);
    const { user } = useAuth0()

    return (
            !userInfo ? <div style={{ height: "100vh" }}><Loader /></div> :
                <section className={`bifrost-table-panel users-table`}>
                    <OrganizationView />
                </section>
    )

}


export default ActivityPage