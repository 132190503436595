import React, { useContext, useEffect, useRef, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import "./UserDetails.scss"
import { useGetUserById } from "../../../../../Hooks/FetchHooks/GetDataService/useGetDataById/useGetUserById"
import Loader from "../../../../../Components/BifrostPageComponent/Loader/Loader"
import {
	Card,
    CardContent,
    CardDescription,
    Dropdown,
    DropdownMenu,
    Feed,
    FeedContent,
    FeedEvent,
    FeedExtra,
    FeedSummary,
    Form,
    Icon,
    Input,
    Message,
    Segment,
    SegmentGroup
} from "semantic-ui-react"
import { ReactComponent as OptionsIcon } from "../../../../../Icons/options.svg"
import { DeleteUserModal } from "../UserModal/DeleteUserModal/DeleteUserModal"
import BifrostButton from "../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton"
import { usePatchData } from "../../../../../Hooks/FetchHooks/PatchDataService/usePatchData"
import { ResetMfaModal } from "../UserModal/ResetMfaModal/ResetMfaModal"
// import { validateInput } from '../../../../../Utils/Validator/ValidateInput';
import { useLocation, useParams } from "react-router-dom"
import Error401 from "../../../../OtherPages/ErrorPage/Error401"
import MainPanelFooter from "../../../MainPanelFooter/MainPanelFooter"
import MainPanelHeader from "../../../MainPanelHeader/MainPanelHeader"
import userInfoContext from '../../../../../store/userInfoContext'
import { isAdmin, isSuperAdminOrAdmin } from "../../../../../Utils/util"
import { Navigate } from "react-router-dom" 

const initialValueState = {
	nickname: "",
	role: ""
};

const initialState = {
    nickname: false,
    role: false
};

const optionsRole = [
    { key: "admin", value: "Admin", text: "Admin" },
    { key: "user", value: "User", text: "User" },
];

// const initialValidatedState = {
//     name: true
// }

// const initialValidatedState = {
//     name: false,
// }

const UserDetails = () => {
	const { id } = useParams()
	const { state } = useLocation()
	const { isLoading: userIsLoading, isError, data, setData, getUserById } = useGetUserById(id)
	const [patchLoading, setPatchLoading] = useState(initialState)
	const [values, setValues] = useState(data.name ? { name: data.name } : initialValueState)
	const [changeEnabled, setChangeEnabled] = useState(initialState)
	const [patchError, setPatchError] = useState(initialState)
	const [showEditIcon, setShowEditIcon] = useState({ nickname: false, role: false })
	const [patchSuccess, setPatchSuccess] = useState(initialState)	
	// const [validated, setValidated] = useState(initialValidatedState)
	// const [validated, setValidated] = useState(initialValidatedState)
	const { patchItem } = usePatchData()
	const { user } = useAuth0()
	const userInfo = useContext(userInfoContext) 
	const inputRef = useRef({ nickname: null, role: null })

	const enableChange = (field) => {
		setPatchError({ ...patchError, [field]: false })
		setChangeEnabled({ ...changeEnabled, [field]: true })
		setTimeout(() => {
			inputRef[field].current.focus()
		}, 100)
	}

	const updateField = (e, name, value) => {
        if (!name) {
            name = e.target.name
            value = e.target.value
        }
        setValues({
            ...values,
            [name]: value
        });

    };

	const submitHandler = (field, value) => {
		if (value === undefined) {
            value = values[field]
        }
        resetFieldState(field)
		// set patch loading based on field
		setPatchLoading({ ...patchLoading, [field]: true })
		let patchObject = {}
		patchObject[field] = values[field]
		console.log(data)
		patchItem("/api/v2/user/" + id, patchObject, null).then(() => {
				setData({
					...data,
					[field]: values[field]
				})
				setPatchSuccess({ ...patchSuccess, [field]: true })
			})
			.catch(() => {
				setPatchError({ ...patchError, [field]: true })
			})
			.finally(() => {
				setPatchLoading({ ...patchLoading, [field]: false })
			})
	}

	const resetFieldState = (field) => {
        setPatchLoading({ ...patchLoading, [field]: false })
        setPatchSuccess({ ...patchSuccess, [field]: false })
        setPatchError({ ...patchError, [field]: false })
        setShowEditIcon({ ...showEditIcon, [field]: false })
        setChangeEnabled({ ...changeEnabled, [field]: false });
    }

	const cancelHandler = (field) => {
		setValues({...values, [field]: data[field]})
		resetFieldState(field)
	}

	useEffect(() => {
		(userInfo && userInfo.terms_accepted) && getUserById()
	}, [id])

	console.log(inputRef)

	return (
		<section className={"bifrost-table-panel users-table"}>
			<MainPanelHeader name={userIsLoading ? (state ? state.name : "") : data.nickname} navPath={"/users"} />
			<div className={"user-detail-container"}>
				{userIsLoading 
				? <Loader backgroundColor="white" inverted height="1px" minHeight="200px" />
				: 
					<>
						{isError ? (isSuperAdminOrAdmin(user) ?
							<Error401 /> : 
							<Navigate to={"\*"} />) :
							<div className={"user-detail-segment"}>
								<SegmentGroup>
									<Segment textAlign="right" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
										<Dropdown icon={<OptionsIcon className={"user-detail-options"} style={{ height: "32px", width: "32px" }} />}>
											<DropdownMenu>
												<DeleteUserModal disabled={data.email === user.email}
													id={data.id}
													navPath={"/users"}
													name={data.email}
												/>
												<ResetMfaModal 
													id={data.id} 
													name={data.email} 
												/>
											</DropdownMenu>
										</Dropdown>
									</Segment>
									<SegmentGroup horizontal>
										<Segment style={{ width: "50%" }}>
											<Feed>
												<FeedEvent className="user-event-margin">
													<FeedContent>
														<FeedSummary content={"Name"} />
														{patchLoading["nickname"] ? <Loader backgroundColor="white" inverted height="1px" minHeight="55px" /> : 
															<>{patchError["nickname"] && <Message error content={"Name change failed, please try again"} />}
																{!changeEnabled["nickname"] ? 
																	<FeedExtra
																		text
																		onClick={() => enableChange("nickname")}
																		className={"user-change-hover"}
																		onMouseLeave={() => setShowEditIcon({ ...showEditIcon, nickname: false })}
																		onMouseEnter={() => setShowEditIcon({ ...showEditIcon, nickname: true })}
																	>
																		{data.nickname}
																		{(patchSuccess.nickname && !showEditIcon.nickname) && <Icon name="check circle outline" size="large" style={{ float: "right", color: "#39C4AA" }} />}
																		{showEditIcon["nickname"] && <Icon style={{ float: "right" }} name="edit" />}
																	</FeedExtra>
																	: 
																	<FeedExtra style={{ marginTop: "0px" }} text>
																		<Card style={{ width: "100%" }}>
																			<CardContent className="user-change">
																				<CardDescription>
																					<Input style={{ border: "none", width: "100%" }} ref={inputRef["nickname"]} className={"user-change-noborder"} name="nickname" defaultValue={data.nickname} onChange={(e) => updateField(e)} />
																				</CardDescription>
																			</CardContent>
																		</Card>
																		<div style={{ display: "flex", justifyContent: "right" }}>
																			<BifrostButton onClick={() => submitHandler("nickname")} color="blue" style={{ width: "90px"}}>
																				Save
																			</BifrostButton>
																			<BifrostButton color="gray" onClick={() => { cancelHandler("nickname") }} basic style={{ width: "90px" }}>
																				Cancel
																			</BifrostButton>
																		</div>
																	</FeedExtra>
																}
															</>
														}
													</FeedContent>
												</FeedEvent>
												<FeedEvent className="user-event-margin">
													{/* <FeedLabel image={null} /> */}
													<FeedContent>
														{/* <FeedDate content={"dd"} /> */}
														<FeedSummary content={"Email"} />
														<FeedExtra
															text
															className={"user-change-disabled"}
															content={data.email}
														/>
													</FeedContent>
												</FeedEvent>
												<FeedEvent className="user-event-margin">
													<FeedContent>
														<FeedSummary content={"Role"} />
														{patchLoading["role"] ? <Loader backgroundColor="white" inverted height="1px" minHeight="55px" /> :
															<>{patchError["role"] && <Message error content={"Role change failed, please try again"} />}
															{!changeEnabled["role"] ?
																<FeedExtra
																	text
																	onClick={() => enableChange("role")}
																	className={"user-change-hover"}
																	onMouseLeave={() => setShowEditIcon({ ...showEditIcon, "role": false })}
																	onMouseEnter={() => setShowEditIcon({ ...showEditIcon, "role": true })}>
																	{data.role}
																	{(patchSuccess.role && !showEditIcon.role) && <Icon name="check circle outline" size="large" style={{ float: "right", color: "#39C4AA" }} />}
																	{showEditIcon["role"] && <Icon style={{ float: "right" }} name="edit" />}
																</FeedExtra>
																:
																<FeedExtra className="user-dropdown" style={{ marginTop: "5px" }} text>
                                                                <Form.Dropdown
                                                                    style={{ marginBottom: "14px" }}
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    defaultValue={data.role}
                                                                    onChange={(e, { name, value }) => updateField(e, name, value)}
                                                                    name="role"
                                                                    placeholder='Select role'
                                                                    options={optionsRole}
                                                                />
                                                                <div style={{ display: "flex", justifyContent: "right" }}>
                                                                    <BifrostButton onClick={() => submitHandler("role")} color='blue' style={{ width: "90px" }}>
                                                                        Save
                                                                    </BifrostButton>
                                                                    <BifrostButton color="gray" onClick={() => { cancelHandler("role") }} basic style={{ width: "90px" }}>
                                                                        Cancel
                                                                    </BifrostButton>
                                                                </div>
                                                            </FeedExtra>
                                                        }
                                                    </>
                                                }
												</FeedContent>
                                        </FeedEvent>
                                        <FeedEvent className='user-event-margin'>
                                            {/* <FeedLabel image={null} /> */}
                                            <FeedContent>
                                                {/* <FeedDate content={"dd"} /> */}
                                                <FeedSummary content={"Groups"} />
                                                <FeedExtra text content={(data.groups && data.groups.length > 0) ? data.groups.map(group => <div key={group.id}>{group.name}</div>) : "None"} />
                                            </FeedContent>
                                        </FeedEvent>
                                    </Feed>
                                </Segment>
										<Segment style={{ width: "50%" }}>
											<Feed>
												<FeedEvent className="user-event-margin">
													{/* <FeedLabel image={image} /> */}
													<FeedContent>
														{/* <FeedDate content={date} /> */}
														<FeedSummary content={"Last Login"} />
														<FeedExtra text content={data.last_login ? data.last_login : "Unknown"} />
													</FeedContent>
												</FeedEvent>
												<FeedEvent className="user-event-margin">
													{/* <FeedLabel image={null} /> */}
													<FeedContent>
														{/* <FeedDate content={"dd"} /> */}
														<FeedSummary content={"Last Session"} />
														<FeedExtra text content={data.last_session ? data.last_session : "Unknown"} />
													</FeedContent>
												</FeedEvent>
											</Feed>
										</Segment>
									</SegmentGroup>
								</SegmentGroup>
							</div>
						}
					</>
				}
			</div>
			<MainPanelFooter />
		</section>
	)
}

export default UserDetails
