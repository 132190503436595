import {useCallback, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

export const usePostData = () => {
    const [isLoading, setIsLoading] = useState(false);


    const {getAccessTokenSilently} = useAuth0();

    const postItem = useCallback(async (url, data, refreshData) => {
        setIsLoading(true);
        return fetch(
            new Request(
                process.env.REACT_APP_SESSION_ADDR + url,
                {
                    method: "POST",
                    headers: new Headers({
                        'authorization': "Bearer " + await getAccessTokenSilently(),
                        "Content-Type": "application/json"
                    }),
                    body: JSON.stringify(data)
                }
            )
        ).then(response => {
            if (response.ok) {
                console.log("Posted Item successfully!");
                return response;
            } else if (response.statusText === "Unauthorized") {
                throw new Error("Unauthorized");
            } else {
                console.log(response.statusText);
                throw new Error("Something went wrong");
            }
        }).then(() => {
            if (refreshData) {
                refreshData();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [getAccessTokenSilently])

    return {isLoading, postItem};
}