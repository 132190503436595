import React, { useState } from "react";
import Loader from "../../../../../../Components/BifrostPageComponent/Loader/Loader";
// import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import BifrostModal from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import BifrostConfirmModalActions
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalActions/BifrostConfirmModalActions";
import BifrostModalConfirmItem
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalPage/BifrostModalConfirmItem";
import { DropdownItem, Icon } from "semantic-ui-react";
import { usePostData } from "../../../../../../Hooks/FetchHooks/PostDataService/usePostData";

export const ResetMfaModal = ({ id, name }) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [showSuccessIcon, setShowSuccessIcon] = useState(false)

    const {
        isLoading,
        postItem
    } = usePostData();

    const confirmHandler = () => {
        postItem("/api/v2/user/" + id + "/resetMfa", null, null).then(() => {
            setShowErrorMessage(false);
            setShowSuccessIcon(true);
            setTimeout(() => {
                // After 3 seconds set the show value to false
                setShowSuccessIcon(false)
                setOpenModal(false);
              }, 1500)
            
        }).catch((e) => {
            console.log(e);
            setShowErrorMessage(true);
            setShowSuccessIcon(false);
        })
    };

    const cancelHandler = () => {
        setOpenModal(false);
        setShowErrorMessage(false);
        setShowSuccessIcon(false);
    };

    return (
        <>
            {
                <BifrostModal title={"Reset MFA"}
                    open={openModal}
                    setOpen={setOpenModal}
                    closeOnEscape={!isLoading}
                    closeOnDimmerClick={!isLoading}
                    trigger={<DropdownItem icon="shield alternate" text='Reset MFA' ></DropdownItem>}
                    modalPage={
                        isLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="100px" /> :
                        showSuccessIcon ? <Icon style={{width: "100%", textAlign: "center", color: "#39C4AA"}} name="check circle outline" size="huge" /> :
                            <BifrostModalConfirmItem showErrorMessage={showErrorMessage}
                                action="reset MFA for"
                                name={name} />
                    }
                    modalActions={
                        <BifrostConfirmModalActions loading={isLoading || showSuccessIcon}
                            confirmButtonColor="blue"
                            confirmText={"Reset MFA"}
                            confirmHandler={() => confirmHandler()}
                            cancelHandler={() => cancelHandler()}
                        />
                    }
                />
            }
        </>
    )
}