import {useCallback, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

export const useGetUserInfo = () => {
    const {getAccessTokenSilently} = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const getUserInfo = useCallback(async () => {

        setIsLoading(true)

        fetch(
            new Request(process.env.REACT_APP_SESSION_ADDR + "/api/v2/user/info", {
                method: 'GET',
                headers: new Headers({
                    'authorization': "Bearer " + await getAccessTokenSilently()
                })
            }))
            .then(response => {
                if (response.status !== 200) {
                    console.log("Unathorized")
                    return [];
                }
                console.log("Got user info")
                return response.json();
            })
            .then(response => {
                setData(response)
            })
            .catch(error => {
                console.error(error)
            })
            .finally(() => {
                setIsLoading(false);
            })

    }, [])

    return {isLoading, data, getUserInfo};

}