/**
 * Renders the Bifrost Detail Component.
 * 
 * @param {Object} chosenOrg - The chosen organization object.
 * @returns {JSX.Element} The rendered Bifrost Detail Component.
 */
import React from 'react';
// import Loader from "../../Loader/Loader";
import { Label, Feed, FeedEvent, FeedExtra, FeedContent, FeedSummary, SegmentGroup, Segment } from "semantic-ui-react";
import RenameDeviceModal from "../../../../Pages/MainPanel/MainPanelBody/DevicesPage/DeviceDetailsPage/RenameDeviceModal/RenameDeviceModal";
import UpgradeFirmwareModal from "../../../../Pages/MainPanel/MainPanelBody/DevicesPage/DeviceDetailsPage/UpgradeFirmwareModal/UpgradeFirmwareModal";

const DeviceDetailsSegment = ({ data, getDeviceById, latestFirmwareVersion, firmwareUpToDate }) => {
    
    return (
        <SegmentGroup horizontal style={{ marginTop: "0px" }}>
            <Segment style={{ width: "50%" }}>
                <Feed>

                    <FeedEvent className='device-event-margin'>
                        <FeedContent>
                            <FeedSummary content={"Name"} />
                            <RenameDeviceModal
                                data={data}
                                getDeviceById={getDeviceById}
                                disabled={data.state !== "idle"}
                            />

                        </FeedContent>

                    </FeedEvent>
                    <FeedEvent className='device-event-margin'>
                        {/* <FeedLabel image={null} /> */}
                        <FeedContent>
                            {/* <FeedDate content={"dd"} /> */}
                            <FeedSummary content={"Serial Number"} />
                            <FeedExtra text className={"device-change-disabled"} content={data.serialnr} />
                        </FeedContent>
                    </FeedEvent>
                    <FeedEvent className='device-event-margin'>
                        {/* <FeedLabel image={null} /> */}
                        <FeedContent>
                            {/* <FeedDate content={"dd"} /> */}
                            <FeedSummary content={"Type"} />
                            <FeedExtra text className={"device-change-disabled"} content={data.type} />
                        </FeedContent>
                    </FeedEvent>
                    <FeedEvent className='device-event-margin'>
                        {/* <FeedLabel image={null} /> */}
                        <FeedContent>
                            {/* <FeedDate content={"dd"} /> */}
                            <FeedSummary content={"Groups"} />
                            <FeedExtra text content={(data.groups && data.groups.length > 0) ? data.groups.map(group => <div key={group.id}>{group.name}</div>) : "None"} />
                        </FeedContent>
                    </FeedEvent>
                </Feed>
            </Segment>
            <Segment style={{ width: "50%" }}>
                <Feed>
                    <FeedEvent className='device-event-margin'>
                        {/* <FeedLabel image={image} /> */}
                        <FeedContent>
                            {/* <FeedDate content={date} /> */}
                            <FeedSummary content={"Firmware Version"} />
                            <FeedExtra text content={data.firmware ?
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="device-detail-cell">
                                        {data.firmware.toString()}
                                        {(latestFirmwareVersion && firmwareUpToDate) &&
                                            <Label style={{ marginLeft: "10px" }} size="small">
                                                LATEST
                                            </Label>}
                                    </div>
                                    {latestFirmwareVersion &&
                                        <div style={{ display: "flex", position: "relative" }}>
                                            <UpgradeFirmwareModal
                                                disabled={data.state !== "idle"}
                                                device={data}
                                                latestFirmwareVersion={latestFirmwareVersion}
                                                firmwareUpToDate={firmwareUpToDate}
                                            />
                                        </div>
                                    }
                                </div>
                                : "Unknown"} />
                        </FeedContent>
                    </FeedEvent>
                    <FeedEvent className='device-event-margin'>
                        {/* <FeedLabel image={image} /> */}
                        <FeedContent>
                            {/* <FeedDate content={date} /> */}
                            <FeedSummary content={"Last Heartbeat"} />
                            <FeedExtra text content={data.timestamp ? data.timestamp : "Unknown"} />
                        </FeedContent>
                    </FeedEvent>
                    <FeedEvent className='device-event-margin'>
                        {/* <FeedLabel image={null} /> */}
                        <FeedContent>
                            {/* <FeedDate content={"dd"} /> */}
                            <FeedSummary content={"Last Session"} />
                            <FeedExtra text content={data.last_session ? data.last_session : "Unknown"} />
                        </FeedContent>
                    </FeedEvent>
                </Feed>

            </Segment>
        </SegmentGroup>
    );
};

export default DeviceDetailsSegment;