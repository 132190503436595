import React, { useState } from "react";
import Loader from "../../../../../../Components/BifrostPageComponent/Loader/Loader";
// import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import BifrostModal from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import { useDeleteData } from "../../../../../../Hooks/FetchHooks/DeleteDataService/useDeleteData";
import BifrostConfirmModalActions
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalActions/BifrostConfirmModalActions";
import BifrostModalConfirmItem
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalPage/BifrostModalConfirmItem";
import { DropdownItem, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

export const DeleteUserModal = ({ disabled, id, name, navPath }) => {
    const nav = useNavigate();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [showSuccessIcon, setShowSuccessIcon] = useState(false)

    const {
        isLoading,
        deleteItem
    } = useDeleteData();

    const confirmHandler = () => {
        deleteItem("/api/v2/user/", id, null).then(() => {
            setShowErrorMessage(false);
            setShowSuccessIcon(true)
            setTimeout(() => {
                // After 3 seconds set the show value to false
                setShowSuccessIcon(false)
                setOpenModal(false);
                nav(navPath);
              }, 1500)
        }).catch((e) => {
            console.log(e);
            setShowErrorMessage(true);
            setShowSuccessIcon(false);
        })
    };

    const cancelHandler = () => {
        setOpenModal(false);
        setShowErrorMessage(false);
        setShowSuccessIcon(false);
    };

    return (
        <>
            {
                <BifrostModal title={"Delete User"}
                    open={openModal}
                    setOpen={setOpenModal}
                    closeOnEscape={!isLoading}
                    closeOnDimmerClick={!isLoading}
                    trigger={<DropdownItem icon="trash alternate" text='Delete' style={{ color: "var(--color-5)" }} disabled={disabled} ></DropdownItem>}
                    modalPage={
                        isLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="100px" /> :
                        showSuccessIcon ? <Icon style={{width: "100%", textAlign: "center", color: "#39C4AA"}} name="check circle outline" size="huge" /> :
                            <BifrostModalConfirmItem showErrorMessage={showErrorMessage}
                                action="delete"
                                name={name} />
                    }
                    modalActions={
                        <BifrostConfirmModalActions loading={isLoading || showSuccessIcon}
                            confirmHandler={() => confirmHandler()}
                            cancelHandler={() => cancelHandler()}
                        />
                    }
                />
            }
        </>
    )
}