import BifrostButton from "../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import {ReactComponent as DeviceIcon} from '../../../../../Icons/devices-icon.svg'
import CreateDevicePage from "./CreateDevicePage";
import React, {useContext, useState} from "react";
import BifrostModal from '../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal'
import {validateInput} from "../../../../../Utils/Validator/ValidateInput";
import {usePostData} from "../../../../../Hooks/FetchHooks/PostDataService/usePostData";
import {hasFeatureInPackage} from "../../../../../Utils/util";
import UpgradePackageModal
    from "../../../../Sidebar/SidebarNavPanel/SidebarNavLink/UpgradePackageModal/UpgradePackageModal";
import {Icon} from "semantic-ui-react";
import userInfoContext from "../../../../../store/userInfoContext";
import Loader from '../../../../../Components/BifrostPageComponent/Loader/Loader'

const feature ="device_config"

const initialValueState = {
    org_id: null,
    type: "",
    shortName: "",
    longName: "",
    serialNr: ""
};

const initialValidatedState = {
    org_id: true,
    type: true,
    shortName: true,
    longName: true,
    serialNr: true
}

export const CreateDeviceModal = ({refreshDevices}) => {
    const userInfo = useContext(userInfoContext);
    const [values, setValues] = useState(initialValueState)
    const [validated, setValidated] = useState(initialValidatedState)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const {
        isLoading,
        postItem
    } = usePostData();

    const submitHandler = () => {
        validateInput(values, validated, setValidated)
            && postItem("/api/v2/device", values, refreshDevices).then(() => {
                setValidated({...initialValidatedState});
                setValues({...initialValueState});
                setShowErrorMessage(false);
                setOpenModal(false);
            }).catch(() => {
            setValidated({...initialValidatedState});
            setValues({...initialValueState});
            setShowErrorMessage(true);
        })
    };

    const cancelHandler = () => {
        setValidated({...initialValidatedState});
        setValues({...initialValueState});
        setShowErrorMessage(false);
        setOpenModal(false);
    };

    return (<>{
        !hasFeatureInPackage(userInfo, feature)
            ?
            <UpgradePackageModal openModal={openModal} setOpenModal={setOpenModal}
                                 feature={feature}
                                 trigger={
                                     <BifrostButton style={{ opacity: "0.5" }}>
                                         <Icon name="lock"/>
                                         Create
                                     </BifrostButton>
                                 }/>
            :
            <BifrostModal title={"Create Device"}
                          open={openModal} setOpen={setOpenModal}
                          closeOnEscape={!isLoading}
                          closeOnDimmerClick={!isLoading}
                          trigger={
                              <BifrostButton>
                                  <DeviceIcon style={{marginRight: "10px"}}/>
                                  Create
                              </BifrostButton>
                          }
                          modalPage={
                            isLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="200px"/> :
                              <CreateDevicePage validated={validated}
                                                values={values}
                                                setValues={setValues}
                                                showErrorMessage={showErrorMessage}
                              />
                          }
                          modalActions={
                              <>
                                  <BifrostButton style={{display: "inline-block"}}
                                                 onClick={() => submitHandler()}
                                                 disabled={isLoading}>
                                      Submit
                                  </BifrostButton>
                                  <BifrostButton onClick={() => cancelHandler()}
                                                 style={{display: "inline-block"}}
                                                 color="gray"
                                                 disabled={isLoading}>
                                      Cancel
                                  </BifrostButton>
                              </>
                          }

            />
    }</>)
}