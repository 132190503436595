import React, {useContext} from "react";

import {ReactComponent as DevicesIcon} from "../../../Icons/devices-icon.svg";
import {ReactComponent as LogoutIcon} from "../../../Icons/logout-icon.svg";
import {Icon} from 'semantic-ui-react'

import SidebarNavLink from "./SidebarNavLink/SidebarNavLink";
import { isSuperAdmin, isAdmin, isSuperAdminOrAdmin, hasFeatureInPackage } from "../../../Utils/util";

import "../Sidebar.scss";
import './SidebarNavPanel.scss'
import userInfoContext from "../../../store/userInfoContext";
import { useAuth0 } from "@auth0/auth0-react";


const SidebarNavPanel = ({ logout }) => {
    const userInfo = useContext(userInfoContext);
    const {user} = useAuth0()

    return (
        <nav className="sidebar-nav" >
            <ul className="sidebar-nav-top">
                <li>
                    <SidebarNavLink to="devices" label="Devices" icon={<DevicesIcon className="icon-align" />} />
                </li>
                <li hidden={process.env.REACT_APP_SAML_ENABLED === "true" || !isSuperAdminOrAdmin(user)}>
                    <SidebarNavLink to="users" label="Users" icon={<Icon name="users"/>}
                    />
                </li>
                <li hidden={process.env.REACT_APP_SAML_ENABLED === "true" || !isSuperAdminOrAdmin(user)}>
                    <SidebarNavLink to="groups" label="Groups" icon={<Icon name="folder"/>}
                                    locked={!hasFeatureInPackage(userInfo, "groups")}
                    />
                </li>
                <li hidden={process.env.REACT_APP_SAML_ENABLED === "true" || !user || !userInfo || !isSuperAdmin(user)}>
                    <SidebarNavLink to="orgs" label="Organizations" icon={<Icon name="building" />} />
                </li>
                <li hidden={!userInfo || !user || !isAdmin(user)}>
                    <SidebarNavLink to="activity" label="Activity" icon={<Icon name="eye" />} />
                </li>
            </ul>

            <ul>
                <li>
                    <SidebarNavLink to="login" label="Log Out" icon={<LogoutIcon className="icon-align" />}
                        onClick={(event) => {
                            event.preventDefault();
                            logout({
                                logoutParams: { returnTo: window.location.origin, }
                            })
                        }}
                    />
                </li>


                {/*<li className="inactive">*/}
                {/*    <SidebarNavLink to="settings" label="Settings" icon={<SettingsIcon />}/>*/}
                {/*</li>*/}
            </ul>
           
        </nav>
    );
};

export default SidebarNavPanel;