import React, {useState} from 'react';
import ChangePackagePage from "./ChangePackagePage";
import BifrostModal from "../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import BifrostButton from "../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import {validateInput} from "../../../../../Utils/Validator/ValidateInput";
import {usePatchData} from "../../../../../Hooks/FetchHooks/PatchDataService/usePatchData";
import Loader from "../../../../../Components/BifrostPageComponent/Loader/Loader";

const initialValidatedState = {
    package_id: true
}

const initialValueState = {
  name: "",
  package_id: null
};

const ChangePackageModal = ({chosenOrg, refreshOrgDetails, orgDetailsLoading}) => {
    const [values, setValues] = useState(initialValueState)
    const [validated, setValidated] = useState(initialValidatedState)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const {isLoading, patchItem} = usePatchData();

    const submitHandler = () => {
        validateInput(values, validated, setValidated) &&
         patchItem("/api/v2/org/"+chosenOrg.id, values, () => refreshOrgDetails(chosenOrg.id)).then(() => {
            setOpenModal(false);
        }).catch(() => {
            setValidated({...initialValidatedState});
            setValues({...initialValueState});
            setShowErrorMessage(true);
        })
    };

    const cancelHandler = () => {
        setValidated({...initialValidatedState});
        setValues({...initialValueState});
        setShowErrorMessage(false);
        setOpenModal(false);
    }

    return (
        <BifrostModal title={"Change Package for "+chosenOrg.name}
                      open={openModal}
                      setOpen={setOpenModal}
                      closeOnEscape={!isLoading}
                      closeOnDimmerClick={!isLoading}
                      trigger={
                        <div className='ui one buttons'>
                          <BifrostButton disabled={orgDetailsLoading}>
                              Change
                          </BifrostButton>
                          </div>
                          }
                      modalPage={
                        isLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="100px"/> :
                          <ChangePackagePage validated={validated}
                                                  values={values}
                                                  setValues={setValues}
                                                  showErrorMessage={showErrorMessage}
                          />
                      }
                      modalActions={
                          <>
                              <BifrostButton style={{display: "inline-block"}}
                                             onClick={() => submitHandler()}
                                             disabled={isLoading}>
                                  Submit
                              </BifrostButton>
                              <BifrostButton onClick={() => cancelHandler()}
                                             style={{display: "inline-block"}}
                                             color="gray"
                                             disabled={isLoading}>
                                  Cancel
                              </BifrostButton>
                          </>
                      }
        />
    );
};

export default ChangePackageModal;