import React, {useCallback, useState, useEffect} from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {Modal, Message} from 'semantic-ui-react'
import AddDevice2GroupPage from './GroupView/AddDeviceToGroupPage'
import AddUser2GroupPage from './GroupView/AddUserToGroupView'
import BifrostButton from '../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton'
import Loader from '../../../../../../Components/BifrostPageComponent/Loader/Loader'
import GroupView from './GroupView/GroupView'

const RemoveItemView = ({setRemoveItemViewProps, identifier, removeItem, onFinish}) => {
    const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const removeItemCallback = useCallback(async () => {
        setLoading(true)
        const request = await removeItem()
        fetch(request).then(response => {
            if (response.status !== 200) {
                throw Error("Something went wrong");
            }
            console.log("Removed item from group")

        })
            .then(() => {

                onFinish()
            })
            .catch(error => {
                setShowDeleteErrorMessage(error);
                setLoading(false)
            });
    }, [])

    return (
        loading ? <Loader backgroundColor="white" inverted height="1px" minHeight="200px"/> :
            <>  
                
                    {showDeleteErrorMessage &&
                        <Message negative>
                            <p>Something unexpected happened</p>
                        </Message>
                    }

                    <p style={{color: "black"}}>
                        Are you sure you want to remove {identifier}?
                    </p>
                


                <Modal.Actions>
                    <BifrostButton style={{display: "inline-block"}} color='red' onClick={() => {
                        removeItemCallback()
                    }}>
                        Delete
                    </BifrostButton>
                    <BifrostButton style={{display: "inline-block"}} color='gray'
                                   onClick={() => setRemoveItemViewProps(null)}>
                        Cancel
                    </BifrostButton>
                </Modal.Actions>
            </>
    )
}

const EditGroupsPage = ({setTitle, groupID, groupName}) => {
    const {getAccessTokenSilently} = useAuth0()

    const [loading, setLoading] = useState(false);
    const [orgIdOfGroup, setOrgIdOfGroup] = useState();
    const [devicesInGroup, setDevicesInGroup] = useState([])
    const [usersInGroup, setUsersInGroup] = useState([])
    const [openDeviceView, setOpenDeviceView] = useState(false)
    const [openUserView, setOpenUserView] = useState(false)
    const [removeItemViewProps, setRemoveItemViewProps] = useState(null)

    const getGroupDetails = useCallback(async () => {
        const url = new URL(process.env.REACT_APP_SESSION_ADDR + "/api/v2/group/" + groupID)
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'authorization': "Bearer " + await getAccessTokenSilently(),
                'Content-Type': 'application/json'
            }),
        });

        setLoading(true)

        fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    return {devices: [], users: []};
                }
                console.log("Got Group")
                return response.json();
            })
            .then((data) => {

                setOrgIdOfGroup(data.org_id)
                setDevicesInGroup(data.devices)
                setUsersInGroup(data.users)
                setLoading(false)
            })
            .catch(error => {
                console.error(error);
                setLoading(false)
            });
    }, [getAccessTokenSilently, groupID])

    useEffect(() => {
        const fetchDetails = async () => await getGroupDetails()
        fetchDetails()
    }, [getGroupDetails])

    useEffect(() => {
        if(openDeviceView){
            setTitle(`Add device to ${groupName}`)
        }else if(openUserView){
            setTitle(`Add user to ${groupName}`)
        }else if(removeItemViewProps){
            setTitle(`Remove ${removeItemViewProps.identifier} from ${groupName}`)
        }else{
            setTitle(`Edit ${groupName}`)
        }
    }, [openDeviceView, openUserView, removeItemViewProps])

    return loading ? <Loader backgroundColor="white" inverted height="1px" minHeight="200px"/> : openDeviceView ?
        <AddDevice2GroupPage orgIdOfGroup={orgIdOfGroup} devicesInGroup={devicesInGroup} groupName={groupName} groupId={groupID} onFinish={() => {
            setOpenDeviceView(false);
            getGroupDetails()
        }}/> :
        openUserView ?
            <AddUser2GroupPage orgIdOfGroup={orgIdOfGroup} usersInGroup={usersInGroup} groupName={groupName} groupId={groupID} onFinish={() => {
                setOpenUserView(false);
                getGroupDetails()
            }}/> :
            removeItemViewProps ? <RemoveItemView onFinish={() => {
                    setRemoveItemViewProps(null);
                    getGroupDetails()
                }} setRemoveItemViewProps={setRemoveItemViewProps} {...removeItemViewProps} /> :
                <GroupView setRemoveItemViewProps={setRemoveItemViewProps} groupName={groupName} groupID={groupID}
                    devicesInGroup={devicesInGroup} usersInGroup={usersInGroup} openDeviceTab={() => setOpenDeviceView(true)}
                           openUserTab={() => setOpenUserView(true)} refresh={getGroupDetails}/>
}

export default EditGroupsPage;
