import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Icon } from "semantic-ui-react";

import {
	hasFeatureInPackage,
	isAdmin,
	isSuperAdminOrAdmin,
} from "../../../../../../Utils/util";

import UpgradePackageModal from "../../../../../Sidebar/SidebarNavPanel/SidebarNavLink/UpgradePackageModal/UpgradePackageModal";
import userInfoContext from "../../../../../../store/userInfoContext";
import BifrostAuditComponent from "../../../../../../Components/BifrostPageComponent/OrganizationComponents/BifrostAuditComponent";
import BifrostSessionComponent from "../../../../../../Components/BifrostPageComponent/OrganizationComponents/BifrostSessionComponent";
import BifrostDetailComponent from "../../../../../../Components/BifrostPageComponent/OrganizationComponents/BifrostDetailComponent";
import MainPanelHeader from "../../../../MainPanelHeader/MainPanelHeader";
import { useGetOrganizationByID } from "../../../../../../Hooks/FetchHooks/GetDataService/useGetOrganizationByID";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../../../../Components/BifrostPageComponent/Loader/Loader";
import { Navigate } from "react-router-dom";
import Error401 from "../../../../../OtherPages/ErrorPage/Error401";

const OrganizationView = () => {
	const { id } = useParams();
	const { state } = useLocation();
	const userInfo = useContext(userInfoContext);
	const { user } = useAuth0();
	const { isLoading, isError, data, getOrganizationByID } =
		useGetOrganizationByID(isAdmin(user) ? userInfo.org.id : id);
	const [activeItem, setActiveItem] =
		process.env.REACT_APP_SAML_ENABLED === "true"
			? useState("audit")
			: useState("details");

	const [feature, setFeature] = useState(null);

	const [openUpgradePackageModal, setOpenUpgradePackageModal] = useState(false);

	const handleItemClick = (e, name) => {
		switch (name) {
			case "details":
				setActiveItem(name);
				break;
			case "audit":
				if (hasFeatureInPackage(userInfo, "audit_log")) {
					setActiveItem(name);
				} else {
					setFeature("audit_log");
					setOpenUpgradePackageModal(true);
				}
				break;
			case "sessions":
				if (hasFeatureInPackage(userInfo, "statistics")) {
					setActiveItem(name);
				} else {
					setFeature("statistics");
					setOpenUpgradePackageModal(true);
				}
				break;
			default:
				setOpenUpgradePackageModal(true);
		}
	};

	useEffect(() => {
		userInfo && userInfo.terms_accepted && getOrganizationByID();
	}, [id, userInfo]);

	return (
		<section className={`bifrost-table-panel users-table`}>
			<MainPanelHeader
				title={isAdmin(user) ? userInfo.org.name : null}
				name={isLoading ? (state ? state.name : null) : data.name}
				fetchData={getOrganizationByID}
				navPath={"/orgs"}
			/>
			{isLoading ? (
				<Loader
					backgroundColor="white"
					inverted
					height="1px"
					minHeight="200px"
				/>
			) : (
				<>
					{isError ? (
						isSuperAdminOrAdmin(user) ? (
							<Error401 />
						) : (
							<Navigate to={"*"} />
						)
					) : (
						<div style={{ backgroundColor: "white" }}>
							<UpgradePackageModal
								openModal={openUpgradePackageModal}
								setOpenModal={setOpenUpgradePackageModal}
								feature={feature}
							/>

							<Menu tabular style={{ paddingTop: "10px" }}>
								{process.env.REACT_APP_SAML_ENABLED !== "true" && (
									<Menu.Item
										name="details"
										active={activeItem === "details"}
										onClick={(e, { name }) => handleItemClick(e, name)}
									>
										Details
									</Menu.Item>
								)}
								{
									<>
										<Menu.Item
											disabled={!isSuperAdminOrAdmin(user)}
											name="audit"
											active={activeItem === "audit"}
											onClick={(e, { name }) => handleItemClick(e, name)}
										>
											{!hasFeatureInPackage(userInfo, "audit_log") ? (
												<Icon color="grey" name="lock" />
											) : (
												<Icon name="star outline" />
											)}
											Audit
										</Menu.Item>
										<Menu.Item
											disabled={!isSuperAdminOrAdmin(user)}
											name="sessions"
											active={activeItem === "sessions"}
											onClick={(e, { name }) => handleItemClick(e, name)}
										>
											{!hasFeatureInPackage(userInfo, "statistics") ? (
												<Icon color="grey" name="lock" />
											) : (
												<Icon name="star outline" />
											)}
											Sessions
										</Menu.Item>
									</>
								}
							</Menu>

							<div style={{ padding: "20px" }}>
								{activeItem === "details" && (
									<BifrostDetailComponent chosenOrg={data} />
								)}
								{activeItem === "audit" && (
									<BifrostAuditComponent
										chosenItem={data}
										chosenItemType="org"
									/>
								)}
								{activeItem === "sessions" && (
									<BifrostSessionComponent
										chosenItem={data}
										chosenItemType="org"
									/>
								)}
							</div>
						</div>
					)}
				</>
			)}
			<section className="bifrost-table-panel-footer" />
		</section>
	);
};

export default OrganizationView;
