import React, {useState} from 'react';
import BifrostModal from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import Loader from "../../../../../../Components/BifrostPageComponent/Loader/Loader";
import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import DeleteGroupPage from "./DeleteGroupPage";
import BifrostConfirmModalActions
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalActions/BifrostConfirmModalActions";
import {useDeleteData} from "../../../../../../Hooks/FetchHooks/DeleteDataService/useDeleteData";

const DeleteGroupModal = ({name, id, refreshGroups}) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [openModal, setOpenModal] = useState(false)

    const {
        isLoading,
        deleteItem
    } = useDeleteData();

    const deleteHandler = () => {
        deleteItem("/api/v2/group/", id, refreshGroups).then(() => {
            setOpenModal(false);
            setShowErrorMessage(false);
        }).
        catch((e) => {
            console.log(e);
            setShowErrorMessage(true);
        })
    };

    const cancelHandler = () => {
        setShowErrorMessage(false);
        setOpenModal(false);
        refreshGroups();
    };

    return (<>
       
            <BifrostModal title={"Delete Group"}
                          open={openModal}
                          setOpen={setOpenModal}
                          closeOnEscape={!isLoading}
                          closeOnDimmerClick={!isLoading}
                          trigger={<BifrostButton color="red"> Delete </BifrostButton>}
                          modalPage={isLoading ? <Loader backgroundColor="white" inverted height="1px" minHeight="100px"/> : <DeleteGroupPage name={name} showDeleteErrorMessage={showErrorMessage}/>}
                          modalActions={<BifrostConfirmModalActions loading={isLoading}
                                                                    confirmHandler={() => deleteHandler()}
                                                                   cancelHandler={() => cancelHandler()}/>}
            />
    </>);
};


export default DeleteGroupModal;