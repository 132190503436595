import React from 'react';
import './MainPanelFooter.scss';

const MainPanelFooter = () => {
    return (
        <section className={'main-panel-footer'} />
        //  Maybe can add copyright, contact info, language etc.
    );
};

export default MainPanelFooter;