import React from "react";
import { Header } from "semantic-ui-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../../Components/BifrostPageComponent/Loader/Loader";

const Error401 = ({ message }) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				background: "white",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "80vh",
				}}
			>
				<Header
					style={{ textAlign: "center", color: "black", fontSize: "80pt" }}
				>
					401
				</Header>
				<div style={{ textAlign: "center" }}>
					<div style={{ fontSize: "25pt", fontWeight: "bold" }}>
						{message ? message : "Not Allowed"}
					</div>
				</div>
			</div>
		</div>
	);
};
export default withAuthenticationRequired(Error401, {
	onRedirecting: () => <Loader />,
});
