import React from 'react'
import { Table } from 'semantic-ui-react'

/**
 * Renders the body of the BifrostTable component.
 * It randers the data rows using the Row component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.items - The array of items to render.
 * @param {React.Component} props.Row - The component used to render each row.
 * @returns {JSX.Element} The rendered BifrostTable body.
 */
const BifrostTableBody = ({items, Row}) => {
    return (
        <Table.Body>
            { items.map((item, idx) => <Row item={item} key={idx} />) }
        </Table.Body>
    )
}

export default BifrostTableBody