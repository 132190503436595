import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useGetOrgLastSession = (chosenOrg) => {
    const { getAccessTokenSilently } = useAuth0();

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const getOrgLastSession = useCallback(async () => {

        setIsLoading(true)
        return fetch(
            process.env.REACT_APP_SESSION_ADDR + "/api/v2/org/" + chosenOrg.id + "/lastSession",
            {
                method: 'GET',
                headers: new Headers({
                    'authorization': "Bearer " + await getAccessTokenSilently(),
                    'Content-Type': 'application/json'
                }),
            })
            .then(response => {
                if (response.ok) {
                    console.log("Get all data successfully!");
                    return response.json();
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .then(data => {
                setData(((data && data.split(" ").length > 1) ? data.split(" ")[0] : "-"))
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            });
    }, [getAccessTokenSilently])

    return { data, isLoading, getOrgLastSession }
}