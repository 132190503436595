import React, {useEffect, useState} from 'react'
import {Form} from 'semantic-ui-react'
import BifrostModalCreateItem from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalPage/BifrostModalCreateItem";
import {useGetOrganizations} from "../../../../../../Hooks/FetchHooks/GetDataService/useGetOrganizations";



const CreateGroupPage = ({validated, values, setValues, showErrorMessage}) => {
    const [optionsOrg, setOptionsOrg] = useState([])

    const {data, isLoading, getOrganizations} = useGetOrganizations();

    const updateField = (e, name, value) => {
        if (!name) {
            name = e.target.name
            value = e.target.value
        }
        setValues({
            ...values,
            [name]: value
        });
    };

    useEffect(() => {
        if (data.length === 0) {
            getOrganizations();
        } else {
            setOptionsOrg(data.map((org) =>
                ({key: org.id, value: Number(org.id), text: org.name})
            ))
        }
    }, [data]);


    return (
        <BifrostModalCreateItem showErrorMessage={showErrorMessage} >
            <Form.Input
                onChange={(e) => updateField(e)}
                placeholder="Name"
                name="name"
                error={!validated.name}
            />
            <Form.Dropdown
                fluid
                search
                selection
                // onOpen={fetchOrgs}
                loading={isLoading}
                noResultsMessage='Loading..'
                onChange={(e, {name, value}) => updateField(e, name, value)}
                name="org_id"
                placeholder='Select organization'
                options={optionsOrg}
                error={!validated.org_id}
            />
        </BifrostModalCreateItem>
    );
}

export default CreateGroupPage;
