import { Modal, Image, Header } from 'semantic-ui-react'
import BifrostButton from '../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton'
import robotImage from '@imagesPath/Robot-handshake.png';
import React from 'react';
import { Link } from "react-router-dom";


const UpgradePackageModal = ({ openModal, setOpenModal, feature, trigger, userLimit }) => {

  return (
    <Modal
    centered={false}
      size="tiny"
      dimmer="blurring"
      onClose={() => setOpenModal(false)}
      onOpen={() => setOpenModal(true)}
      open={openModal}
      trigger={trigger}
    >
      <Modal.Content style={{ color: "black" }}>
        <Image centered style={{marginBottom: "20px"}} size='medium' src={robotImage} wrapped />
        
        <Modal.Description>

          {(function () {
            switch (feature) {
              case 'users':
                return (<>
                  <Header>{`Need more users?`}</Header>
                  You have reached the limit of {userLimit} active user.<br /><br />Upgrade your plan to get more users.
                </>);
              case 'groups':
                return (<>
                  <Header>{`Unlock hierarchy based user management`}</Header>
                 Upgrade your plan to organize users within a structured hierarchy.
                </>);
              case 'device_config':
                return (<>
                  <Header>{`Unlock centralized device configuration`}</Header>
                  Upgrade your plan to centrally configure all your devices.
                </>);
              case 'statistics':
                return (<>
                  <Header>{`Unlock statistics and usage data`}</Header>
                  Upgrade your plan to get granular understand of patterns and behaviors.
                </>);
              case 'audit_log':
                return (<>
                  <Header>{`Unlock audit log`}</Header>
                  Upgrade your plan to review actions executed within the system.
                </>);
              default:
                return (<>
                  <Header>{`Unlock this feature`}</Header>
                  Upgrade your plan to utilize this feature.
                </>);
            }
          })()}
          <br /><br />
          Contact us at <Link
            to='#'
            onClick={(e) => {
              e.preventDefault();  
              window.location.href = "mailto:sales@bifrostconnect.com";
            }}
        >sales@bifrostconnect.com.</Link>

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <BifrostButton style={{ display: "inline-block" }} color='blue' onClick={(e) => {
          e.stopPropagation();
          setOpenModal(false)
        }}>
          Close
        </BifrostButton>
      </Modal.Actions>
    </Modal>
  )
}

export default UpgradePackageModal;