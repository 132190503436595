import React, {useEffect, useState} from 'react';
import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import BifrostModal from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import RenameDeviceModalPage from "./RenameDeviceModalPage";
import RenameDeviceModalActions from "./RenameDeviceModalActions";
import {usePostDeviceNameById} from "../../../../../../Hooks/FetchHooks/PostDataService/usePostDeviceNameById";
import {Message, FeedExtra, Icon} from "semantic-ui-react";

const RenameDeviceModal = ({data, getDeviceById, disabled}) => {
        const {isLoading, postDeviceNameById} = usePostDeviceNameById();

        const [showErrorMessage, setShowErrorMessage] = useState(false);
        const [inputData, setInputData] = useState('');
        const [openModal, setOpenModal] = useState(false);

        const [showConfirmMessage, setShowConfirmMessage] = useState(false);
        const [nameIsValid, setNameIsValid] = useState(false);
        const [nameLengthOK, setNameLengthOK] = useState(false);

        const [showEditIcon, setShowEditIcon] = useState(false)

        const [errorMessage, setErrorMessage] = useState('');

        const validateDeviceName = (input) => {
            const regExp = /^[a-zA-Z0-9_-]*$/;

            if (regExp.test(input)) {
                setNameIsValid(true);
            } else {
                setNameIsValid(false);
            }

            if (input.length < 3 || input.length > 16) {
                setNameLengthOK(false);
            } else {
                setNameLengthOK(true);
            }
        }


        const renameHandler = () => {
            validateDeviceName(inputData);
            if (!nameIsValid || !nameLengthOK) {
                setShowConfirmMessage(false);
                setErrorMessage("Not a valid name");
                setShowErrorMessage(true)
            } else {
                setShowConfirmMessage(true);
            }
        }

        const confirmHandler = () => {
            postDeviceNameById(data.id, inputData)
                .then(() => {
                    setInputData('');
                    setShowConfirmMessage(false);
                    setNameLengthOK(false);
                    setNameIsValid(false);
                    setShowErrorMessage(false)

                    setOpenModal(false);

                    getDeviceById();
                })
                .catch(error => {
                    setErrorMessage(error.message);
                    setInputData('');
                    setShowConfirmMessage(false);
                    setNameLengthOK(false);
                    setNameIsValid(false);
                    setShowErrorMessage(true)
                })

        }

        const cancelRenameHandler = () => {
            setInputData('');
            setShowConfirmMessage(false);
            setNameLengthOK(false);
            setNameIsValid(false);
            setShowErrorMessage(false)

            setOpenModal(false);
        }

        const cancelConfirmHandler = () => {
            setShowErrorMessage(false);
            setShowConfirmMessage(false);
        }

        useEffect(() => {
            return () => {
                setShowConfirmMessage(false);
                setShowErrorMessage(false);
                setNameLengthOK(false);
                setNameIsValid(false);
                setOpenModal(false);
                setErrorMessage('');
            }
        }, [disabled]);


        return (
            <BifrostModal title={"Rename Device"}
                                closeOnEscape={!isLoading}
                                closeOnDimmerClick={!isLoading}
                                open={disabled ? false : openModal}
                                setOpen={setOpenModal}
                                trigger={
                                    <FeedExtra text className={"device-change-hover"} onMouseLeave={() => setShowEditIcon(false)} onMouseEnter={() => setShowEditIcon(true)}>
                                                    <>
                                                        {data.shortname}
                                                        {showEditIcon && (disabled ? <Icon style={{ float: "right", opacity: "0.3"}} name="edit" /> : <Icon style={{ float: "right" }} name="edit" />)}
                                                    </>
                                    </FeedExtra>
                                }
                                modalPage={
                                    showErrorMessage ?
                                        <Message negative>{errorMessage}</Message>
                                        :
                                        <RenameDeviceModalPage
                                            inputData={inputData}
                                            setInputData={setInputData}
                                            isLoading={isLoading}
                                            showConfirmMessage={showConfirmMessage}
                                            nameIsValid={nameIsValid}
                                            nameLengthOK={nameLengthOK}
                                            validateDeviceName={validateDeviceName}
                                        />
                                }
                                modalActions={
                                    showErrorMessage ?
                                        <BifrostButton style={{display: "inline-block"}} color="gray"
                                                       onClick={() => cancelConfirmHandler()}>
                                            Cancel
                                        </BifrostButton>
                                        :
                                        <RenameDeviceModalActions
                                            isLoading={isLoading}
                                            showConfirmMessage={showConfirmMessage}
                                            nameIsValid={nameIsValid}
                                            nameLengthOK={nameLengthOK}
                                            renameHandler={renameHandler}
                                            confirmHandler={confirmHandler}
                                            cancelRenameHandler={cancelRenameHandler}
                                            cancelConfirmHandler={cancelConfirmHandler}
                                        />
                                }
                />
        );
    }
;

export default RenameDeviceModal;