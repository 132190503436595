import {withAuthenticationRequired} from "@auth0/auth0-react"
import React, {useContext} from "react";
import userAvatar from "@imagesPath/user-avatar.gif"
import Loader from '../../../Components/BifrostPageComponent/Loader/Loader'

import './UserInfoPanel.scss'
import userInfoContext from "../../../store/userInfoContext";
import {useAuth0} from "@auth0/auth0-react";

const UserInfoPanel = ({loading}) => {
    const userInfo = useContext(userInfoContext);
    const {user} = useAuth0()

    return (
        <section className="sidebar-user-section">
            {loading || !userInfo ? <Loader/> :
                <>
                    <img
                        src={user.picture ?? userAvatar}
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null; // inconsistent html behaviour
                            currentTarget.src = userAvatar;
                        }}
                        alt="Avatar"
                        className="sidebar-user-avatar"/>
                    <div className="sidebar-user-info">
                        <span className="tooltip-arrow"/>
                        <div className="sidebar-user-name">{user.nickname}</div>
                        <div className="sidebar-user-org">{userInfo.org.name}</div>
                        <div className="sidebar-user-role">{user.role}</div>
                        {userInfo.package_key !== "legacy" && <div className={`sidebar-user-package ${userInfo.package_key}`}>{userInfo.package_name.toUpperCase()}</div>}
                    </div>
                </>}
        </section>
    )
}

export default withAuthenticationRequired(UserInfoPanel, {
    onRedirecting: () => <Loader/>,
});