import React from 'react';
import {ReactComponent as CompanyLogo} from "@imagesPath/company-logo.svg";
import {Link} from "react-router-dom";

import './Logo.scss'

const Logo = () => {

    let version = process.env.REACT_APP_VERSION;
    if (process.env.REACT_APP_SAML_ENABLED === 'true') {
        version = version + "-saml"
    }
    if (process.env.NODE_ENV !== 'production') {
        version = version + "-rc"
    }
    return (
        <Link to="/" className="company-logo-and-version-info" >
                <CompanyLogo className="company-logo"/>
                <div className="version-info">v{version}</div>
        </Link>
    );
};

export default Logo;