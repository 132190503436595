import React, {useEffect} from 'react';
import BifrostTable from "../../BifrostElementComponent/BifrostTable/BifrostTable";
import moment from "moment/moment";
import {Table} from "semantic-ui-react";
import {
    useGetPaginatedSessionHistory
} from "../../../Hooks/FetchHooks/GetDataService/getOrganizationData/useGetPaginatedSessionHistory";

const BifrostSessionComponent = ({chosenItem, chosenItemType}) => {
    //* pagination method form the get paginated session history hood
    const {
        data, isLoading, pagination, setPagination, getSessionHistory
    } = useGetPaginatedSessionHistory(chosenItem, chosenItemType)
    
    const sessionHeaderColumns = React.useMemo(() => [
            {name: "Session Start (UTC)", key: "session_start", type: "date"},
            {name: "Device", key: "device_name", isUnSortable: true},
            {name: "Session Duration", key: "session_duration", type: "number"}
        ], []);
    
    const SessionRow = ({ item }) => {
        // convert the session_duration to moment duration object
        const sessionDuration = moment.duration(item.session_duration, 'second');
        
        return (
            <Table.Row key={item.id}>
                <Table.Cell>{item.session_start}</Table.Cell>
                <Table.Cell>{item.device_name}</Table.Cell>
                <Table.Cell>
                    {
                        sessionDuration.asSeconds() >= 172800
                            ? moment({
                                d: sessionDuration.days(),
                                h: sessionDuration.hours(),
                                m: sessionDuration.minutes(),
                                s: sessionDuration.seconds()
                            }).format("D[days] HH:mm:ss")
                            : sessionDuration.asSeconds() >= 86400 && sessionDuration.asSeconds() < 172800
                                ? moment({
                                    d: sessionDuration.days(),
                                    h: sessionDuration.hours(),
                                    m: sessionDuration.minutes(),
                                    s: sessionDuration.seconds()
                                }).format("D[day] HH:mm:ss")
                                : moment({
                                    h: sessionDuration.hours(),
                                    m: sessionDuration.minutes(),
                                    s: sessionDuration.seconds()
                                }).format("HH:mm:ss")
                    }
                </Table.Cell>
            </Table.Row>);
    }
    
    const filterSession = (session, filter) =>
        (!filter || filter === "")
        || (session.device_name.toLowerCase().includes(filter.toLowerCase()) || session.session_start.toLowerCase().includes(filter.toLowerCase()))
    
    useEffect(() => {
        // * un-pagination method from the uesGetSessionHistory hook
        // (!data || !data.length) && getSessionHistory()
        // * pagination method from the uesGetPaginatedSessionHistory hook
        if(!data || !data.length){
            getSessionHistory(pagination)
        }
        // return () => setData([])
    },[])
    
    return (
        <BifrostTable
            loading={isLoading}
            pageName='Sessions'
            columns={sessionHeaderColumns}
            data={data}
            Row={SessionRow}
            filterFunc={filterSession}
            defaultSort={{ order: 'descending', column: 'session_start' }}
            fetchData={getSessionHistory}
            //* extra property for the bifrost table to implement the pagination
            pagination={pagination}
            setPagination={setPagination}
        />
    );
};

export default BifrostSessionComponent;