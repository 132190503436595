import React, {useCallback, useEffect, useContext} from "react";
import {Card, CardGroup, Icon, List} from "semantic-ui-react";
import Loader from "../Loader/Loader";
import { ReactComponent as DevicesIcon } from "../../../Icons/devices-icon.svg";
import {useGetOrgStructure} from "../../../Hooks/FetchHooks/GetDataService/getOrganizationData/useGetOrgStructure";
import userInfoContext from "../../../store/userInfoContext";

const BifrostOrgStructureComponent = ({chosenOrg}) => {
    const {data, isLoading, getOrgStructure } = useGetOrgStructure(chosenOrg)
    const userInfo = useContext(userInfoContext)
    
    const compileOrgStructure = useCallback((data) => {

        data = data.map((element, index) => {
            let icon;
            switch (element.type) {
                case 'organization':
                    icon = <List.Icon name="building" />
                    break;
                case 'User':
                    icon = <List.Icon color="grey" name="user" />
                    break
                case 'Admin':
                    icon = <List.Icon color="blue" name="user" />
                    break
                case 'SuperAdmin':
                    icon = <List.Icon color="green" name="user" />
                    break
                case 'group':
                    icon = <List.Icon name="folder" />
                    break
                case 'unassigned':
                    icon = <List.Icon name="ban" />
                    break
                case 'device':
                    icon = <List.Icon><DevicesIcon /></List.Icon>
                    break
                default:
                    icon = <List.Icon name="question" />
            }

            return (
                (element.type === 'unassigned' && !element.children) ? null :
                    <List.Item key={index} className={element.type}>
                        {icon}
                        <List.Content>
                            <List.Header>{element.name}</List.Header>
                            {element.children &&
                                <List.List>
                                    {compileOrgStructure(element.children)}
                                </List.List>
                            }
                        </List.Content>
                    </List.Item>
            )
        },[])

        return data

    })
    useEffect(() => {
        if (userInfo && userInfo.terms_accepted && (chosenOrg.id !== 0) && process.env.REACT_APP_SAML_ENABLED !== "true" ) {
            (!data || !data.length ) && getOrgStructure()
        }
    },[])

    
    
    return (
        <CardGroup>
            <Card fluid>
                <Card.Content>
                    <Card.Header>
                        <Icon name='fork'/>
                        Structure
                    </Card.Header>
                    <Card.Description>
                        {isLoading
                            ? <Loader backgroundColor="white" inverted height="1px" minHeight="100px"/>
                            : <List content={compileOrgStructure(data)} style={{overflow:"auto"}} />
                        }
                    </Card.Description>
                </Card.Content>
            </Card>
        </CardGroup>
    )
}

export default BifrostOrgStructureComponent