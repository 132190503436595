/**
 * Renders the Bifrost Detail Component.
 * 
 * @param {Object} chosenOrg - The chosen organization object.
 * @returns {JSX.Element} The rendered Bifrost Detail Component.
 */
import React from 'react';
import { Grid } from "semantic-ui-react";
import {isSuperAdmin} from "../../../Utils/util";
import {useAuth0} from "@auth0/auth0-react";
import BifrostLastSessionComponent from "./BifrostLastSessionComponent";
import BifrostOrgStructureComponent from "./BifrostOrgStructureComponent";
import BifrostOrgPackageComponent from "./BifrostOrgPackageComponent";

const BifrostDetailComponent = ({chosenOrg}) => {
    const { user } = useAuth0()
    
    return (
        <>
            {chosenOrg.id &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={(!isSuperAdmin(user) || process.env.REACT_APP_SAML_ENABLED === "true") ? 16 : 10}>
                            <BifrostLastSessionComponent chosenOrg={chosenOrg} />
                        </Grid.Column>
                        { (isSuperAdmin(user) && process.env.REACT_APP_SAML_ENABLED !== "true") &&
                            <Grid.Column width={6}>
                                <BifrostOrgPackageComponent chosenOrg={chosenOrg} />
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
            }
            <BifrostOrgStructureComponent chosenOrg={chosenOrg} />
        </>
    );
};

export default BifrostDetailComponent;