import React, {useCallback, useState, useEffect} from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {Form, Dropdown} from 'semantic-ui-react'
import BifrostModalCreateItem
    from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModalPage/BifrostModalCreateItem";
import {useGetOrganizations} from "../../../../../../Hooks/FetchHooks/GetDataService/useGetOrganizations";
import {useContext} from "react";
import userInfoContext from '../../../../../../store/userInfoContext';
import {hasFeatureInPackage} from '../../../../../../Utils/util';


const optionsRole = [
    {key: "admin", value: "Admin", text: "Admin"},
    {key: "user", value: "User", text: "User"},
];


const CreateUserPage = ({validated, values, setValues, showErrorMessage}) => {
    const userInfo = useContext(userInfoContext);
    const [optionsOrg, setOptionsOrg] = useState([])
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [groupOptions, setGroupOptions] = useState([])

    const {getAccessTokenSilently} = useAuth0();

    const {data, isLoading, getOrganizations} = useGetOrganizations();

    const updateField = (e, name, value) => {
        if (!name) {
            name = e.target.name
            value = e.target.value
        }
        setValues({
            ...values,
            [name]: value,
            groups: name === "org_id" ? [] : name === "groups" ? value : values.groups
        });
    };

    const fetchGroups = useCallback(async (org) => {
        setGroupsLoading(true)
        const request = new Request(process.env.REACT_APP_SESSION_ADDR + "/api/v2/org/" + org + "/group", {
            method: 'GET',
            headers: new Headers({
                'authorization': "Bearer " + await getAccessTokenSilently(),
                'Content-Type': 'application/json'
            }),
        });

        fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    console.log("Unathorized")
                    return [];
                }
                return response.json()
            })
            .then(data => {
                console.log("Groups fetched")
                setGroupOptions(data.map((group) =>
                    ({key: group.id, value: group.id, text: group.name})
                ))
            })
            .catch(error => {
                console.error(error);
            }).finally(() => {
                setGroupsLoading(false)
                });
    }, [getAccessTokenSilently])

    useEffect(() => {
        if (data.length === 0) {
            getOrganizations();
        } else {
            setOptionsOrg(data.map((org) =>
                ({key: org.id, value: Number(org.id), text: org.name})
            ))
        }
    }, [data]);

    return (
        <BifrostModalCreateItem showErrorMessage={showErrorMessage}>
            <Form.Input
                onChange={(e) => updateField(e)}
                placeholder="Email"
                name="email"
                error={!validated.email}
            />
            <Form.Input
                onChange={(e) => updateField(e)}
                placeholder='Nickname'
                name="nickname"
                error={!validated.nickname}
            />
            <Form.Dropdown
                fluid
                search
                selection
                onChange={(e, {name, value}) => updateField(e, name, value)}
                name="role"
                placeholder='Select role'
                options={optionsRole}
                error={!validated.role}
            />
            <Form.Dropdown
                fluid
                search
                selection
                loading={isLoading}
                noResultsMessage='Loading..'
                onChange={(e, {name, value}) => {
                    updateField(e, name, value);
                    setGroupOptions([]);
                    hasFeatureInPackage(userInfo, "groups") && fetchGroups(value);
                }
                }
                name="org_id"
                placeholder='Select organization'
                options={optionsOrg}
                error={!validated.org_id}
            />


            {
                (values.org_id && hasFeatureInPackage(userInfo, "groups"))
                && <>
                    <Form.Field>
                        <label className=''>Access Groups</label>
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            loading={groupsLoading}
                            value={values.groups}
                            onChange={(e, {name, value}) => updateField(e, name, value)}
                            placeholder='Select groups'
                            fluid
                            multiple
                            search
                            selection
                            name="groups"
                            options={groupOptions}
                        />
                    </Form.Field>
                </>
            }
        </BifrostModalCreateItem>
    );
}

export default CreateUserPage;
