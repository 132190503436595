import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "../../Components/BifrostPageComponent/BifrostProtectedRoute/ProtectedRoute";
import DevicesPage from "./MainPanelBody/DevicesPage/DevicesPage";
import OrganizationsPage from "./MainPanelBody/Organizations_Activity/OrganizationsPage";
import UsersPage from "./MainPanelBody/Users/UsersPage";
import GroupsPage from "./MainPanelBody/Groups/GroupsPage";
import ActivityPage from "./MainPanelBody/Organizations_Activity/OrgannizationInfo_ActitvtyPage/ActivityPage";
import Error404 from "../OtherPages/ErrorPage/Error404";
import DeviceDetailsPage from "./MainPanelBody/DevicesPage/DeviceDetailsPage/DeviceDetailsPage";
import OrganizationView from "./MainPanelBody/Organizations_Activity/OrgannizationInfo_ActitvtyPage/OrganizatianInfoPage/OrganizationView";
import UserDetails from "./MainPanelBody/Users/UserDetails/UserDetails";
import { useAuth0 } from "@auth0/auth0-react";
import { isAdmin, isSuperAdmin, isSuperAdminOrAdmin } from "../../Utils/util";
import { hasFeatureInPackage } from "../../Utils/util";
import userInfoContext from "../../store/userInfoContext";

const MainPanel = () => {
	const { user } = useAuth0();
	const userInfo = useContext(userInfoContext);

	return (
		<main>
			<Routes>
				<Route path="/" element={<Navigate to="/devices" />} />
				<Route
					path="/devices"
					element={<ProtectedRoute component={DevicesPage} />}
				/>
				{isSuperAdminOrAdmin(user) && (
					<>
						<Route
							path="/devices/:id"
							element={<ProtectedRoute component={DeviceDetailsPage} />}
						/>
						{process.env.REACT_APP_SAML_ENABLED !== "true" && (
							<>
								<Route
									path="/users"
									element={<ProtectedRoute component={UsersPage} />}
								/>
								<Route
									path="/users/:id"
									element={<ProtectedRoute component={UserDetails} />}
								/>
								{hasFeatureInPackage(userInfo, "groups") && (
									<Route
										path="/groups"
										element={<ProtectedRoute component={GroupsPage} />}
									/>
								)}
							</>
						)}
					</>
				)}
				{isAdmin(user) && (
					<Route
						path="/activity"
						element={<ProtectedRoute component={ActivityPage} />}
					/>
				)}
				{isSuperAdmin(user) && (
					<>
						<Route
							path="/orgs"
							element={<ProtectedRoute component={OrganizationsPage} />}
						/>
						<Route
							path="/orgs/:id"
							element={<ProtectedRoute component={OrganizationView} />}
						/>
					</>
				)}
				<Route path="*" element={<Error404 />} />
			</Routes>
		</main>
	);
};

export default MainPanel;
