import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../Components/BifrostPageComponent/Loader/Loader";
import Sidebar from "./Sidebar/Sidebar";
import MainPanel from "./MainPanel/MainPanel";

const Pages = ({ loading }) => {
	const { isLoading } = useAuth0();

	return isLoading || loading ? (
		<div style={{ height: "100vh" }}>
			<Loader />
		</div>
	) : (
		<div id="global-wrapper">
			<Sidebar loading={loading} />
			<MainPanel />
		</div>
	);
};

export default Pages;
