import React, { useState } from 'react';
import BifrostModal from "../../../../../../Components/BifrostPageComponent/BifrostModal/BifrostModal";
import BifrostButton from "../../../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import UpgradeFirmwareModalContent from "./UpgradeFirmwareModalContent";
import {
    usePostUpgradeFirmware
} from "../../../../../../Hooks/FetchHooks/PostDataService/usePostUpgradeFirmware";
import './UpgradeFirmwareModal.scss'
import Loader from "../../../../../../Components/BifrostPageComponent/Loader/Loader";
import { isAdmin, isSuperAdmin } from "../../../../../../Utils/util";
import { useAuth0 } from "@auth0/auth0-react";
import { Icon, Message } from "semantic-ui-react";

const UpgradeFirmwareModal = ({ disabled, device, latestFirmwareVersion, firmwareUpToDate }) => {
    const [openFirmwareUpgradeModal, setOpenFirmwareUpgradeModal] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [version, setVersion] = useState('');

    const { isLoading,
        firmwareUpgradeSuccess,
        postUpgradeFirmware
    } = usePostUpgradeFirmware();

    const { user } = useAuth0();

    const submitHandler = (e) => {
        let newVersion = "latest"
        if (e.target.id === "push-button") {
            newVersion = version;
        }

        postUpgradeFirmware(device.id, newVersion)
            .then(() => {
                setVersion('');
                setShowErrorMessage(false);
                setOpenFirmwareUpgradeModal(false);
            }).catch(() => {
                setShowErrorMessage(true);
                setVersion('');
            })
    };

    const cancelHandler = () => {
        setVersion('');
        setShowErrorMessage(false);
        setOpenFirmwareUpgradeModal(false);
    }

    return (
        <>

            
                {firmwareUpgradeSuccess ? <Message positive size='mini' className='small-message'>Device is now updating</Message> :
                <>
                    {!firmwareUpToDate && <Message size='mini' className="small-message">New firmware version available</Message>}


                   <BifrostModal title={"Update Firmware"}
                            open={openFirmwareUpgradeModal} setOpen={setOpenFirmwareUpgradeModal}
                            trigger={
                                (isSuperAdmin(user) || !firmwareUpToDate) &&
                                <BifrostButton size="medium" style={{ marginLeft: "10px", marginTop: "1px", marginBottom: "1px" }} disabled={disabled}>
                                            <Icon name='arrow alternate circle down' style={{ margin: "0px", opacity: "1" }} />
                                </BifrostButton>
                            
                            }
                            closeOnEscape={!isLoading}
                            closeOnDimmerClick={!isLoading}
                            modalPage={
                                <>
                                    {isLoading
                                        ? <Loader backgroundColor="white" inverted height="1px" minHeight="50px" />
                                        : <UpgradeFirmwareModalContent setVersion={setVersion}
                                            device={device}
                                            showErrorMessage={showErrorMessage}
                                            latestFirmwareVersion={latestFirmwareVersion}
                                        />}
                                </>
                            }
                            modalActions={
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    {isSuperAdmin(user) &&
                                        <BifrostButton disabled={isLoading} id="push-button" onClick={(e) => submitHandler(e)}>Push</BifrostButton>}
                                    {isAdmin(user) &&
                                        <BifrostButton disabled={isLoading} id="confirm-button" onClick={(e) => submitHandler(e)}>Confirm</BifrostButton>}
                                    <BifrostButton disabled={isLoading} color="gray" onClick={() => cancelHandler()}>
                                        Cancel
                                    </BifrostButton>
                                </div>

                            }
                        />
                    
                </>
                }
            

        </>
    );
};

export default UpgradeFirmwareModal;