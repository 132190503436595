import React, {useEffect} from "react";
import Loader from './Components/BifrostPageComponent/Loader/Loader'
import {useAuth0} from "@auth0/auth0-react";
import AcceptUserTerms from './Pages/OtherPages/UserLoginPage/AcceptUserTerms'
import DownForMaintenancePage from './Pages/OtherPages/DownForMaintenancePage'

import 'semantic-ui-css/semantic.min.css'

import '@global';
import '@variables';
import '@reset';

import ErrorAuth0 from "./Pages/OtherPages/ErrorPage/ErrorAuth0";
import Pages from "./Pages/Pages";
import UserInfoContext from "./store/userInfoContext";
import {useGetUserInfo} from "./Hooks/FetchHooks/GetDataService/useGetUserInfo";


const App = () => {

    const {isLoading, error, isAuthenticated} = useAuth0();
    const {isLoading: isLoadingForGetUserInfo, data: userInfo, getUserInfo} = useGetUserInfo();

    let isDownForMaintenance = (process.env.REACT_APP_DOWN_FOR_MAINTENANCE === "true")

    useEffect(() => (!userInfo && isAuthenticated) && getUserInfo(), [isAuthenticated, userInfo]);

    return (
        <UserInfoContext.Provider value={userInfo}>
            {
                isDownForMaintenance
                    ? <DownForMaintenancePage/>
                    : error
                        ? <ErrorAuth0/>
                        : (userInfo && userInfo.terms_accepted === false)
                            ? <AcceptUserTerms/>
                            : (isLoading || isLoadingForGetUserInfo)
                                ? <div style={{height: "100vh"}}><Loader/></div>
                                : <Pages loading={isLoadingForGetUserInfo}/>
            }
        </UserInfoContext.Provider>
    )
}

export default App
