import React, { useState } from 'react'
import { useEffect, useContext } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { Popup, Button, Table, Label, Icon } from 'semantic-ui-react'
import BifrostTable from "../../../../Components/BifrostElementComponent/BifrostTable/BifrostTable";
import { isSuperAdmin, isSuperAdminOrAdmin } from '../../../../Utils/util'
import Error404 from '../../../OtherPages/ErrorPage/Error404'
import MainPanelHeader from "../../MainPanelHeader/MainPanelHeader";
import MainPanelFooter from "../../MainPanelFooter/MainPanelFooter";
import { CreateUserModal } from "./UserModal/CreateUserModal/CreateUserModal";
import { useGetUsers } from "../../../../Hooks/FetchHooks/GetDataService/useGetUsers";
import PropTypes from "prop-types";
import userInfoContext from "../../../../store/userInfoContext";
import BifrostButton from "../../../../Components/BifrostElementComponent/BifrostButton/BifrostButton";
import UpgradePackageModal
    from "../../../Sidebar/SidebarNavPanel/SidebarNavLink/UpgradePackageModal/UpgradePackageModal";
import { useNavigate } from 'react-router-dom'
import './UsersPage.scss'


const UsersPage = () => {
    const userInfo = useContext(userInfoContext);
    
    const { user } = useAuth0()
    const { data, setData: setUsers, isLoading, getUsers } = useGetUsers();
    const [openModal, setOpenModal] = useState(false);
    const nav = useNavigate();

    const columns = React.useMemo(
        () => {
            const superadminColumns = isSuperAdmin(user) ? [{
                name: "Organization",
                key: "org_name",
                type: "string"
            }] : []

            const adminColumns = isSuperAdminOrAdmin(user) ? [{
                name: "Groups",
                key: "groups",
                width: 2,
                isUnSortable: true
            }
            ] : []

            return superadminColumns.concat([
                {
                    name: "Email",
                    key: "email",
                    type: "string"
                },
                {
                    name: "Nickname",
                    key: "nickname",
                    type: "string"
                },
                {
                    name: "Role",
                    key: "role",
                    type: "string"
                },
            ]).concat(adminColumns)
        },
        [user]
    );

    const UserRow = ({ item }) => (

        <Table.Row key={item.id}
            className={isSuperAdminOrAdmin(user) && "users-list-row"}
            onClick={() => (isSuperAdminOrAdmin(user) && nav(`/users/${item.id}`, { state: { name: item.name } }))}
        >
            {isSuperAdmin(user)
                ? <Table.Cell>{item.org_name}</Table.Cell>
                : null}
            <Table.Cell>{item.email}</Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{item.role}</Table.Cell>

            <Table.Cell>
                {(item.groups && item.groups.length)
                    ? <Popup on='click'
                        trigger={
                            <Button onClick={(e) => {e.stopPropagation()}} style={{ backgroundColor: '#f3f3f3' }} size='small'>
                                Show
                            </Button>}
                        >
                        {item.groups.map(group => (<div key={group.id}>{group.name}</div>))}
                    </Popup>
                    : "None"}
            </Table.Cell>

        </Table.Row>
    )

    UserRow.propTypes = {
        item: PropTypes.object,
    };

    const filterUser = (user, filter) => {
        if (!filter || filter === "") return true
        else
            return user.email.toLowerCase().includes(filter.toLowerCase()) || user.name.toLowerCase().includes(filter.toLowerCase()) ||
                user.org_name.toLowerCase().includes(filter.toLowerCase())
    }

    const userLimitExceeded = (noOfUsers, userLimit) => {
        if (noOfUsers && userLimit) {
            return data.length >= (userLimit ? userLimit : 2)
        }
    }

    const UserLimitItem = ({ userLimit, noOfUsers }) => {
        return (
            <>
                {userLimit !== 999 &&
                    <Label basic size="small" style={{ border: "none", verticalAlign: "middle", color: "#858585", backgroundColor: "#efefef" }}>
                        USED:
                        <Label.Detail style={{ opacity: "100", marginLeft: "6px" }}>
                            {noOfUsers}/{
                                userLimit
                                    ? userLimit
                                    : 2
                            }</Label.Detail>


                    </Label>}
            </>
        )
    }

    useEffect(() => {
        if (isSuperAdminOrAdmin(user) && userInfo && userInfo.terms_accepted){
            getUsers();
        }
        return () => setUsers([]);
    }, []);

    return (
        (!isSuperAdminOrAdmin(user) || process.env.REACT_APP_SAML_ENABLED === "true")
            ? <Error404 />
            : <section className={`bifrost-table-panel users-table`}>
                <MainPanelHeader 
                    title={"Users"}
                    fetchData={getUsers}
                    userLimitItem={(userInfo && !isLoading && data.length > 0) && <UserLimitItem userLimit={userInfo.user_limit} noOfUsers={data.length} />}
                >
                    {
                        (data.length && !isLoading) ? userLimitExceeded(data.length, userInfo.user_limit)
                            ? <UpgradePackageModal openModal={openModal} setOpenModal={setOpenModal}
                                feature={"users"}
                                userLimit={userInfo.user_limit}
                                trigger={
                                    <BifrostButton className={"ui right floated button"}
                                        style={{ opacity: "0.9" }}>
                                        <Icon name="lock" />
                                        Create
                                    </BifrostButton>
                                }
                            />
                            : <CreateUserModal refreshUsers={getUsers} /> : null
                    }
                </MainPanelHeader>
                {isSuperAdminOrAdmin(user) &&
                    <BifrostTable
                        loading={isLoading}
                        pageName='Users'
                        columns={columns}
                        data={data}
                        Row={UserRow}
                        filterFunc={filterUser}
                        defaultSort={{ order: 'ascending', column: isSuperAdmin(user) ? 'org_name' : 'email' }}
                        isSelectable={!!(isSuperAdminOrAdmin(user) && !isLoading)}
                        fetchData={getUsers}
                    />
                }
                <MainPanelFooter />
            </section>
    )

}

export default UsersPage