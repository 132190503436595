import {validateEmail} from "../util";

export const validateInput = (values, validated, setValidated) => {

    let valid = true;
    let validatedCopy = {...validated};

    for (const [key] of Object.entries(validatedCopy)) {
        if (key === "email" && !validateEmail(values[key])) {
            validatedCopy[key] = false
            valid = false
        } else if (!values[key] && values[key] !== "0") {
            validatedCopy[key] = false
            valid = false
        } else if (!values[key]) {
            validatedCopy[key] = false;
            valid = false;
        } else {
            validatedCopy[key] = true;
        }
    }

    setValidated({...validatedCopy});

    return valid;
}