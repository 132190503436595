import React, { useEffect } from 'react';
import Loader from "../Loader/Loader";
import { useGetOrgDetails } from "../../../Hooks/FetchHooks/GetDataService/getOrganizationData/useGetOrgDetails";
import { Card, Icon } from "semantic-ui-react";
import ChangePackageModal
    from "../../../Pages/MainPanel/MainPanelBody/Organizations_Activity/OrganizationsModal/ChangePackageModal";

const BifrostOrgPackageComponent = ({ chosenOrg }) => {
    const { data: orgDetails, isLoading: orgDetailsLoading, getOrgDetails } = useGetOrgDetails(chosenOrg)

    useEffect(() => {
        if (chosenOrg && process.env.REACT_APP_SAML_ENABLED !== "true") {
            getOrgDetails(chosenOrg.id)
        }
    }, [chosenOrg])

    return (
        <Card fluid style={{ height: "100%" }}>
            <Card.Content textAlign='center'>
                <Card.Header>
                    <Icon name='box' />Package
                </Card.Header>
                <Card.Description>
                    {orgDetailsLoading
                        ? <Loader backgroundColor="white" inverted height="1px" minHeight="55px" />
                        : <div className={`package-col-value ${orgDetails.package && orgDetails.package.key.toLowerCase()}`}>
                            {orgDetails.package && orgDetails.package.name.toUpperCase()}
                        </div>
                    }
                </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign='center'>
                <ChangePackageModal chosenOrg={chosenOrg} refreshOrgDetails={getOrgDetails} orgDetailsLoading={orgDetailsLoading} />
            </Card.Content>
        </Card>
    );
};

export default BifrostOrgPackageComponent;